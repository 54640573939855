import { languages, language, useTranslation } from "./i18nInstance.js";

export const useLanguage = () => {
	const { t, i18n } = useTranslation();
	const isLTR = language.isLTRDirection;
	const direction = isLTR ? "ltr" : "rtl";
	document.documentElement.lang = language.current;
	document.documentElement.dir = direction;
	const changeLanguage = (newLang) => {
		//const language = e.target.value;
		i18n.changeLanguage(newLang);
	};
	return { t, i18n, changeLanguage, language, languages, isLTR, direction };
};

export default useLanguage;
