import React from "react";
import { Link } from "react-router-dom";
import MSTypography from "../standard/MSTypography/MSTypography";

export default function MBreadcrumbs({ items, separator = "/" }) {
	return (
		<div className="m-breadcrumb  flex items-center gap-2" separator={separator ?? "/"}>
			{items?.map((ele, i) => (
				<div key={i} className="flex items-center gap-2">
					{i !== items.length - 1 ? (
						<Link className={`text-disabled ${!ele.href ? "pointer-events-none" : ""}`} to={ele.href}>
							{ele.label}
						</Link>
					) : (
						<MSTypography className="text-primary pointer-events-none text-sm">{ele.label}</MSTypography>
					)}
					{i !== items.length - 1 && <div className="text-textSecondary">{separator}</div>}
				</div>
			))}
		</div>
	);
}
