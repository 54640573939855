import * as React from "react";

function CustomTabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			className="tab-panel"
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <div>{children}</div>}
		</div>
	);
}

export default function MTabs({ items, defaultActiveKey }) {
	const [value, setValue] = React.useState(defaultActiveKey ?? 0);
	return (
		<div className="m-tabs">
			<div className="w-full overflow-x-auto tabs">
				{items?.map((item, index) => (
					<div className={`tab ${value === index ? "selected" : ""}`} key={index} onClick={() => setValue(index)}>
						{item.label}
					</div>
				))}
			</div>
			{items?.map((item, index) => (
				<CustomTabPanel key={index} value={value} index={index}>
					{item.children}
				</CustomTabPanel>
			))}
		</div>
	);
}
