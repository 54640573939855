import MTypography from "@compts:std/MSTypography/MSTypography.jsx";
import { useEffect, useState } from "react";

export default function MSSwitch({ value, onChange, className, size = "small", check, unCheck, children }) {
	const [active, setActive] = useState(value);

	const handleToggle = () => {
		const newActive = !active;
		setActive(newActive);
		if (onChange) {
			onChange(newActive); // Pass the new value to onChange
		}
	};

	useEffect(() => {
		setActive(value); // Update the local state when the value prop changes
	}, [value]);

	return (
		<div className="flex items-center gap-2">
			<div
				className={`flex m-switch items-center cursor-pointer relative justify-center rounded-full
                  ${className} ${active && "checked"} ${size ?? "small"} p-2`}
				onClick={handleToggle}
			>
				{((size !== "large" && !children) || size === "large") && (
					<div className="text-white text-base inner-text">{active ? check : unCheck}</div>
				)}
				<div className="absolute m-switch-button bg-white rounded-full top-[50%] translate-y-[-50%]"></div>
			</div>
			{size !== "large" && <MTypography>{children}</MTypography>}
		</div>
	);
}
