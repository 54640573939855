import { UIItem } from "@core";
import MSIcon from "../MSIcon/MSIcon";
import { MRtv_ACTUAL_GET } from "@systypes";

const actualGetterByValue = (aValue) => {
	if (typeof aValue === "object" && MRtv_ACTUAL_GET in aValue) {
		return aValue[MRtv_ACTUAL_GET];
	}
	return aValue;
};

const actualGetterByRow = (aValue, aRow, aCol) => {
	const getter = aRow.valueOf;
	if (typeof getter === "function") {
		return getter.call(aRow, aCol.dataIndex);
	}
	return aValue;
};

function getColumnBase(aName, aConfig, aPath, aTranslation) {
	const title = UIItem.titleOf_cls(aPath, aTranslation);
	return {
		dataIndex: aName,
		config: aConfig,
		title: title,
		valueGetter: aName.includes(".") ? actualGetterByRow : actualGetterByValue,
	};
}

function getColumnSpecific(...args) {
	const result = getColumnBase(...args);
	if (result.valueGetter === actualGetterByValue) {
		delete result.valueGetter;
	}
	return result;
}

function getColumnNumber(...args) {
	const result = getColumnBase(...args);
	result.type = "number";
	return result;
}

function getColumnDate(...args) {
	const result = getColumnBase(...args);
	result.type = "date";
	const baseGetter = result.valueGetter;
	result.valueGetter = (...args) => {
		const value = baseGetter(...args);
		if (value && value instanceof Date) {
			return value.toLocaleDateString();
		}
		return value;
	};
	return result;
}

function getColumnTime(...args) {
	const result = getColumnBase(...args);
	result.type = "time";
	const baseGetter = result.valueGetter;
	result.valueGetter = (...args) => {
		const value = baseGetter(...args);
		if (value && value instanceof Date) {
			return value.toLocaleTimeString();
		}
		return value;
	};
	return result;
}

function getColumnDateTime(...args) {
	const result = getColumnBase(...args);
	result.type = "datetime";
	const baseGetter = result.valueGetter;
	result.valueGetter = (...args) => {
		const value = baseGetter(...args);
		if (value && value instanceof Date) {
			return value.toLocaleString();
		}
		return value;
	};
	return result;
}

function getColumnEnum(aName, aConfig, aPath, aTranslation) {
	const result = getColumnBase(...arguments);
	const values = [];
	aConfig.datatype.asList.forEach((item) => {
		const title = UIItem.translate_cls(aPath, aTranslation, item.title) || item.title;
		values[item.index] = title;
	});
	const baseGetter = result.valueGetter;
	result.valueGetter = (...args) => {
		const value = baseGetter(...args);
		if (typeof value === "number") {
			return values[value];
		}
		return value;
	};
	return result;
}

function getColumnRelation(aName, aConfig) {
	const result = getColumnBase(...arguments);
	let captionIn = aConfig.captionIn;
	if (!captionIn) {
		const cls = aConfig.verifyInstanceClass();
		if (cls instanceof Promise) {
			cls.then(() => (captionIn = aConfig.captionIn));
		} else captionIn = aConfig.captionIn;
	}
	const baseGetter = result.valueGetter;
	result.valueGetter = (...args) => {
		const actual = baseGetter(...args);
		if (captionIn && actual && typeof actual === "object") {
			return actual[captionIn];
		}
		return actual;
	};
	return result;
}

function getColumnList(...args) {
	return getColumnBase(...args);
}

function getColumnString(...args) {
	return getColumnBase(...args);
}

function getColumnInteger(...args) {
	const result = getColumnNumber(...args);
	return result;
}

function getColumnBoolean(...args) {
	const result = getColumnBase(...args);
	result.render = (aCell) => {
		return aCell === true ? <MSIcon name="Check" /> : <MSIcon name="Close" />;
	};
	return result;
}

const ColumnDefinitions = {
	SET: getColumnList,
	FLOAT: getColumnNumber,
	ENUM: getColumnEnum,
	STRING: getColumnString,
	INTEGER: getColumnInteger,
	BOOLEAN: getColumnBoolean,
	SMALLINT: getColumnNumber,
	TIME: getColumnTime,
	DATE: getColumnDate,
	DATETIME: getColumnDateTime,
	TINYINT: getColumnNumber,

	list: getColumnList,
	relation: getColumnRelation,
	property: getColumnSpecific,
	SetElement: getColumnBoolean,
};

export function columnByConfig(aName, aConfig /*, aPath, aTranslation*/) {
	const kind = aConfig.fieldKind;
	const func = ColumnDefinitions[kind];
	if (func) return func(...arguments);
	console.log(...arguments);
	throw new Error(`Can not create column for field ${aName}, fieldKind ${kind} was not treated!.`);
}

export default columnByConfig;
