import React, { useEffect } from "react";
import MSPaper from "../components/standard/MSPaper/MSPaper";
import { useStyleSettings } from "@/styleViews/layouts/common/styleSettings.jsx";
import MSTypography from "@compts:std/MSTypography/MSTypography.jsx";
import { MSForm } from "@compts:std/MSForm/index.js";
import { MInput } from "@compts:MC";
import image_01 from "./images/socialmedia-training.png";
import { useTranslation } from "@i18n";

import { mdiAccountCircleOutline, mdiLockOutline, mdiClose } from "@mdi/js";
import Icon from "@mdi/react";

const MainContent = () => {
	const { snackbarRef } = useStyleSettings();
	useEffect(() => {
		snackbarRef.current.open("Welcome To ERP");
	}, [snackbarRef]);
	const lang = useTranslation();
	return (
		<div className="flex items-center justify-center">
			{/* <MSPaper className="min-w-96 min-h-96 flex items-center p-10">
				<MSTypography className="!text-6xl ">Main Content</MSTypography>
			</MSPaper> */}

			<div className="bg-[#FAFAFA] w-[50%] h-[400px] shadow-2xl cursor-pointer">
				<div className="flex flex-row gap-[20px] justify-between items-center w-[100%] h-[100%]">
					<div className="right-side h-[100%] w-[40%] p-[10px]">
						<span>
							<Icon path={mdiClose} size={1} />
						</span>
						<MSForm>
							<span
								className={`font-bold text-[36px] ${
									lang.language?.isRTLDirection
										? `bg-gradient-to-r from-white to-primary bg-clip-text text-transparent mb-[25px]`
										: `bg-gradient-to-r from-primary to-white bg-clip-text text-transparent mb-[25px]`
								}`}
							>
								Login
							</span>
							<div className="flex items-center">
								<Icon path={mdiAccountCircleOutline} size={1.5} className="text-primary mr-[5px]" />
								<MInput />
							</div>
							<div className="flex items-center">
								<Icon path={mdiLockOutline} size={1.5} className="text-primary mr-[5px] font-light" />
								<MInput type="password" />
							</div>

							<div
								className={`flex flex-row justify-between items-center mt-[20px]${
									lang.language.isRTLDirection ? `flex-row-reverse` : `flex-row`
								}`}
							>
								<a href="/" className="text-primary text-[14px]">
									Forget Password ?
								</a>
								<button className="border-none py-[6px] transition-[0.3s] text-white bg-primary rounded-[20px] w-[120px]">
									Login
								</button>
							</div>
						</MSForm>
					</div>
					<div
						className={`left-side h-[100%] w-[40%] bg-lightPrimary ${
							lang.language?.isRTLDirection ? `rounded-r-[50%]` : `rounded-l-[50%]`
						} `}
					>
						<div className="">
							<div className="imageDesktop flex items-center justify-center">
								<img src={image_01} alt="desktopImage" className="mt-[80px] h-[200px] w-[300px]" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MainContent;
