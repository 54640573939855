import React from "react";
import { layouts } from "../../layouts";
import SettingButton from "../settings/SettingButton";
import StyleSettingsProvider from "@/styleViews/layouts/common/styleSettings";

const LoadedLayouts = {};
export function ThemeLayout({ style, children }) {
	let Layout = LoadedLayouts[style.layout];
	if (!Layout) {
		Layout = React.lazy(layouts[style.layout]);
		LoadedLayouts[style.layout] = Layout;
	}
	return (
		<React.Suspense>
			<StyleSettingsProvider>
				<Layout>{children}</Layout>
				<SettingButton />
			</StyleSettingsProvider>
		</React.Suspense>
	);
}

export default ThemeLayout;
