import { useState } from "react";
import SideBarSettings from "./SideBarSettings";
import MIcon from "@compts:std/MSIcon/MSIcon";
import MButton from "@compts:std/MSButton/MSButton";
import { useStyleSettings } from "@/styleViews/layouts/common/styleSettings";
export default function SettingButton() {
	const { modalMood } = useStyleSettings();
	const [open, setOpen] = useState(false);
	return (
		<>
			<MButton
				icon={<MIcon name="Cog" className="text-white" />}
				className={`setting-button !p-3 ${modalMood ? "-z-10" : "z-10"}`}
				onClick={() => setOpen(true)}
				type="circle"
			/>
			<SideBarSettings open={open} onClose={(value) => setOpen(value)} />
		</>
	);
}
