import React, { useEffect } from "react";

//#region please change document.baseURI
const MStyled = () => {
	useEffect(() => {
		const style = document.createElement("style");

		style.innerHTML = `
            @font-face {
                font-family: 'Material Design Icons';
                src: url(${document.baseURI}static/media/materialdesignicons-webfont.ec024da790d2972da002.woff2) format('woff2');
                font-display: swap ;
            }
        `;
		document.head.appendChild(style);

		return () => {
			document.head.removeChild(style);
		};
	}, []);

	return <></>;
};

export default MStyled;
