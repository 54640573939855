import React from "react";

const Divider = ({ textAlign, children }) => {
	return (
		<div className={" m-divider flex  items-center my-2 " + textAlign}>
			<div className="one flex-grow border-t border-disabled" />
			{children && <span className="mx-2 text-nowrap text-sm text-disabled">{children}</span>}
			<div className="tow border-t border-disabled" />
		</div>
	);
};

export default Divider;
