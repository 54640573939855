import { MMenu } from "@compts:misc";
import MSButton from "@compts:std/MSButton/MSButton.jsx";
import MIcon from "@compts:std/MSIcon/MSIcon.jsx";
import { MSInput } from "@compts:stdCommon";
import useTranslation from "@i18n";
import moment from "moment";
import momentHijri from "moment-hijri";
import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
const formattingTokens =
	/(\\)?i(Mo|MM?M?M?|Do|DDDo|DD?D?D?|w[o|w]?|YYYYY|YYYY|YY|gg(ggg?)?)|(\\)?(Mo|MM?M?M?|Do|DDDo|DD?D?D?|ddd?d?|do?|w[o|w]?|W[o|W]?|YYYYY|YYYY|YY|gg(ggg?)?|GG(GGG?)?|e|E|a|A|hh?|HH?|mm?|ss?|SS?S?|X|zz?|ZZ?|.)/g;
const MSDateTimePicker = (
	{
		label,
		format = document.dir === "ltr" ? "YYYY-MM-DD hh:mm A" : "YYYY-MM-DD mm:hh A",
		onChange,
		warning,
		defaultValue,
		isReadOnly,
		suffix,
		clearable,
		isDisabled,
		helperText,
		error,
		status,
		date = true,
		time = true,
		onBlur,
		...props
	},
	ref
) => {
	if (!error) error = status?.errors;
	if (!warning) warning = status?.warnings;
	if (!isReadOnly) isReadOnly = status?.isReadOnly;
	if (!isDisabled) isDisabled = status?.isDisabled;
	const [isHijri, setIsHijri] = useState(false);

	useImperativeHandle(ref, () => ({
		date: inputRef.current?.input,
	}));
	const getCurrentFormat = useCallback(
		(asHijri = isHijri, aFormat = format) => {
			function change(text) {
				if (text.includes("Y") || text.includes("M") || text.includes("D")) return true;
				return false;
			}
			aFormat = format.match(formattingTokens);
			let result = "";
			aFormat.forEach((ele) => {
				result += asHijri && change(ele) ? "i" + ele : ele;
			});
			return result;
		},
		[isHijri, format]
	);
	const trans = useTranslation();
	const currentMoment = isHijri ? momentHijri : moment;
	const [viewDate, setViewDate] = useState();
	const [selectedDate, setSelectedDate] = useState();
	useEffect(() => {
		const newDate = defaultValue ? currentMoment(defaultValue) : null;
		setViewDate(newDate);
		setSelectedDate(newDate || currentMoment());
		if (defaultValue) inputRef.current.updateIsFill(true, "yes");
	}, [defaultValue, currentMoment]);
	const inputRef = useRef(null);
	const itemsRef = useRef([]);

	const currentOf = useCallback(
		(text) => {
			if (text === "year" || text === "month" || text === "date") {
				const newText = isHijri ? "i" + text[0].toUpperCase() + text.substr(1) : text;
				return newText;
			}
			return text;
		},
		[isHijri]
	);
	const changeAM = useCallback(() => {
		const newDate = selectedDate.clone();
		if (newDate.format("A") === "AM") newDate.add(12, "hours");
		else newDate.subtract(12, "hours");
		setSelectedDate(newDate);
		setViewDate(newDate);
		onChange && onChange(new Date(newDate.format(getCurrentFormat())));
	}, [selectedDate, getCurrentFormat, onChange]);

	// const hijriMonths = {
	// 	0: trans.t("months.Muharram"),
	// 	1: trans.t("months.Safar"),
	// 	2: trans.t("months.Rabi-al-Awwal"),
	// 	3: trans.t("months.Rabi-al-Thani"),
	// 	4: trans.t("months.Jumada-al-Awwal"),
	// 	5: trans.t("months.Jumada-al-Thani"),
	// 	6: trans.t("months.Rajab"),
	// 	7: trans.t("months.Shaban"),
	// 	8: trans.t("months.Ramadan"),
	// 	9: trans.t("months.Shawwal"),
	// 	10: trans.t("months.Dhu-al-Qidah"),
	// 	11: trans.t("months.Dhu-al-Hijjah"),
	// };
	const menuRef = useRef(null);
	// const miladiMonths = {
	// 	0: "January",
	// 	1: "February",
	// 	2: "March",
	// 	3: "April",
	// 	4: "May",
	// 	5: "June",
	// 	6: "July",
	// 	7: "August",
	// 	8: "September",
	// 	9: "October",
	// 	10: "November",
	// 	11: "December",
	// };
	// const miladiDateAsString = useCallback(
	// 	(date) => {
	// 		if (!isHijri) return date.format(getCurrentFormat());
	// 		return date.format(getCurrentFormat(false));
	// 	},
	// 	[isHijri, getCurrentFormat]
	// );

	const updateTime = useCallback(
		(type, value) => {
			const newDate = selectedDate.clone();
			if (type === "AM") {
				changeAM();
			} else newDate[type](Number(value));
			setSelectedDate(newDate);
			setViewDate(newDate);
			onChange && onChange(newDate.format());
		},
		[onChange, selectedDate, changeAM]
	);
	// const miladiYears = [2020, 2021, 2022, 2023, 2024, 2025, 2026];
	// const hijriyears = [1440, 1441, 1442, 1443, 1444, 1445, 1446, 1447, 1448];
	// const currentYears = isHijri ? hijriyears : miladiYears;
	const getDateArray = useMemo(() => {
		function getLabel(text) {
			if (text.includes("Y")) return "year";
			if (text.includes("M")) return "month";
			if (text.includes("D")) return "date";
			if (text.includes("h")) return "hour";
			if (text.includes("m")) return "minute";
			if (text.includes("a") || text.includes("A")) return "AM";
		}

		return getCurrentFormat()
			.match(formattingTokens)
			.map((aFormat) => ({
				label: getLabel(aFormat),
				format: aFormat,
				value: viewDate ? viewDate.format(aFormat) : aFormat,
				am: viewDate && getLabel(aFormat) === "AM" ? viewDate.format(aFormat) : null,
			}));
	}, [getCurrentFormat, viewDate]);

	// const currentMonths = isHijri ? hijriMonths : miladiMonths;
	const changeDate = useCallback(
		(selectedDate, isHijri) => {
			const hijriFormat = getCurrentFormat(true);
			if (isHijri) {
				// to miladi date
				const dateAsString = selectedDate.format(hijriFormat);
				const miladiDate = momentHijri(dateAsString, hijriFormat).format(format);
				const newDate = moment(miladiDate, format);
				setSelectedDate(newDate);
				if (viewDate) setViewDate(newDate);
				setIsHijri(false);
				// if (!viewDate) onChange && onChange(new Date(newDate.format(getCurrentFormat(false))));
			} else {
				// to hijri date
				const hijriDate = momentHijri(selectedDate.format(format), getCurrentFormat()).format(hijriFormat);
				const newDate = momentHijri(hijriDate, hijriFormat);
				setSelectedDate(newDate);
				if (viewDate) setViewDate(newDate);
				setIsHijri(true);
				// if (!viewDate) onChange && onChange(new Date(newDate.format(getCurrentFormat(false))));
			}
		},
		[getCurrentFormat, format, viewDate]
	);
	const handlePlusIndex = useCallback(
		(index, value = 1) => {
			let round = 1;
			let aIndex = index + value;
			while (!itemsRef.current[aIndex] && round < 4) {
				aIndex += value;
				round += 1;
			}
			itemsRef.current[aIndex]?.focus();
			// else itemsRef.current[0]?.focus();
		},
		[itemsRef]
	);

	const parentRef = useRef(null);
	const handleKeyDown = useCallback(
		(event, item, index) => {
			event.preventDefault();
			if (event.key === "ArrowLeft") {
				if (getDateArray.length > index + 1) {
					handlePlusIndex(index);
				}
				return;
			}
			if (event.key === "ArrowRight") {
				if (0 <= index - 1) {
					event.preventDefault();
					handlePlusIndex(index, -1);
				}
				return;
			}
			function handleUpDown(min, max) {
				function getDefault() {
					return item.value === item.format ? selectedDate.format(item.format) : event.target.textContent;
				}
				if (event.key === "ArrowUp") {
					event.preventDefault();

					const value = getDefault();
					if (+event.target.textContent !== max) event.target.textContent = +value + 1;
					else event.target.textContent = +value;
					item.value = event.target.textContent;
					return true;
				}
				if (event.key === "ArrowDown") {
					event.preventDefault();
					const value = getDefault();
					if (+event.target.textContent !== min) event.target.textContent = +value - 1;
					item.value = event.target.textContent;
					return true;
				}
				return false;
			}
			const isArrowKey = event.key === "ArrowUp" || event.key === "ArrowDown";
			if (!/\d/.test(event.key) && !isArrowKey) return;

			if (item.label === "year") {
				if (handleUpDown(2000, 2040)) return;
				else if (event.target.textContent.length === 4) event.target.textContent = event.key;
				else {
					event.target.textContent += event.key;
					event.target.textContent.length === 4 && handlePlusIndex(index);
				}
			} else if (item.label === "month") {
				if (handleUpDown(1, 12)) return;
				else if (event.target.textContent.length === 2)
					if (+event.key > 1) {
						event.target.textContent = `0${event.key}`;
						handlePlusIndex(index);
					} else {
						event.target.textContent = event.key;
					}
				else {
					event.target.textContent += event.key;
					handlePlusIndex(index);
				}
				if (+event.target.textContent > 12) event.target.textContent = 12;
			} else if (item.label === "date") {
				if (handleUpDown(1, selectedDate.daysInMonth())) return;
				else if (event.target.textContent.length === 2) {
					if (event.key > 3) {
						event.target.textContent = `0${event.key}`;
						handlePlusIndex(index);
					} else event.target.textContent = `${event.key}`;
				} else {
					event.target.textContent += event.key;
				}
				if (+event.target.textContent > selectedDate.daysInMonth())
					event.target.textContent = selectedDate.daysInMonth();
			} else if (item.label === "minute") {
				if (handleUpDown(0, 59)) return;
				else if (event.target.textContent.length === 2) {
					if (event.key > 5) {
						event.target.textContent = `0${event.key}`;
						handlePlusIndex(index);
					} else event.target.textContent = `${event.key}`;
				} else {
					event.target.textContent += event.key;
					handlePlusIndex(index);
				}
				if (+event.target.textContent > 59) event.target.textContent = 59;
			} else if (item.label === "hour") {
				if (handleUpDown(1, 12)) return;
				else if (event.target.textContent.length === 2) {
					if (event.key > 1) {
						event.target.textContent = `0${event.key}`;
						handlePlusIndex(index);
					} else event.target.textContent = `${event.key}`;
				} else {
					event.target.textContent += event.key;
					handlePlusIndex(index);
				}
				if (+event.target.textContent > 12) event.target.textContent = 12;
			}
			if (item.label === "AM") {
				event.target.textContent = event.target.textContent === "AM" ? "PM" : "AM";
			}
			item.value = event.target.textContent;
		},
		[handlePlusIndex, selectedDate, getDateArray]
	);

	const handleFocus = useCallback(
		(event) => {
			event.stopPropagation();
			const range = document.createRange();
			const selection = window.getSelection();
			selection.removeAllRanges();
			range.selectNodeContents(event.target);
			selection.addRange(range);

			parentRef.current.addEventListener("focusout", () => {
				setTimeout(() => {
					let anyFocused = false;
					itemsRef.current.forEach((child) => {
						if (child === document.activeElement) {
							anyFocused = true;
						}
					});

					if (!anyFocused) {
						const newDate = selectedDate.clone();
						getDateArray.forEach((ele) => {
							ele.label &&
								ele.label !== "AM" &&
								newDate[currentOf(ele.label)](ele.label === "month" ? +ele.value - 1 : +ele.value);
						});
						if (newDate.isValid() && !newDate.isSame(selectedDate)) {
							setSelectedDate(newDate);
							setViewDate(newDate);
							onChange && onChange(newDate.format());
						}
						// console.log("No child div is focused");
					}
				}, 0);
			});
		},
		[itemsRef, parentRef, selectedDate, currentOf, onChange, getDateArray]
	);
	function handleMouseDown(event) {
		if (event.target === document.activeElement) {
			// event.stopPropagation();
			const selection = window.getSelection();
			selection.removeAllRanges();
		}
	}
	const handleClickOutside = useCallback(
		(event) => {
			if (inputRef.current?.input && !inputRef.current.input.contains(event.target)) {
				!viewDate && inputRef.current.updateIsFill(false);
				onBlur && onBlur(event);
			}
		},
		[onBlur, viewDate]
	);
	// const [openInput, setOpenInput] = useState(false);
	const updateDate = useCallback(
		(newDate) => {
			if (newDate.isValid()) {
				setSelectedDate(newDate);
				setViewDate(newDate);
				onChange && onChange(new Date(newDate.format()));
			}
		},
		[onChange]
	);
	useEffect(() => {
		document.addEventListener("click", handleClickOutside, false);
		return () => {
			document.removeEventListener("click", handleClickOutside, false);
		};
	}, [handleClickOutside]);

	// const handleClickInput = useCallback((e) => {
	// 	e.stopPropagation();
	// 	menuRef.current.closeMenu();
	// inputRef.current.input.classList.add("is-fill");
	// setOpenInput(true);
	// }, []);
	const InputDate = useCallback(
		({ selectedDate }) => (
			<div className="flex items-center gap-2">
				<MSInput
					labelClass="is-fill"
					ref={inputRef}
					label={label}
					error={error}
					helperText={helperText}
					// className={`${!viewDate ? "not-fill" : ""}`}
					// onClick={handleClickInput}
					warning={warning}
					isDisabled={isDisabled}
					isReadOnly={isReadOnly}
					suffix={
						<div className="flex items-center">
							{date && (
								<MSButton
									icon={isHijri ? "هـ" : "م"}
									type="circle"
									variant="text"
									onClick={() => changeDate(selectedDate, isHijri)}
									className="text-primary switch-btn text-lg h-8 w-8 justify-center font-Rubik"
								/>
							)}
							<MMenu
								orVertical="bottom"
								orHorizontal="center"
								transHorizontal="center"
								className="m-date"
								popupClassName="m-date-popup"
								ref={menuRef}
								data={[
									{
										type: "container",
										label: (
											<MSButton
												// onClick={(event) => {
												// 	menuRef.current.openMenu(event);
												// }}
												icon={
													<MIcon
														size={0.9}
														name={date ? "CalendarRange" : "ClockOutline"}
														className="text-lightPrimary"
													/>
												}
												type="circle"
												variant="text"
												className="date-btn"
											/>
										),
										children: [
											{
												type: "container",
												label: (
													<div className="flex gap-2">
														{date && (
															<div className="select-date-container">
																<Calendar defaultDate={selectedDate} changedDate={updateDate} />
															</div>
														)}
														{time && (
															<div className="flex gap-2 time-box">
																<Time items={Array.from({ length: 12 }, (_, index) => index + 1)} type="hour" />
																<Time items={Array.from({ length: 60 }, (_, index) => index)} type="minute" />
																<Time items={["AM", "PM"]} type="AM" />
															</div>
														)}
													</div>
												),
											},
										],
									},
								]}
							/>
							<div className="flex flex-col items-center justify-center">
								{clearable && (
									<MIcon
										name="Close"
										className="text-textSecondary"
										size={0.8}
										onClick={() => {
											setViewDate(null);
											onChange(null);
										}}
									/>
								)}
								{suffix}
							</div>
						</div>
					}
				>
					<div
						{...props}
						ref={parentRef}
						className="flex h-full gap-[2px]  items-center"
						onClick={(e) => {
							// e.preventDefault();
							// menuRef.current.closeMenu();
							itemsRef.current[0]?.focus();
						}}
						tabIndex={0}
					>
						{getDateArray.map((ele, i) =>
							ele.label ? (
								<div
									key={i}
									tabIndex={0}
									ref={(itemRef) => (itemsRef.current[i] = itemRef)}
									onFocus={(e) => {
										handleFocus(e, ele.value);
										inputRef.current.updateIsFill(true);
									}}
									onMouseDown={(e) => handleMouseDown(e, i)}
									onKeyDown={(e) => handleKeyDown(e, ele, i)}
									onClick={(event) => {
										event.stopPropagation();
										ele.am && changeAM();
									}}
									className="date-item"
								>
									{ele.value}
								</div>
							) : (
								<span key={i}>{ele.value}</span>
							)
						)}
					</div>
				</MSInput>
			</div>
		),
		[
			handleFocus,
			handleKeyDown,
			clearable,
			onChange,
			changeDate,
			time,
			menuRef,
			updateDate,
			suffix,
			props,
			helperText,
			date,
			changeAM,
			isHijri,
			getDateArray,
			label,
			warning,
			isReadOnly,
			isDisabled,
			error,
		]
	);
	const daysInMonth = (days) => {
		return Array.from({ length: days }, (_, index) => index + 1);
	};
	//#region Calendar
	const Calendar = memo(function Days({ defaultDate, changedDate }) {
		const dayNames = useMemo(
			() => [
				{ name: "Sunday", label: "Su" },
				{ name: "Monday", label: "Mo" },
				{ name: "Tuesday", label: "Tu" },
				{ name: "Wednesday", label: "We" },
				{ name: "Thursday", label: "Th" },
				{ name: "Friday", label: "Fr" },
				{ name: "Saturday", label: "Sa" },
			],
			[]
		);
		const [date, setDate] = useState(defaultDate);

		// const [days, setDays] = useState(daysInMonth(date?.daysInMonth()));
		// const [month, setMonth] = useState();
		// const [year, setYear] = useState(date[currentOf("year")]());
		const [mood, setMood] = useState("day");
		const allYears = useMemo(() => {
			const array = [];
			const start = isHijri ? 1390 : 1971;
			const end = isHijri ? 1480 : 2100;
			for (let index = start; index < end; index++) {
				array.push(index);
			}
			return array;
		}, []);

		const allMonth = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
		function updateDate(type, value) {
			// const newMonth = month + value;
			// setMonth(newMonth);
			// setDays(daysInMonth(currentMoment().month(newMonth).daysInMonth()));
			const newDate = date.clone();
			newDate[currentOf(type)](value);
			setDate(newDate);
			if (type === "date") changedDate(newDate);
		}
		const emptyDays = useMemo(() => {
			const result = [];
			const tempDate = date.clone();
			for (let index = 0; index < dayNames.length; index++) {
				if (tempDate.startOf(currentOf("month")).format("dddd") === dayNames[index].name) break;
				result.push(1);
			}
			return result;
		}, [dayNames, date]);
		// const updateDate = useCallback(
		// 	(day) => {
		// 		const newDate = defaultDate.clone();
		// 		newDate[currentOf("year")](Number(year));
		// 		newDate[currentOf("month")](Number(month));
		// 		newDate[currentOf("date")](Number(day));
		// 		changedDate(newDate);
		// 		// selectedDate[currentOf(type)](Number(value));
		// 		// daysRef.current?.setDays(daysInMonth(selectedDate.daysInMonth()));
		// 	},
		// 	[month, year, defaultDate, changedDate]
		// );
		useEffect(() => {
			setDate(defaultDate);
		}, [defaultDate]);
		const getMMMM = isHijri ? "iMMMM" : "MMMM";
		const toggleMood = useCallback(
			(targetMood) => (mood === targetMood ? setMood("day") : setMood(targetMood)),
			[mood]
		);
		return (
			<div className="flex flex-col gap-2">
				<div className="flex gap-2 justify-between p-2">
					<div className="flex gap-2 items-center">
						<div className="cursor-pointer" onClick={() => toggleMood("year")}>
							{date[currentOf("year")]()}
						</div>{" "}
						-
						<div className="cursor-pointer" onClick={() => toggleMood("month")}>
							{trans.t(`months.${date.locale("en").format(getMMMM)}`)}
						</div>
					</div>
					<div className="flex gap-2 items-center">
						<MIcon
							name={trans.isLTR ? "MenuLeft" : "MenuRight"}
							onClick={() => updateDate("month", date[currentOf("month")]() - 1)}
							className="text-disabled"
						/>
						<MIcon
							name={trans.isLTR ? "MenuRight" : "MenuLeft"}
							onClick={() => updateDate("month", date[currentOf("month")]() + 1)}
							className="text-disabled"
						/>
					</div>
				</div>
				{mood === "year" ? (
					<div className="years">
						{allYears.map((ele, i) => (
							<div
								key={i}
								ref={(yearRef) => yearRef && +ele === +date[currentOf("year")]() && yearRef.focus()}
								tabIndex={0}
								className="year"
								onClick={() => {
									updateDate("year", ele);
									setMood("day");
								}}
							>
								{ele}
							</div>
						))}
					</div>
				) : mood === "month" ? (
					<div className="months">
						{allMonth.map((ele, i) => (
							<div
								key={i}
								ref={(monthRef) => monthRef && +ele === +date[currentOf("month")]() && monthRef.focus()}
								tabIndex={0}
								className="month"
								onClick={() => {
									updateDate("month", ele);
									setMood("day");
								}}
							>
								{trans.t(`months.${currentMoment()[currentOf("month")](ele).locale("en").format(getMMMM)}`)}
							</div>
						))}
					</div>
				) : (
					<div className="days">
						{dayNames.map((ele, i) => (
							<div key={i} className="day label">
								{trans.t(`days.${ele.label}`)}
							</div>
						))}
						{emptyDays.map((ele, i) => (
							<div key={i} className="day pointer-events-none"></div>
						))}
						{daysInMonth(date?.daysInMonth())?.map((day, i) => {
							return (
								<div
									className={`day ${+day === +date[currentOf("date")]() ? "selected" : ""} `}
									key={i}
									onClick={(event) => {
										menuRef.current.closeMenu();
										updateDate("date", day);
									}}
								>
									{day}
								</div>
							);
						})}
					</div>
				)}
			</div>
		);
	});
	const Time = useCallback(
		({ items, type }) => {
			return (
				<div className="flex flex-col time-container">
					{items?.map((ele, i) => (
						<div
							className="time"
							key={i}
							onClick={() => {
								updateTime(type, ele);
							}}
						>
							{ele}
						</div>
					))}
				</div>
			);
		},
		[updateTime]
	);

	// const Months = useCallback(
	// 	({ selectedDate }) => {
	// 		return (
	// 			<MSSelect
	// 				label="month"
	// 				size="small"
	// 				options={Object.entries(currentMonths).map(([key, month]) => ({
	// 					label: month,
	// 					value: key,
	// 				}))}
	// 				value={selectedDate[currentOf("month")]()}
	// 				clearable={false}
	// 				onChange={(option) => {
	// 					updateDate("month", option.value);
	// 				}}
	// 			/>
	// 		);
	// 	},
	// 	[currentMonths, updateDate, currentOf]
	// );
	// const Years = useCallback(
	// 	({ selectedDate }) => {
	// 		return (
	// 			<MSSelect
	// 				label="year"
	// 				size="small"
	// 				options={currentYears.map((year) => ({ label: year, value: year }))}
	// 				clearable={false}
	// 				value={selectedDate[currentOf("year")]()}
	// 				onChange={(option) => {
	// 					updateDate("year", option.value);
	// 				}}
	// 			/>
	// 		);
	// 	},
	// 	[currentYears, updateDate, currentOf]
	// );

	return <InputDate selectedDate={selectedDate} />;
};

export default forwardRef(MSDateTimePicker);
