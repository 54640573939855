import React from "react";
import MSSelect from "../../standard/common/MSSelect.jsx";
import { useTranslation } from "@i18n";
import MLoader from "@compts:std/common/MLoader.jsx";
const saveRows = Symbol();

function LocalMultiSelect({ item, selectionIn }) {
	const [list, setList] = React.useState(null);
	const [choises, setChoises] = React.useState([]);
	const [selections, setSelections] = React.useState([]);

	React.useEffect(() => {
		const initList = async () => {
			const list = await item?.valueOrCreate();
			const choisesController = await list?.instanceClass?.meta?.configs.fields[selectionIn]?.verifyInstanceClass();
			const choises = await choisesController.getAll();
			if (!list[saveRows]) {
				list[saveRows] = [...list];
			}
			setChoises(choises);
			setList(list);
			const curr_Selections = list.map((inst) => {
				const v = inst.valueOf(selectionIn);
				return v?.ID?.value || v;
			});
			setSelections(curr_Selections);
		};
		initList();
	}, [item, selectionIn]);

	const trans = useTranslation();
	const options = choises.map((choice) => ({
		key: choice?.ID?.value,
		label: trans?.language?.isRTLDirection ? choice.name_AR : choice.name_EN,
		value: choice.ID.value,
	}));

	const loc_HandleSelect = (value) => {
		list.clear();
		value.forEach((id) => {
			const org = list[saveRows]?.find((inst) => {
				const v = inst.valueOf(selectionIn);
				return v === id || v?.ID?.value === id;
			});
			if (org) {
				list.add(org);
			} else {
				list.addNew().setValueOf(
					selectionIn,
					choises.find((inst) => inst.ID.value === id)
				);
			}
		});
		setSelections(value);
	};

	return (
		<div className="m-select">
			<MSSelect
				multiple
				value={selections}
				options={options}
				maxTagCount="responsive"
				onChange={loc_HandleSelect}
				label={list?.instanceClass.translate(trans, `fields.${item?.name}`)}
			/>
		</div>
	);
}
export const MMultiSelect = (props) => {
	const loader = async () => {
		const result = { ...props };
		result.item = await result.item;
		return result;
	};

	return props.item instanceof Promise ? (
		<MLoader component={LocalMultiSelect} loader={loader} />
	) : (
		<LocalMultiSelect {...props} />
	);
};
export default MMultiSelect;
