import { forwardRef, useImperativeHandle, useRef } from "react";
import MSDateTimePicker from "./MSDateTimePicker.jsx";

const MSDatePicker = (
	{
		label,
		format = "YYYY-MM-DD",
		onChange,
		warning,
		isReadOnly,
		suffix,
		helperText,
		defaultValue,
		isDisabled,
		error,
		status,
		onBlur,
	},
	ref
) => {
	if (!error) error = status?.errors;
	if (!warning) warning = status?.warnings;
	if (!isReadOnly) isReadOnly = status?.isReadOnly;
	if (!isDisabled) isDisabled = status?.isDisabled;

	useImperativeHandle(ref, () => ({
		date: inputRef.current?.date,
	}));

	const inputRef = useRef(null);

	return (
		<MSDateTimePicker
			ref={inputRef}
			onBlur={onBlur}
			suffix={suffix}
			defaultValue={defaultValue}
			time={false}
			format={format}
			label={label}
			onChange={onChange}
			warning={warning}
			error={error}
			status={status}
			isDisabled={isDisabled}
			isReadOnly={isReadOnly}
			helperText={helperText}
		/>
	);
};

export default forwardRef(MSDatePicker);
