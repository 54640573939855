import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

const isRTL = () => document.dir === "rtl";
const PopupMenu = ({
	element,
	onClose,
	children,
	popupClassName,
	transformOrigin = {
		vertical: "",
		horizontal: "",
	},
	elementOrigin = {
		vertical: "top",
		horizontal: "right",
	},
}) => {
	const handleClickOutside = useCallback(
		(event) => {
			if (!element.contains(event.target)) {
				onClose && onClose();
			}
		},
		[element, onClose]
	);
	const popupRef = useRef(null);
	const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0, right: 0 });
	const widthElement = element?.getBoundingClientRect().width;
	const heightElement = element?.getBoundingClientRect().height;

	useEffect(() => {
		if (element && popupRef) {
			document.addEventListener("click", handleClickOutside);
			const { bottom: newTop, left: newLeft, height: heightElement } = element.getBoundingClientRect();
			const viewportWidth = window.innerWidth;
			const viewportHeight = window.innerHeight;
			const popupRect = popupRef.current.getBoundingClientRect();
			let top = newTop + window.scrollY - heightElement;
			let left = newLeft;
			let right = viewportWidth - widthElement - newLeft;

			if (top + popupRect.height > viewportHeight) {
				top = top - (top + popupRect.height - viewportHeight);
			}
			if (left + popupRect.width > viewportWidth) {
				left = left - (left + popupRect.width - viewportWidth);
			}
			if (right + popupRect.width > viewportWidth) {
				right = right - (right + popupRect.width - viewportWidth);
			}
			setMenuPosition({ top: top, left: left, right });
		} else {
			document.removeEventListener("click", handleClickOutside);
		}

		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, [element, handleClickOutside, widthElement]);

	const aClassName = ` ${popupClassName ?? ""} m-popup-menu transform-${transformOrigin.horizontal}-${
		transformOrigin.vertical
	}`;
	// const aClassName = `m-popup-menu `;
	const statistics = {
		// right: 0,
		// left: -widthElement,
		right: widthElement,
		center: widthElement / 2,
		bottom: heightElement,
		top: 0,
	};
	return (
		Boolean(element) &&
		ReactDOM.createPortal(
			<div
				id="omran-menu"
				ref={popupRef}
				style={{
					minWidth: element.getBoundingClientRect()?.width,
					top: menuPosition.top + (statistics[elementOrigin.vertical] ?? 0),
					right: isRTL() ? menuPosition.right + (statistics[elementOrigin.horizontal] ?? 0) : "unset",
					left: isRTL() ? "unset" : menuPosition.left + (statistics[elementOrigin.horizontal] ?? 0),
				}}
				className={`${aClassName} ${menuPosition.top === 0 ? "opacity-0" : "opacity-100"} `}
			>
				{children}
			</div>,
			document.body
		)
	);
};

export default PopupMenu;
