import { CLASS_SYMBOL_SYSTEMCLASS } from "../../../shared/core/MConsts.js";
import { FIELD_NAME_API_HANDLER } from "../../../../constants/index.js";
export default (Base, application, aApi) =>
	class UiClass extends Base {
		//#region Informations
		static get [CLASS_SYMBOL_SYSTEMCLASS]() {
			return true;
		}
		//#endregion Informations
		//#region ApiManagment
		//#region Deprecated
		async get(...args) {
			return await this.constructor.get(...args);
		}
		async post(...args) {
			return await this.constructor.post(...args);
		}
		static async get(...args) {
			console.warn("method UIController.get is deprecated, please use apiGet!..");
			return await this.apiGet(...args);
		}
		static async post(...args) {
			console.warn("method UIController.post is deprecated, please use apiPost!..");
			return await this.apiPost(...args);
		}
		//#endregion Deprecated
		async apiGet(...args) {
			return await this.constructor.apiGet(...args);
		}
		async apiPost(...args) {
			return await this.constructor.apiPost(...args);
		}
		get [FIELD_NAME_API_HANDLER]() {
			return aApi;
		}
		static get [FIELD_NAME_API_HANDLER]() {
			return aApi;
		}
		static async apiGet(aSubApi, aParams) {
			try {
				const api = this.meta.api + "/" + aSubApi;
				const result = await this[FIELD_NAME_API_HANDLER].get(api, aParams ? { params: aParams } : undefined);
				if (result.status === 200) {
					return result.data;
				}
				console.error(result);
				return false;
			} catch (errors) {
				console.error(errors);
				return false;
			}
		}
		static async apiPost(aSubApi, aData) {
			try {
				let config;
				if (aData instanceof FormData) {
					config = { headers: { "Content-Type": "multipart/form-data" } };
				}
				const api = this.meta.api + "/" + aSubApi;
				const result = await this[FIELD_NAME_API_HANDLER].post(api, aData, config);
				if (result.status === 200) {
					return result.data;
				}
				console.error(errors);
				return false;
			} catch (errors) {
				console.error(errors);
				return false;
			}
		}
		//#endregion ApiManagment
		//#region UIMethods
		translate(...args) {
			return this.meta.translate(...args);
		}
		static translate(...args) {
			return this.meta.translate(...args);
		}
		static async prepare_cls() {
			const meta = this.meta;
			const fields = meta.configs.fields;
			if (!fields) return;
			const loaders = [];
			Object.values(fields).forEach((config) => {
				const func = config.verifyInstanceClass;
				if (typeof func === "function") {
					loaders.push(func.call(config));
				}
			});
			await Promise.all(loaders);
		}
		//#endregion UIMethods
	};
