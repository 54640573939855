import MButton from "@compts:std/MSButton/MSButton";
import { useStyle } from "../../../context/AppContext";
import MSTypography from "@compts:std/MSTypography/MSTypography";
import MSPaper from "@compts:std/MSPaper/MSPaper";

export default function ChangeTheme() {
	const style = useStyle();
	function handleDark(value) {
		// if (value) style.mainColor = style.mainColor;
		// else style.mainColor = style.mainColor.replace("Dark", "");
		style.dark = value;
	}

	// const dark = JSON.parse(localStorage.getItem("dark"));
	const DARKAPOLLO = "darkAPOLLO";
	const LIGHTAPOLLO = "lightAPOLLO";
	const DARKIKAROS = "darkIKAROS";
	const LIGHTHORIZONTAL = "lightHorizontal";
	function changeTheme(theme, title) {
		switch (theme) {
			case DARKAPOLLO:
				style.layout = title;
				handleDark(true);
				break;
			case LIGHTAPOLLO:
				style.layout = title;
				handleDark(false);
				break;
			case DARKIKAROS:
				style.layout = title;
				handleDark(true);
				break;
			case LIGHTHORIZONTAL:
				style.layout = title;
				handleDark(false);
				break;
			default:
				break;
		}
	}

	// function handelDark(value) {
	// 	localStorage.setItem("dark", value);
	// 	const htmlEl = document.documentElement;
	// 	htmlEl.setAttribute("dark", value);
	// 	toggleDark(value);
	// }
	const themes = [
		{
			img: "/assets/images/vertical.png",
			themeLight: LIGHTAPOLLO,
			themeDark: DARKAPOLLO,
			title: "Apollo",
		},
		{
			img: "/assets/images/horizontal.png",
			themeLight: LIGHTHORIZONTAL,
			themeDark: DARKIKAROS,
			title: "Ikaros",
		},
	];
	return (
		<div className="flex w-100 flex-col gap-5">
			{themes.map((ele, i) => (
				<div className="flex w-100 items-center flex-col gap-0" key={i}>
					<div className="div-img flex flex-col gap-2 items-center">
						<div className="overlay">
							<MButton
								onClick={() => changeTheme(ele.themeLight, ele.title)}
								className="bg-white text-black hover:bg-[#ffffff95]"
								label="Light"
							/>

							<MButton
								className="bg-black text-white hover:bg-[#00000095]"
								onClick={() => changeTheme(ele.themeDark, ele.title)}
								label="Dark"
							/>
						</div>
						<MSPaper elevation={5} className="shadow-none !p-0">
							<img src={ele.img} alt="vertical" />
						</MSPaper>
					</div>
					<MSTypography variant="body1"> {ele.title}</MSTypography>
				</div>
			))}
		</div>
	);
}
