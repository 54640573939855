import { forwardRef } from "react";

function LocalMPaper({ className, children, ...props }, ref) {
	return (
		<div className={`m-paper w-fit rounded-default bg-backgroundLight ${className}`} {...props} ref={ref}>
			{children}
		</div>
	);
}

const MSPaper = forwardRef(LocalMPaper);

export default MSPaper;
