import React, { useRef } from "react";

export const MLoadingIcon = ({ loading = true, type = "liner", height, width, size = 30, duration = 0.8 }) => {
	const loadingRef = useRef(null);
	return (
		<div className="m-loader">
			{" "}
			<div
				ref={loadingRef}
				style={{
					width: `${width}px`,
					height: `${height}px`,
				}}
				className={` ${type === "liner" ? "linear-progress" : "circular-loader"} ${loading ? "active" : ""}`}
			>
				<div
					className="loader"
					style={{ width: `${size}px`, height: `${height || size}px`, animationDuration: `${duration}s` }}
				></div>
			</div>
		</div>
	);
};

export default MLoadingIcon;
