import { MSInput } from "@compts:stdCommon";
import React, { useState, useEffect, useCallback, useImperativeHandle, memo, useRef, useMemo } from "react";
import MSNumber from "../common/MSNumber.jsx";
import { useTranslation } from "@i18n";
import MIcon from "@compts:std/MSIcon/MSIcon.jsx";
import MSTypography from "@compts:std/MSTypography/MSTypography.jsx";
import { MLoadingIcon } from "@compts:misc/MLoadingIcon.jsx";
import MPagination from "@compts:misc/MPagination.jsx";

const MSTable = (
	{
		rows: defaultRows,
		columns: aColumns,
		superHeader,
		loading: aLoading = false,
		fixedHeader = true,
		scroll = true,
		renderLoading = "loading .......",
		onChangeOrderColumn,
		onChangeOrderRow,
		className,
		onRowSelectionModelChange,
		hiddenFotter = false,
		onRowDoubleClick,
		disableSelectedCell,
		onPressEnter,
		dataLoader,
		pageMax = 10000,
		pageMin = 1,
		page: aPage = 1,
		perPage: aPerPage = 10,
		getRowId = (row) => row.id,
	},
	ref
) => {
	//region Table
	const [rows, setRows] = useState(defaultRows);
	const [columns, setColumns] = useState(aColumns);
	const [rowOrder, setRowOrder] = useState(rows?.map((_, index) => index));
	const cellRefs = useRef([]);
	const rowsRef = useRef([]);
	const [cellIndex, setCellIndex] = useState([0, 0]);
	const trans = useTranslation();
	const [page, setPage] = useState(aPage);
	const [perPage, setPerPage] = useState(aPerPage);
	const [loading, setLoading] = useState(aLoading);
	const firstColIndex = useMemo(() => {
		let index = 0;
		columns.forEach((ele) => (ele.hidden === true ? (index += 1) : null));
		return index;
	}, [columns]);
	const visibleColumns = useMemo(() => columns?.filter((col) => !col.hidden), [columns]);
	const [columnOrder, setColumnOrder] = useState(columns?.map((_, index) => index));
	const tableRef = useRef(null);
	const paginationRef = useRef(null);

	const handleDragStart = (index, column = true) => {
		if (column) return (tableRef.current.draggedColumnIndex = index);
		tableRef.current.draggedRowIndex = index;
	};
	const handleDragOver = (index, column = true) => {
		if ((tableRef.current.draggedColumnIndex && column) || (tableRef.current.draggedRowIndex && !column)) {
			const draggedIndex = tableRef.current.draggedColumnIndex || tableRef.current.draggedRowIndex;
			if (index !== draggedIndex) {
				const newOrder = column ? [...columnOrder] : [...rowOrder];
				const targetIndex = newOrder.findIndex((ele) => ele === index);
				const currentIndext = newOrder.findIndex((ele) => ele === draggedIndex);
				const temp = newOrder[targetIndex];
				newOrder[targetIndex] = newOrder[currentIndext];
				newOrder[currentIndext] = temp;
				if (columns[targetIndex]?.fixed === undefined && columns[currentIndext]?.fixed === undefined) {
					setTimeout(() => (column ? setColumnOrder(newOrder) : setRowOrder(newOrder)), 200);
					column && onChangeOrderColumn && onChangeOrderColumn(newOrder);
					!column && onChangeOrderRow && onChangeOrderRow(newOrder);
				}
			}
		}
	};
	const handleDrop = () => {
		tableRef.current.draggedColumnIndex = null;
		tableRef.current.draggedRowIndex = null;
	};
	//#region Column
	const Column = memo(function Column({ column, index }) {
		const [width, setWidth] = useState(column?.width);

		const colRef = useRef(null);
		const handleMouseDown = useCallback((event, width) => {
			if (!width) width = colRef.current.getBoundingClientRect().width;
			event.preventDefault();
			const startX = event.clientX;
			// const startWidth = columnWidths[index];

			const handleMouseMove = (e) => {
				let newWidth = trans.isLTR ? width + (e.clientX - startX) : width - (e.clientX - startX);
				setWidth((prevWidth) => {
					newWidth = Math.max(newWidth, 50);

					if (isNaN(newWidth)) return prevWidth;
					return newWidth;
				});
			};

			const handleMouseUp = () => {
				document.removeEventListener("mousemove", handleMouseMove);
				document.removeEventListener("mouseup", handleMouseUp);
			};

			document.addEventListener("mousemove", handleMouseMove);
			document.addEventListener("mouseup", handleMouseUp);
		}, []);

		return column?.hidden ? null : (
			<th
				draggable={column?.fixed === undefined}
				onDragStart={() => handleDragStart(index)}
				onDragOver={() => handleDragOver(index)}
				onDrop={handleDrop}
				// style={{ width: width ?? "fit-content" }}
				className={`header-cell ${column?.fixed ?? ""}`}
			>
				<div className="w-fit cursor-move " ref={colRef}>
					{column?.title}
				</div>
				<div
					className="column-seperator"
					tabIndex={0}
					onDoubleClick={() => setWidth(colRef.current.getBoundingClientRect().width)}
					onMouseDown={(event) => handleMouseDown(event, width)}
				></div>
			</th>
		);
	});

	//#region Row
	const Row = memo(function Row({ row, rowIndex }) {
		const [height, setHeight] = useState(40);

		function InputEdit({ column, rowKey, onClickOutSide, type = "text", ...props }) {
			let defaultValue = row[rowKey];
			const eleRef = useRef(null);
			if (typeof column?.valueGetter === "function") defaultValue = column?.valueGetter(defaultValue, row);
			const [getter, setter] = useState(defaultValue);
			useEffect(() => {
				window.addEventListener("click", handleClickOutSide);
				eleRef.current.input?.focus();
				function handleClickOutSide(event) {
					if (!eleRef.current.input.contains(event.target)) return onClickOutSide(eleRef.current.input?.value);
				}
				return () => window.removeEventListener("click", handleClickOutSide);
			}, [eleRef, onClickOutSide]);
			return type === "text" ? (
				<MSInput
					ref={eleRef}
					size="small"
					value={getter}
					className="!p-0 !w-fit input-cell"
					onChange={(event) => {
						setter(event.target.value);
					}}
					onKeyDown={(event) => event.key === "Enter" && onClickOutSide(eleRef.current.input?.value)}
					{...props}
				></MSInput>
			) : (
				<MSNumber
					ref={eleRef}
					size="small"
					value={getter}
					className="!p-0 !w-fit input-cell"
					onChange={(event) => {
						setter(event.target.value);
					}}
					onKeyDown={(event) => event.key === "Enter" && onClickOutSide(eleRef.current.input?.value)}
					{...props}
				/>
			);
		}

		function HandleEditCell({ column, rowKey, onClickOutSide }) {
			const getType = {
				text: <InputEdit column={column} rowKey={rowKey} onClickOutSide={onClickOutSide} />,
				number: <InputEdit column={column} type="number" rowKey={rowKey} onClickOutSide={onClickOutSide} />,
			};
			return getType[column?.type ?? "text"];
		}
		//#region Cell
		const Cell = memo(
			React.forwardRef(({ column, rowKey, rowIndex, colIndex }, ref) => {
				const cellRef = useRef(null);
				const [editMode, setEditMode] = useState(false);
				const [render, setRender] = useState(false);
				const handleKeyDown = (e, rowIndex, colIndex) => {
					if (disableSelectedCell) return;
					if (e.key !== "Enter" && editMode) return;
					let newRowIndex = rowIndex;
					let newColIndex = colIndex;
					switch (e.key) {
						case "Enter":
							column?.editable && setEditMode(!editMode);
							break;

						case "ArrowRight":
							if (trans.isLTR) newColIndex = Math.min(colIndex + 1, visibleColumns.length - 1);
							else newColIndex = Math.max(colIndex - 1, firstColIndex);
							break;
						case "ArrowLeft":
							if (trans.isLTR) newColIndex = Math.max(colIndex - 1, firstColIndex);
							else newColIndex = Math.min(colIndex + 1, visibleColumns.length - 1);
							break;
						case "ArrowDown":
							e.stopPropagation();
							newRowIndex = Math.min(rowIndex + 1, rows.length - 1);
							onRowSelectionModelChange && onRowSelectionModelChange(rows[rowIndex + 1]);
							break;
						case "ArrowUp":
							e.stopPropagation();
							newRowIndex = Math.max(rowIndex - 1, 0);
							onRowSelectionModelChange && onRowSelectionModelChange(rows[rowIndex - 1]);

							break;
						default:
							return;
					}
					e.preventDefault();
					cellRefs.current[newRowIndex][newColIndex].current?.cell.focus();
				};

				function handleSetValue(value) {
					setEditMode(false);
					if (typeof column?.valueSetter === "function") column.valueSetter(value, row);
					else handleUpdateRows([{ ...row, [`${rowKey}`]: value }]);
					setCellIndex([rowIndex, colIndex]);
				}
				function handleCell(column, rowKey) {
					let value = row[rowKey];
					if (typeof column?.valueGetter === "function") value = column.valueGetter(value, row, column);
					if (typeof column?.valueFormatter === "function") value = column.valueFormatter(value, row);
					if (typeof column?.render === "function") return column.render(value, row);
					return value;
				}
				function handledoubleClickCell() {
					column?.editable && setEditMode(true);
					onRowDoubleClick && onRowDoubleClick(row);
				}

				useImperativeHandle(ref, () => ({
					renderCell: (value) => setRender(!render),
					cell: cellRef.current,
				}));
				return (
					<td
						tabIndex={0}
						ref={cellRef}
						onKeyDown={(e) => handleKeyDown(e, rowIndex, colIndex)}
						onClick={(event) => {
							setTimeout(() => {
								!disableSelectedCell && setCellIndex([rowIndex, colIndex]);
							}, 10);
						}}
						className={`row-cell ${editMode ? "editing" : ""} ${column?.fixed ?? ""} ${!column ? "hidden" : ""}`}
						onDoubleClick={handledoubleClickCell}
					>
						{column ? (
							editMode ? (
								<HandleEditCell column={column} rowKey={rowKey} onClickOutSide={handleSetValue} />
							) : (
								handleCell(column, rowKey)
							)
						) : null}
					</td>
				);
			})
		);

		const handleMouseDown = useCallback((event, height) => {
			event.preventDefault();
			const startY = event.clientY;
			// const startWidth = columnWidths[index];

			const handleMouseMove = (e) => {
				let newHeight = height + (e.clientY - startY);
				setHeight((prevHeight) => {
					newHeight = Math.max(newHeight, 40);
					if (isNaN(newHeight)) return prevHeight;
					return newHeight;
				});
			};

			const handleMouseUp = () => {
				document.removeEventListener("mousemove", handleMouseMove);
				document.removeEventListener("mouseup", handleMouseUp);
			};

			document.addEventListener("mousemove", handleMouseMove);
			document.addEventListener("mouseup", handleMouseUp);
		}, []);
		// useEffect(() => {
		// 	if (rowSelectionModel) setSelectedRow(rowSelectionModel);
		// }, [rowSelectionModel]);
		function handleRowKeyDown(event) {
			if (event.key === "Enter") return onPressEnter && onPressEnter(row);
			if (event.key === "ArrowUp" && rowIndex > 0) {
				event.preventDefault();
				rowsRef.current[rowIndex - 1]?.focus();
				// setSelectedRow(getRowId(rows[rowIndex - 1]));
			}
			if (event.key === "ArrowDown" && rowIndex < rowsRef.current.length) {
				rowsRef.current[rowIndex + 1]?.focus();
				event.stopPropagation();
				// setSelectedRow(getRowId(rows[rowIndex + 1]));
			}
		}

		return (
			<tr
				ref={(rowRef) => {
					rowsRef.current[rowIndex] = rowRef;
				}}
				// className={`row ${selectedRow === getRowId(row) ? "selected" : ""}`}
				className={`row`}
				style={{ height: `${height}px` }}
				draggable
				onDragStart={() => handleDragStart(rowIndex, false)}
				onDragOver={() => handleDragOver(rowIndex, false)}
				onFocus={() => {
					onRowSelectionModelChange && onRowSelectionModelChange(row);
				}}
				// onClick={() => setSelectedRow(getRowId(row))}
				onKeyDown={handleRowKeyDown}
				tabIndex={0}
				onDrop={handleDrop}
			>
				<td className={`row-cell index ${trans.isLTR ? "left" : "right"}`}>
					<div>{perPage * (page - 1) + rowIndex + 1}</div>
					<div className="row-seperator" tabIndex={0} onMouseDown={(event) => handleMouseDown(event, height)}></div>
				</td>

				{columnOrder?.map((columnIndex, i) => {
					const column = columns[columnIndex];
					const rowKey = column?.dataIndex;
					if (!cellRefs.current[rowIndex]) cellRefs.current[rowIndex] = [];
					// cellRefs.current[rowIndex][colIndex] = el;
					// if (!cellRefs.current[rowIndex][columnIndex]) {
					cellRefs.current[rowIndex][columnIndex] = React.createRef();
					// }

					return column?.hidden ? null : (
						<Cell
							key={i}
							ref={cellRefs.current[rowIndex][columnIndex]}
							column={column}
							rowKey={rowKey}
							rowIndex={rowIndex}
							colIndex={i}
						/>
					);
				})}
			</tr>
		);
	});

	//#region updateRows
	const handleUpdateRows = (data, isDelete, by) => {
		if (!data) return;
		const result = (rows && [...rows]) || [];
		data?.forEach((newRow) => {
			const index = result.findIndex((row) => String(getRowId(row)) === String(getRowId(newRow)));

			if (index >= 0) {
				if (isDelete) result.splice(index, 1);
				else {
					result[index] = newRow;
				}
			} else {
				return result.push(newRow);
			}
		});
		setRows(result);
		setRowOrder(result?.map((_, index) => index));
	};

	const handleUpdateColumn = useCallback((column) => {
		setColumns((prevColumns) => {
			const index = prevColumns.findIndex((col) => col.dataIndex === column.dataIndex);
			if (index >= 0) {
				prevColumns[index] = column;
				return [...prevColumns];
			}
			return prevColumns;
		});
	}, []);
	const handleRenderCell = useCallback((columnIndex, rowIndex, value) => {
		if ((rowIndex || rowIndex === 0) && (columnIndex || columnIndex === 0)) {
			const cellRef = cellRefs.current[rowIndex]?.[columnIndex]?.current;
			if (cellRef) cellRef.renderCell(value);
			return cellRef;
		} else {
			throw new Error("please check from row and column index");
		}
	}, []);
	useImperativeHandle(ref, () => ({
		updateRows: handleUpdateRows,
		updateLoading: (value) => setLoading(value),
		updateColumn: handleUpdateColumn,
		table: tableRef.current,
		focus: () => rowsRef.current[0]?.focus(),
		updateCell: handleRenderCell,
	}));
	useEffect(() => {
		if (cellRefs.current[cellIndex[0]])
			if (cellRefs.current[cellIndex[0]][cellIndex[1]]) {
				cellRefs.current[cellIndex[0]][cellIndex[1]].current?.cell.focus();
			}
	}, [cellIndex]);
	useEffect(() => {
		setColumns(aColumns);
		setColumnOrder(aColumns?.map((_, index) => index));
	}, [aColumns]);
	useEffect(() => {
		setRowOrder(defaultRows?.map((_, index) => index));
		setRows(defaultRows);
	}, [defaultRows]);
	const [scrollLoading, setScrollLoading] = useState(false);
	useEffect(
		() => setScrollLoading(scroll && dataLoader && rows?.length >= perPage),
		[scroll, rows, dataLoader, perPage]
	);
	function EmptyTable() {
		return (
			<tr className="empty">
				<td colSpan={visibleColumns.length + 1}></td>
			</tr>
		);
	}

	let newRender = true;
	function handleScroll(event) {
		if (scrollLoading && newRender) {
			const { scrollTop, clientHeight, scrollHeight } = event.target;
			if (scrollTop > scrollHeight - clientHeight - 80) {
				handleLoadMoreData(event);
				newRender = false;
			}
		}
	}
	function handleAddRows(data, push = false) {
		const newRows = push ? [...rows, ...data] : data;
		setRows(newRows);
		setRowOrder(newRows.map((_, index) => index));
	}
	async function handleChangePagination(paginate, isTry) {
		const page = paginate.page;
		const perPage = paginate.perPage;
		const result = await dataLoader((page - 1) * perPage, perPage);

		if (result) {
			if (result.length === +perPage) paginationRef.current.updateValues("max", page + 5);
			if (result.length < perPage || isTry) {
				paginationRef.current.updateValues("max", page);
				paginationRef.current.updateValues("page", page);
				// setPage(page);
			}
			handleAddRows(result);
			setPage(page);
			setPerPage(perPage);
		} else {
			handleChangePagination({ page: page - 1, perPage }, true);
		}
	}
	async function handleLoadMoreData(event) {
		const result = await dataLoader(page * perPage, perPage);
		if (result) {
			handleAddRows(result, true);
			setPage(page + 1);
			event.target.scrollTop -= 100;
			if (result.length < perPage) setScrollLoading(false);
		} else setScrollLoading(false);
	}
	return (
		<div className="m-table" ref={tableRef}>
			<div className="h-[calc(100%-50px)]">
				{superHeader && <div className="super-header">{superHeader}</div>}
				<div className={`table-container ${className ?? ""}`} onScroll={handleScroll}>
					<table border="none" className="table">
						<thead
							className={`header  ${columnOrder?.length > 0 ? "" : "loading"} ${fixedHeader ? "fixed-header" : ""}`}
						>
							<tr>
								<th className={`header-cell index ${trans.isLTR ? "left" : "right"}`}> # </th>
								{/* {columns.map((column, i) => (
								<Column column={column} index={i} key={i} />
							))} */}

								{columnOrder?.map((index) => (
									<Column column={columns[index]} key={index} index={index} />
								))}
							</tr>
						</thead>
						<tbody className="body">
							{loading ? (
								<tr>
									<td colSpan={columns?.length + 1}>
										<div className={`loading ${columnOrder?.length > 0 ? "" : "hidden"}`}>
											{/* <div>{renderLoading}</div> */}
											<MLoadingIcon width={200} height={4} />
										</div>
									</td>
								</tr>
							) : rowOrder?.length > 0 ? (
								<>
									{rowOrder?.map((rowIndex) => (
										<Row row={rows[rowIndex]} key={rowIndex} rowIndex={rowIndex} />
									))}
									{scrollLoading && (
										<tr>
											<td colSpan={columns?.length + 1}>
												<div className="scroll-loading">
													<div>{"loading ....."}</div>
													<MLoadingIcon width={200} height={4} />
												</div>
											</td>
										</tr>
									)}
								</>
							) : (
								<EmptyTable />
							)}
						</tbody>
						{/* {!hiddenFotter && (
						<tfoot className={`footer ${fixedFooter ? "fixed-footer" : ""}`}>
							<tr>
								<td></td>
								<td colSpan={columns.length + 1}>
									<div>hello footer</div>
								</td>
							</tr>
						</tfoot>
					)} */}
					</table>
					{rowOrder?.length <= 0 && !loading && columns?.length > 0 && (
						<div className="flex flex-col items-center empty-content">
							<MIcon name="PoundBox" size={3} className="text-divider" />
							<MSTypography className="!text-disabled ">{trans.t("table.emptyTable")}</MSTypography>
						</div>
					)}
				</div>
			</div>
			{!scroll && dataLoader && (
				<div className="pagination">
					<MPagination
						ref={paginationRef}
						min={pageMin}
						max={pageMax}
						page={page}
						perPage={perPage}
						onChange={handleChangePagination}
						disabled={rows?.length <= 0}
					/>
				</div>
			)}
		</div>
	);
};

export default React.forwardRef(MSTable);
