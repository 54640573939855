import i18n from "i18next";
import backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { languages } from "./languages.js";
import { useTranslation, initReactI18next } from "react-i18next";

i18n
	.use(backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: "ar",
		//debug: true,
		supportedLngs: Object.keys(languages),
		detection: {
			order: ["path", "localStorage", "cookie"],
			cache: ["localStorage", "cookie"],
		},
		load: "languageOnly",
		backend: {
			loadPath: "/locales/{{lng}}.json",
			crossDomain: true,
		},
		interpolation: {
			escapeValue: false,
		},
		cache: {
			enabled: true,
			expirationTime: 24 * 60 * 60 * 1000,
		},
	});

export const language = {
	get current() {
		return i18n.language;
	},
	get value() {
		return this.current;
	},
	get name() {
		return languages[this.current].name;
	},
	get icon() {
		return languages[this.current].icon;
	},
	get isRTLDirection() {
		return languages[this.current].isRTLDirection;
	},
	get isLTRDirection() {
		return !languages[this.current].isRTLDirection;
	},
};

export { i18n, languages, useTranslation };
export default i18n;
