import MMeta from "../MMeta.js";
import { DataTypes, MRtvInstance } from "@systypes";
import {
	CLASS_SYMBOL_SYSTEMCLASS,
	BUILDER_ENGINE_SYMBOL_ADDCLASS,
	BUILDER_ENGINE_SYMBOL_FINALIZE,
	BUILDER_ENGINE_SYMBOL_BUILDCLASS,
} from "../MConsts.js";
import { CLASS_PROP_META } from "../../../../constants/index.js";

const CLASS_SYMBOL_METACLASS = Symbol();
export default class MBuilderEngine {
	//#region Constructor
	#baseParams;
	#classes = {};
	constructor(aApplication) {
		const datatypes = DataTypes;
		const entities = aApplication.entities;
		this.#baseParams = {
			deferred: [],
			datatypes,
			DataTypes: datatypes,
			Controllers: entities,
		};
	}
	//#region Constructor
	//#region Building
	async [BUILDER_ENGINE_SYMBOL_BUILDCLASS](aClass, aApp, aName, aDeclaration) {
		let parentMeta;
		let cls = aClass;
		const metaDataGetters = [];
		do {
			if (Object.hasOwn(cls, "getMetaData")) {
				metaDataGetters.push(cls.getMetaData);
			}
			cls = Object.getPrototypeOf(cls);
			if (Object.hasOwn(cls, CLASS_PROP_META)) {
				parentMeta = await cls[CLASS_PROP_META];
				break;
			}
		} while (!Object.hasOwn(cls, CLASS_SYMBOL_SYSTEMCLASS));
		let metaClass = cls[CLASS_SYMBOL_METACLASS];
		if (!metaClass) {
			metaClass = MMeta;
			const start = cls;
			do {
				if (!Object.hasOwn(cls, CLASS_SYMBOL_SYSTEMCLASS)) {
					throw new Error(`Class ${cls.name} must be SystemClass!...`);
				}
				const extenders = cls[CLASS_SYMBOL_SYSTEMCLASS].meta;
				if (extenders) {
					const extend = (extender) => {
						if (typeof extender !== "function") return;
						metaClass = extender.call(aClass, metaClass, this) || metaClass;
					};
					if (!Array.isArray(extenders)) extend(extenders);
					else extenders.forEach(extend);
				}
				cls = Object.getPrototypeOf(cls);
			} while (cls !== MRtvInstance);
			start[CLASS_SYMBOL_METACLASS] = metaClass;
		}
		const meta = metaClass.create_cls(aDeclaration, aName, aApp);
		const metaData = [];
		for (let func of metaDataGetters) {
			const data = await func.call(aClass, this.#baseParams);
			if (data) metaData.push(data);
		}
		const params = {
			builder: this.#baseParams,
			datatypes: this.#baseParams.datatypes,
		};
		await meta.build(
			metaData,
			parentMeta,
			(meta, metaData, ...args) => {
				metaData.forEach((data) => this.treatMetaData(meta, data, ...args));
			},
			params
		);
		Object.defineProperty(aClass, CLASS_PROP_META, { value: meta });
		const notifier = aClass.builded;
		if (typeof notifier === "function") {
			await notifier.call(aClass, meta);
		}
		return meta;
	}
	[BUILDER_ENGINE_SYMBOL_ADDCLASS](aClass, aApp, aName, aDeclaration) {
		if (this.#classes[aName]) {
			throw new Error(`Class ${aName} was already registered!..`);
		}
		let resolve, reject;
		aClass[CLASS_PROP_META] = new Promise((...args) => ([resolve, reject] = args));
		this.#classes[aName] = () =>
			this[BUILDER_ENGINE_SYMBOL_BUILDCLASS](...arguments)
				.then((res) => {
					resolve(res);
					return res;
				})
				.catch(reject);
	}
	async [BUILDER_ENGINE_SYMBOL_FINALIZE]() {
		const builders = Object.values(this.#classes).map((func) => func());
		await Promise.all(builders);
		await Promise.all(this.#baseParams.deferred.map((func) => func()));
		return this;
	}
	//#endregion Building
	//#region ClassBuilding
	treatRelationDefinition(aName, aColumn, aMeta, aParams) {
		throw new Error("treatRelationDefinition was not interrupted!.");
	}
	treatColumnDefinition(aName, aColumn, aMeta, aParams) {
		throw new Error("treatColumnDefinition was not interrupted!.");
	}
	treatTable(aMeta, aTableData, aParams) {
		for (let [name, value] of Object.entries(aTableData.columns)) {
			if (value.primaryKey && aParams.isPrimaryTable) {
				aMeta.setPrimaryKey(name);
			}
			if (value.relation) {
				this.treatRelationDefinition(name, value, aMeta, aParams);
			} else if (value.type) {
				this.treatColumnDefinition(name, value, aMeta, aParams);
			} else {
				throw new Error(`Unknown column definition for '${aParams.name}.${name}'!..`);
			}
			if (value.caption) {
				aMeta.addToCaption(name, value.caption);
			}
		}
	}
	treatTables(aMeta, aData, aParams) {
		const tables = aData.tables;
		const tableParams = {};
		Object.assign(tableParams, aParams);
		tableParams.definitionMetaData = aData;
		tableParams.isPrimaryTable = true;
		if (tables === undefined) {
			const name = aData.tableName;
			if (!name) {
				if (aData.columns) {
					throw new Error(`tableName is required in meta ${aMeta.name}!..`);
				}
				if (!aMeta.parent?.tables) {
					throw new Error(`meta ${aMeta.name} must have one table at least!..`);
				}
				return;
			}
			tableParams.name = name;
			this.treatTable(aMeta, aData, tableParams);
		} else if (!Array.isArray(tables)) {
			throw new Error(`tables must be array for container ${aMeta.name}`);
		} else {
			if (tables.length === 1) {
				tableParams.name = tables[0].name;
				this.treatTable(aMeta, tables[0], tableParams);
				return;
			}
			var primaryTable = tables.find((t) => t.isPrimaryTable);
			if (!primaryTable) {
				throw new Error(`primaryTable for container ${aMeta.name} is required!.`);
			}
			tableParams.name = primaryTable.name;
			this.treatTable(aMeta, primaryTable, tableParams);
			delete tableParams.isPrimaryTable;
			tableParams.baseTable = primaryTable.name;
			for (let table of tables) {
				if (table !== primaryTable) {
					tableParams.name = table.name;
					this.treatTable(aMeta, table, tableParams);
				}
			}
		}
	}
	treatAliases(aMeta, aData) {
		const aliases = aData.aliases;
		if (aliases) {
			for (let [key, value] of Object.entries(aliases)) {
				aMeta.addAlias(key, value);
			}
		}
	}
	treatListFieldDefinition(aName, aDefinition, aMeta) {
		throw new Error("treatListFieldDefinition was not interrupted!.");
	}
	treatFieldDefinition(aName, aDefinition, aMeta) {
		if (aDefinition.listOf) {
			this.treatListFieldDefinition(...arguments);
		} else if (aDefinition.type) {
			aMeta.addTypedField(aName, aDefinition.type, aDefinition);
		} else {
			throw new Error(`Unkown definition of field: ${aMeta.name}.${aName}`);
		}
	}
	treatFields(aMeta, aData) {
		const fields = aData.fields;
		if (fields) {
			for (let [key, value] of Object.entries(fields)) {
				if (typeof value !== "object") {
					console.error(`field definition for Key: ${aMeta.name}.${key}, must be object!.`);
				} else {
					this.treatFieldDefinition(key, value, aMeta);
				}
			}
		}
	}
	treatMetaData(aMeta /*, aData, aParams*/) {
		this.treatTables(...arguments);
		this.treatFields(...arguments);
		this.treatAliases(...arguments);
	}
	//#endregion ClassBuilding
}
