import MIcon from "@compts:std/MSIcon/MSIcon.jsx";
import { uniqueID } from "@sys:utils";
import React, { useEffect, useState } from "react";
export const MSRadioGroup = React.forwardRef(
	(
		{
			label,
			value = "",
			helperText,
			row,
			name = "default",
			suffix,
			className,
			onChange,
			items = [],
			status,
			isDisabled,
			error,
			warning,
			clearable = false,
			...props
		},
		ref
	) => {
		if (!error) error = status?.errors;
		if (!warning) warning = status?.warnings;
		if (!isDisabled) isDisabled = status?.isDisabled;
		const [aValue, setAValue] = useState(value);
		const classValidation = `${warning ? "warning" : ""} ${error ? "error" : ""}  ${isDisabled ? "disabled" : ""}`;
		function handle(event) {
			onChange && onChange(event);
			setAValue(event.target.value);
		}
		useEffect(() => {
			setAValue(value);
		}, [value]);
		return (
			<div className="h-full">
				<div className="flex flex-col py-[2px] h-full justify-center" {...props} tabIndex={0} ref={ref}>
					<div
						className={`m-radio-group   ${row ? "horizontal" : "border-[2px] border-primary"} ${classValidation} ${
							className ?? ""
						}`}
					>
						<div className="inner-container">
							{label && <div className="label">{label}</div>}
							{clearable && (
								// <div className="">
								<MIcon
									name="Close"
									onClick={(e) => {
										handle({ ...e, target: { ...e.target, value: null } });
									}}
									className="text-disabled clear"
									size={0.9}
								/>
								// </div>
							)}

							{items.map((ele, i) => {
								const id = uniqueID();
								return (
									<div className="m-radio" key={i}>
										<input
											type="radio"
											id={id}
											checked={String(ele.value) === String(aValue)}
											onChange={handle}
											name={id}
											value={ele.value}
											disabled={isDisabled}
										/>
										<label htmlFor={id} className="font-semibold text-primary">
											{ele.label}
										</label>
									</div>
								);
							})}
						</div>
						{suffix}
					</div>
					{helperText ? <div className="helper-text">{helperText}</div> : null}
				</div>
			</div>
		);
	}
);

export default MSRadioGroup;
