import MAppExtender from "../../shared/core/MApp.js";
import MApplicationExtender from "../../shared/core/MApplication.js";
import { APPLICATION_TYPE_CLIENT } from "../../shared/core/MConsts.js";
import UIApp from "./UIApp.js";
import UIApplication from "./UIApplication.js";
export const MApp = MAppExtender(UIApp);
export const MApplication = async (...args) => {
	const Class = MApplicationExtender(APPLICATION_TYPE_CLIENT, UIApplication);
	return await new Class(...args);
};
export { autoCodeByParent } from "../../shared/core/MMeta.js";
export { UIItem } from "./UIMeta.js";
export default MApplication;
