import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import MIcon from "@compts:std/MSIcon/MSIcon.jsx";

function LocalMSInput(
	{
		type,
		value = "",
		disabled,
		onChange,
		label,
		placeholder,
		className,
		prefix,
		innerPrefix,
		suffix,
		helperText,
		onClick,
		status,
		error,
		size,
		warning,
		isReadOnly,
		isDisabled,
		onBlur,
		maxLength,
		allowClear,
		multiline,
		children,
		labelClass,
		rows,
		...props
	},
	ref
) {
	if (!error) error = status?.errors;
	if (!warning) warning = status?.warnings;
	if (!isReadOnly) isReadOnly = status?.isReadOnly;
	if (!isDisabled) isDisabled = status?.isDisabled;
	const [getter, setter] = useState(value);
	const [aType, setAType] = useState(type);
	const inputRef = useRef();

	function handleChange(event) {
		onChange && onChange(event);
		const value = event.target.value;
		setter(value);
	}
	useEffect(() => {
		if (inputRef.current?.value) inputRef.current?.classList?.add("is-fill");
		else inputRef.current?.classList?.remove("is-fill");
	});
	useImperativeHandle(ref, () => ({
		input: inputRef.current,
		updateIsFill: (isAdd) => {
			if (isAdd) inputRef.current?.classList?.add("is-fill");
			else inputRef.current?.classList?.remove("is-fill");
		},
	}));
	useEffect(() => {
		setter(value);
	}, [value]);
	const containerRef = useRef(null);
	useEffect(() => {
		const handleClickOutSide = (event) => {
			if (containerRef.current && !containerRef.current.contains(event.target)) onBlur && onBlur(event);
		};
		document.addEventListener("click", handleClickOutSide);
		return () => document.removeEventListener("click", handleClickOutSide);
	}, [onBlur]);
	return (
		<div className={`container-input ${className ?? ""}`}>
			{prefix && <div>{prefix}</div>}
			<div className="flex flex-col w-full">
				<div
					ref={containerRef}
					className={`container ${size} ${warning ? "warning" : ""} ${error ? "error" : ""}  ${
						isDisabled ? "disabled" : ""
					} ${isReadOnly ? "readonly" : ""}`}
					tabIndex={1}
					onClick={onClick}
				>
					{label && <span className={`label m-input ${labelClass} `}>{label}</span>}
					{innerPrefix && <div className="inner-prefix">{innerPrefix}</div>}
					{allowClear && getter.length > 0 && type !== "password" && (
						<MIcon
							className="second-suffix-icon"
							name="Close"
							onClick={(event) => {
								setter("");
								onChange && onChange(event);
							}}
						/>
					)}
					{type === "password" && (
						<MIcon
							className="text-primary"
							name={aType === "password" ? "Eye" : "EyeOff"}
							onClick={() => (aType === "password" ? setAType("text") : setAType("password"))}
						/>
					)}
					{children ? (
						<div ref={inputRef} className={`m-input`} tabIndex={0} {...props}>
							{children}
						</div>
					) : multiline ? (
						<textarea
							onChange={handleChange}
							rows={rows}
							disabled={isDisabled}
							readOnly={isReadOnly}
							maxLength={maxLength}
							ref={inputRef}
							name="m-textarea"
							className="m-input"
							placeholder={placeholder}
							value={getter}
							{...props}
						></textarea>
					) : (
						<input
							ref={inputRef}
							type={aType}
							placeholder={placeholder}
							value={getter}
							className="m-input"
							// size={size}
							onChange={handleChange}
							disabled={isDisabled}
							readOnly={isReadOnly}
							{...props}
						/>
					)}
					<div className="suffix">{suffix}</div>
				</div>
				{helperText ? (
					<div className="ps-2 pt-0" tabIndex={0}>
						{helperText}
					</div>
				) : null}
			</div>
		</div>
	);
}

export const MSInput = forwardRef(LocalMSInput);

export default MSInput;
