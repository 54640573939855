import React, { useRef } from "react";
import { useTranslation } from "@i18n";
import MSSelect from "@/components/standard/common/MSSelect";
import useItemStatus from "@compts:root/hooks/useItemStatus.js";
import MLoader from "@compts:std/common/MLoader.jsx";

const idByValue = (aValue) => {
	if (aValue && typeof aValue === "object") {
		return aValue.ID.value;
	}
	return aValue ?? null;
};

function LocalSelect({ item, size }) {
	const trans = useTranslation();
	const title = item?.title(trans);
	const elementRef = useRef(null);
	const [getter, setter] = React.useState(idByValue(item?.value));
	const [options, setOptions] = React.useState([]);
	const {
		status,
		validationIcon: suffix,
		validationText,
		showTextValidation,
	} = useItemStatus(item, elementRef.current?.input);
	React.useEffect(() => {
		if (!item) return;
		const doValueChanged = (aNew, aOld, aParams) => {
			if (aParams?.byEditor === elementRef.current) {
				return;
			}
			setter(idByValue(aNew));
		};
		item.onChangedAdd(doValueChanged);
		return () => item.onChangedRemove(doValueChanged);
	}, [item]);
	React.useEffect(() => {
		if (!item) return;
		const doValuesChanged = (aValues) => setOptions(aValues.toSelectOptions(trans));
		item.onValuesAdd(doValuesChanged);
		return () => item.onValuesRemove(doValuesChanged);
	}, [item, trans]);

	const selectValueChanged = React.useCallback(
		(option) => {
			setter(option?.value ?? null);
			item.setValue(option?.instance || option?.value, { byEditor: elementRef.current });
		},
		[item]
	);
	// console.log("select", elementRef.current?.input);

	return (
		<MSSelect
			ref={elementRef}
			label={title}
			size={size}
			value={options.length > 0 ? getter : null}
			status={status}
			options={options}
			suffix={suffix}
			helperText={validationText}
			onBlur={() => {
				showTextValidation(false);
			}}
			onChange={selectValueChanged}
		/>
	);
}

export const MSelect = (props) => {
	const loader = async () => {
		const result = { ...props };
		result.item = await result.item;
		return result;
	};

	return props.item instanceof Promise ? (
		<MLoader component={LocalSelect} loader={loader} />
	) : (
		<LocalSelect {...props} />
	);
};
export default MSelect;
