import MLoader from "@compts:misc/MLoadingIcon.jsx";
import React from "react";

const MSButton = (
	{
		variant = "elevated",
		className,
		contentClassName,
		htmlType,
		color,
		startIcon,
		endIcon,
		type,
		icon,
		label,
		loading = false,
		disabled,
		style,
		...props
	},
	ref
) => {
	return (
		<button
			ref={ref}
			{...props}
			className={`m-btn ${disabled ? "disabled" : ""} ${loading ? "loading" : ""} ${variant} ${type || ""} ${
				className ?? ""
			}`}
			style={style}
			type={htmlType}
		>
			<div className={`content ${contentClassName ?? ""}`}>
				{type !== "circle" ? (
					<>
						{startIcon}
						<div className="label">{label}</div>
						{endIcon}
					</>
				) : (
					<div className="inner-container">{icon}</div>
				)}
			</div>
			{loading && <MLoader type="circle" size={20} />}
		</button>
	);
};

export default React.forwardRef(MSButton);
