import appsRoutesGetter from "./appsRoutes.js";
import MainContent from "@/views/MainContent.jsx";
const getter = (application) => {
	const appsRoutes = appsRoutesGetter(application);
	return [
		{
			name: "home",
			path: "",
			element: <MainContent />,
		},
		...appsRoutes,
	];
};

export default getter;
