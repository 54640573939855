import { MLoadingIcon } from "@compts:misc";
import { useEffect, useState } from "react";

export const MLoader = ({ component: Component, loader }) => {
	const [result, setResult] = useState(null);
	useEffect(() => {
		async function fetchItem() {
			setResult(null);
			const newResult = await loader();
			setResult(newResult);
		}

		fetchItem();
	}, [loader]);

	return !result ? (
		<div className="loading-item">
			<MLoadingIcon type="circle" />
			<Component />
		</div>
	) : (
		<Component {...result} />
	);
};

export default MLoader;
