import MIcon from "@compts:std/MSIcon/MSIcon.jsx";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { createPortal } from "react-dom";

export const MSnackbar = ({ ...props }, ref) => {
	const [visible, setVisible] = useState(false);
	const [type, setType] = useState("info");
	const [children, setChildren] = useState();
	const snackRef = useRef(null);
	const isHoverRef = useRef(false);
	useImperativeHandle(ref, () => ({
		open: (children, type = "info", duration = 3000) => {
			setVisible(true);
			setChildren(children);
			setType(type);
			setInterval(() => {
				if (!isHoverRef.current) {
					setVisible(false);
				}
			}, duration);
		},
		close: () => setVisible(false),
	}));
	const iconNames = {
		success: "CheckCircleOutline",
		error: "CloseOutline",
		warning: "AlertOutline",
		info: "InformationVariant",
	};
	useEffect(() => {
		document.addEventListener("click", handleClick);
		function handleClick(event) {
			if (snackRef.current && !snackRef.current.contains(event.target) && !isHoverRef.current) {
				setVisible(false);
			}
		}
		return () => document.removeEventListener("click", handleClick);
	}, []);
	return (
		visible &&
		createPortal(
			<div
				onMouseEnter={() => (isHoverRef.current = true)}
				onMouseLeave={() => (isHoverRef.current = false)}
				ref={snackRef}
				{...props}
				className={`m-snackbar elevation-2  rounded-md ${type}`}
			>
				<div className="content">
					<MIcon name={iconNames[type]} /> {children}
				</div>
			</div>,
			document.body
		)
	);
};

export default forwardRef(MSnackbar);
