import UiMeta from "../UIMeta.js";
import uiClass from "./uiClass.js";
import { MRtvInstance, MRtv_OBJECT_CONFIGS } from "@systypes";
import { CLASS_SYMBOL_SYSTEMCLASS } from "../../../shared/core/MConsts.js";

export default (...args) =>
	class UiBase extends uiClass(MRtvInstance, ...args) {
		//#region Informations
		static [CLASS_SYMBOL_SYSTEMCLASS] = {
			meta: UiMeta,
		};
		//#endregion Informations
		//#region RtvManagment
		get [MRtv_OBJECT_CONFIGS]() {
			return this.meta.configs;
		}
		static get [MRtv_OBJECT_CONFIGS]() {
			return this.meta.configs;
		}
		//#endregion RtvManagment
	};
