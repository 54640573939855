import { useTranslation } from "@i18n";
import { useState, useEffect, useRef, useCallback } from "react";
import MIcon from "@/components/standard/MSIcon/MSIcon";
import { MSCheckbox, MSInput } from "@/components/standard/common";
import { MMenu } from "@/components/miscellaneous";
import MSButton from "@/components/standard/MSButton/MSButton";
import useModal from "./useModal";
import MSColumns from "@/components/standard/MSTable/MSColumns.jsx";
import MSTable from "../../components/standard/MSTable/MSTable";
import MSTypography from "@compts:std/MSTypography/MSTypography.jsx";
import MCurrentBreadcrumbs from "@compts:misc/MLocationBreadcrumbs.jsx";
import ValidationResult from "@compts:misc/validationResult.jsx";
import { useStyleSettings } from "@/styleViews/layouts/common/styleSettings.jsx";
import MLoader from "@compts:std/common/MLoader.jsx";
import { useLoaderData } from "react-router-dom";

function LocalCaList({ entity, columns, controller }) {
	const trans = useTranslation();
	const [modal, showModal, closeModal, loadingModal] = useModal();
	const [secondModal, showSecondModal] = useModal();
	const title = entity?.title(trans);
	const [boxed, setBoxed] = useState(true);
	const { snackbarRef } = useStyleSettings();
	const getSliceData = useCallback(
		(offset, limit) => {
			tableRef.current?.updateLoading(true);
			return controller
				?.getSlice({
					offset: offset,
					limit: limit,
					strategy: "uiList",
				})
				.then((data) => {
					tableRef.current?.updateLoading(false);
					if (data && data.length > 0) {
						return data;
					}
					return null;
				});
		},
		[controller]
	);

	const showEditor = (aInstace, action, loader) => {
		const executeAction = async (event, dontClose = true, ignoreWarnings = false) => {
			dontClose && event.preventDefault();
			loadingModal(true);
			const result = await aInstace[action](ignoreWarnings, true);
			loadingModal(false);
			if (!result) {
				showSecondModal({
					title: <MSTypography className="p-2">Failure Progress</MSTypography>,
					content: <></>,
					moreOne: true,
					className: `modal-error`,
				});
				return false;
			} else if (result.validationCheck && !ignoreWarnings) {
				showSecondModal({
					title: (
						<MSTypography>
							<MSButton
								className="!pointer-events-none icon"
								variant="flat"
								type="circle"
								icon={<MIcon name={result.errors ? "Close" : "ProgressAlert"} size={2} />}
							/>
						</MSTypography>
					),
					onOk: (event) => {
						!result.errors && executeAction(event, false, !!result.warnings);
					},
					content: <ValidationResult show={true} warnings={result.warnings} errors={result.errors} />,
					moreOne: true,
					className: `modal-error ${result.errors ? "errors" : "warning"}`,
					warning: !!result.warnings && !result.errors,
				});
				return false;
			} else {
				const rows = [...dataSourceFiltered];
				if (action === "add") {
					aInstace.key = aInstace.ID;
					rows.push(aInstace);
				}
				setDataSourceFiltered(rows);
				closeModal();
				snackbarRef.current.open(trans.t(action === "add" ? "add.done" : "update.done"), "success");
			}
		};

		showModal({
			title: (
				<MSTypography>
					<div>{`${trans.t(`ACDU.${action}`)} ${title}`}</div>
				</MSTypography>
			),
			onOk: executeAction,
			loader: loader,
			content: <entity.editor instance={aInstace} />,
			ok: `buttons.${action}`,
		});
	};

	const doAddNew = async () => {
		const instance = new controller();
		const loader = async () => await instance.new();
		showEditor(instance, "add", loader);
	};
	const doUpdateRecord = async (aRecord) => {
		const loader = async () => await aRecord.verifyLoaded();
		showEditor(aRecord, "update", loader);
	};
	function doDeleteRecord(aRecord) {
		showModal({
			title: "delete message",
			onOk: validationDelete,
			content: <MSTypography> {`${trans.t("delete.message")}  " ${aRecord.Caption} "`} </MSTypography>,
			className: "modal-delete",
		});
		async function validationDelete() {
			const result = await aRecord.validate("Delete");
			if (!result) return confirmDelete(aRecord);
			else {
				showSecondModal({
					title: (
						<MSTypography>
							<MSButton
								className="!pointer-events-none icon"
								variant="flat"
								type="circle"
								icon={<MIcon name={result.errors ? "Close" : "ProgressAlert"} size={2} />}
							/>
						</MSTypography>
					),
					content: <ValidationResult show={true} errors={result.errors} />,
					className: "modal-error errors",
				});
			}
		}
	}
	const confirmDelete = (aRecord) => {
		showSecondModal({
			title: "delete message",
			onOk: deleteRecord,
			content: <MSTypography> {`${trans.t("delete.confirm")}  " ${aRecord.Caption} "`}</MSTypography>,
			className: "modal-delete",
		});
		function deleteRecord() {
			const index = dataSourceFiltered.indexOf(aRecord);
			aRecord.delete().then((result) => {
				if (result !== false) {
					const rows = [...dataSourceFiltered];
					rows.splice(index, 1);
					setDataSourceFiltered(rows);
					snackbarRef.current.open(trans.t("delete.done"), "success");
				}
			});
		}
	};
	const [visibleColumns, setVisibleColumns] = useState([]);
	const [searchFields, setSearchFields] = useState([]);
	const [allColumns, setAllColumns] = useState([]);
	useEffect(() => {
		function fetchData() {
			const aData = [];
			columns?.forEach((col) => {
				const column = MSColumns(col.name, col.config, col.path, trans);
				aData.push(column);
			});
			setAllColumns(aData);
			setVisibleColumns(aData);
			setSearchFields(aData);
		}
		fetchData();
	}, [columns, trans]);
	useEffect(() => {
		async function getData() {
			const result = await getSliceData(0, 10);
			setDataSourceFiltered(result ? [...result] : []);
		}
		getData();
	}, [getSliceData]);
	const updateRef = useRef(null);
	const operationColumn = {
		title: trans.t("table.operations.operations"),
		type: "operations",
		editable: true,
		fixed: "left",
		ref: updateRef.current,
		dataIndex: "operation",
		align: "center",
		width: 100,
		render: (_, record) => (
			<div className="flex items-center h-full gap-2">
				<MSButton
					ref={updateRef}
					icon={<MIcon name="Pencil" className="text-warning" />}
					onClick={() => doUpdateRecord(record)}
					type="circle"
					variant="text"
				/>
				<MSButton
					icon={<MIcon name="Delete" className="text-error" />}
					onClick={() => doDeleteRecord(record)}
					type="circle"
					variant="text"
				/>
			</div>
		),
	};

	function handleVisibleColumn(event, column) {
		tableRef.current.updateColumn({ ...column, hidden: !event.target.checked });
	}

	function handelSearchFields(key) {
		const temp = [...searchFields];
		temp.forEach((ele) => {
			if (ele.key === key) ele.includeInSearch = !ele.includeInSearch;
		});
		setSearchFields([...temp]);
	}

	function getMenuSearch() {
		return (
			<MMenu
				triggerSubMenuAction="click"
				mode="horizontal"
				theme={{ theme: "light" }}
				className="border-none"
				items={[
					{
						key: "search",
						popupClassName: "table-filter",
						className: "p-0",
						label: <MIcon name="ChevronDown" fontSize={"22px"} />,
						children: searchFields?.map((ele, i) => ({
							key: i,
							label: (
								<div
									onClick={(e) => {
										e?.stopPropagation();
									}}
								>
									<MSCheckbox
										key={i}
										onClick={() => {
											handelSearchFields(ele.key);
										}}
										className="text-theme2"
										checked={searchFields.find((el) => el.key === ele.key)?.includeInSearch}
									>
										{ele.title}
									</MSCheckbox>
								</div>
							),
						})),
					},
				]}
			></MMenu>
		);
	}
	const [dataSourceFiltered, setDataSourceFiltered] = useState([]);
	const tableRef = useRef(null);
	const superHeader = (
		<div className="px-2 h-10 flex items-center justify-between">
			<div className="gap-4 flex justify-center items-center">
				<MSTypography className="pe-2 border-e-[1px] border-divider text-sm text-nowrap">{title}</MSTypography>
				<MSInput
					className="search-table"
					innerPrefix={<MIcon name="Magnify" className="text-disabled" />}
					placeholder={trans.t("fields.search")}
					size="small"
					suffix={getMenuSearch()}
				/>
			</div>
			<div className="flex justify-between items-center gap-6">
				<MMenu
					expandIcon={<></>}
					mode="horizontal"
					popupClassName="table-filter"
					data={[
						{
							key: "filter",
							type: "container",
							label: (
								<div className="p-2 hover:bg-hover rounded-full">
									<MIcon name="Filter" />
								</div>
							),
							children: allColumns?.map((ele, i) => ({
								key: i,
								type: "container",
								label: (
									<div
										onClick={(e) => {
											e?.stopPropagation();
										}}
									>
										<MSCheckbox
											key={i}
											onChange={(event) => {
												handleVisibleColumn(event, ele);
											}}
											checked={visibleColumns.find((el) => el.key === ele.key)?.hidden ? false : true}
											label={ele.title}
										/>
									</div>
								),
							})),
						},
					]}
				></MMenu>
				{modal()}
				{secondModal()}
				<MSButton icon={<MIcon name="Plus" className="text-white" />} onClick={() => doAddNew()} type="circle" />
			</div>
		</div>
	);

	return (
		<div className={`flex flex-col h-full  gap-6  ${boxed ? "px-32" : "px-4"}`}>
			<div className="flex justify-between items-center">
				<MCurrentBreadcrumbs />
				<div className={`flex  ${trans.isLTR ? "" : "flex-row-reverse"} rounded-full h-12`}>
					<MSButton
						style={{
							borderRadius: "999px 0 0 999px",
							backgroundColor: boxed ? "var(--background-light)" : "var(--background-dark)",
							color: "var(--text-secondary)",
							height: "100%",
						}}
						onClick={() => setBoxed(true)}
						label={trans.t("buttons.boxed")}
					></MSButton>
					<MSButton
						style={{
							borderRadius: " 0 999px 999px 0 ",
							backgroundColor: boxed ? "var(--background-dark)" : "var(--background-light)",
							color: "var(--text-secondary)",
							height: "100%",
						}}
						onClick={() => setBoxed(false)}
						label={trans.t("buttons.fullWidth")}
					/>
				</div>
			</div>

			<div style={{ height: "calc(100vh - 250px)" }}>
				<MSTable
					ref={tableRef}
					superHeader={superHeader}
					rows={dataSourceFiltered}
					columns={visibleColumns?.length > 0 ? [...visibleColumns, operationColumn] : []}
					getRowId={(record) => record.ID.value}
					scroll={false}
					dataLoader={getSliceData}
				/>
			</div>
		</div>
	);
}
export const CaList = () => {
	const initializer = useLoaderData();
	if (typeof initializer !== "function") {
		throw new Error("CaList need initializer!...");
	}
	const loader = async () => {
		const res = await initializer();
		const result = { entity: res, controller: res.Class, columns: [] };
		result.controller.forEachStrategyField("uiList", (name, config, path) => {
			result.columns.push({ name, config, path });
		});
		return result;
	};

	return <MLoader component={LocalCaList} loader={loader} />;
};

export default CaList;
