import MIcon from "@compts:std/MSIcon/MSIcon.jsx";
import ChangeTheme from "./ChangeTheme";
import MSSwitch from "@compts:std/MSSwitch/MSSwitch";
import MRadioGroup from "@compts:std/common/MSRadioGroup";
import useStyle from "../../../context/useStyle";
import MDrawer from "@/components/miscellaneous/MDrawer/MDrawer";
import MDivider from "@/components/miscellaneous/MDivider";
import { useTranslation } from "@i18n";
import React from "react";
import MSChip from "@compts:std/MSChip/MSChip.jsx";
import MPaper from "@compts:std/MSPaper/MSPaper.jsx";
import MSIcon from "@compts:std/MSIcon/MSIcon.jsx";
import MTypography from "@compts:std/MSTypography/MSTypography.jsx";
import MSButton from "@compts:std/MSButton/MSButton.jsx";

export default function SideBarSettings({ open, onClose }) {
	const style = useStyle();
	const { t } = useTranslation();

	const colors = [
		{
			label: "indigo",
			background: style.dark ? "#2D355C" : "#e2e2f4",
			color: style.dark ? "#6366f1" : "#4f46e5",
		},
		{
			label: "teal",
			background: style.dark ? "#1D464D" : "#dbeaeb",
			color: style.dark ? "#0D9488" : "#0d9488",
		},
		{
			label: "yellow",
			background: style.dark ? "#817361" : "#ffeace",
			color: "#e09226",
		},
		{
			label: "orange",
			background: style.dark ? "#4B3830" : "#F2E4DE",
			color: style.dark ? "#EA580C" : "#EA580C",
		},
	];
	const roundedItems = [
		{
			class: "rounded-tl-none",
			value: "0",
		},
		{
			class: "rounded-tl-md",
			value: "1",
		},
		{
			class: "rounded-tl-lg",
			value: "2",
		},
		{
			class: "rounded-tl-xl",
			value: "3",
		},
		{
			class: "rounded-tl-2xl",
			value: "4",
		},
		{
			class: "rounded-tl-3xl",
			value: "5",
		},
	];
	function handleDark(value) {
		style.dark = value;
	}

	const isSelectedColor = (color) => style.mainColor.toLowerCase() === color.label.toLowerCase();
	return (
		open && (
			<MDrawer open={open} reverse={true} className="z-[1300]" variant="permanent" drawerwidth={300}>
				<div className="flex flex-col sideBar-setting gap-4 ">
					<div className="justify-between  flex items-center p-2">
						<MSButton icon={<MIcon name="Close" />} type="circle" variant="text" onClick={() => onClose(false)} />
						<div className="flex items-center">
							<MTypography className="font-bold text-lg me-4">{t("style.settings")}</MTypography>
							<MIcon name="Cog" />
						</div>
					</div>
					<div className="overflow-y-auto h-[calc(100vh-64px)] flex flex-col gap-2">
						<MSSwitch
							check={t("style.dark")}
							className="dark-toggle"
							unCheck={t("style.light")}
							value={style?.dark}
							size="large"
							onChange={() => handleDark(!style.dark)}
						/>
						<MDivider textAlign={"left"}> {t("style.colors")}</MDivider>
						{colors.map((item, i) => (
							<MSChip
								label={<div style={{ color: isSelectedColor(item) ? "white" : item.color }}>{item.label}</div>}
								key={i}
								style={{
									background: isSelectedColor(item) ? item.color : item.background,
									color: isSelectedColor(item) ? "white" : item.color,
								}}
								className={`color-chip cursor-pointer hover:bg-[color:${item.color}] hover:!text-white ${
									isSelectedColor(item)
										? `bg-[color:${item.color}] text-white`
										: `bg-[color:${item.background}] text-[${item.color}]`
								}`}
								icon={
									<MSIcon
										name={isSelectedColor(item) ? "Check" : "Circle"}
										size={1.5}
										style={{ color: isSelectedColor(item) ? "white" : item.color }}
										// className={`${isSelectedColor(item) ? "text-white" : `text-[color: ${item.color}]`}`}
									/>
								}
								onClick={() => (style.mainColor = item.label)}
							/>
						))}
						<MDivider textAlign={"left"}> {t("style.mode")}</MDivider>
						<div className="px-2 flex items-center flex-col gap-2">
							<ChangeTheme />
						</div>
						<MDivider textAlign={"left"}> {t("style.roundedCorners")} </MDivider>
						<div className="px-2 flex items-center flex-col gap-2">
							<div className="grid grid-cols-3 gap-3 w-fit">
								{roundedItems.map((ele, i) => {
									return (
										<MPaper
											key={i}
											className={`elevation-0 rounded-paper ${ele.class} ${
												+style.rounded === +ele.value ? "selected" : ""
											}`}
											onClick={() => (style.rounded = ele.value)}
											style={{ borderRadius: `${+ele.value / 4}rem 0 0 0` }}
										>
											<MPaper
												className={`elevation-0 inner-paper ${ele.class} ${
													+style.rounded === +ele.value ? "selected" : ""
												}`}
												style={{ borderRadius: `${+ele.value / 4}rem 0 0 0` }}
											></MPaper>
										</MPaper>
									);
								})}
							</div>
						</div>

						<MDivider textAlign={"left"}>{t("style.toolBar")}</MDivider>
						<div className="px-2 flex items-center flex-col gap-2">
							<MRadioGroup
								className="border-none"
								value={typeof style.headerFixed === "string" ? style.headerFixed === "true" : style.headerFixed}
								onChange={(event) => (style.headerFixed = event.target.value)}
								items={[
									{ label: t("style.fixed"), value: true },
									{ label: t("style.static"), value: false },
								]}
							/>
						</div>
					</div>
				</div>
			</MDrawer>
		)
	);
}
