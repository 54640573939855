import { MContext } from "../../shared/core/MContext.js";
import { API_CONTEXT_MAIN } from "../../shared/core/MConsts.js";
import { FIELD_NAME_API_HANDLER } from "../../../constants/index.js";

const MENU_ITEM_KIND_LINK = "Link";
const MENU_ITEM_KIND_GROUP = "group";
const MENU_ITEM_KIND_TYPED = "typed";

export class UIContext extends MContext {}

export default class UIMainContext extends MContext {
	//#region Constructor
	#application;
	constructor(aApplication) {
		super();
		this.#application = aApplication;
	}
	async prepare() {
		await this.#buildMenu();
	}
	//#endregion Constructor
	//#region menu
	#menu;
	#menuListeners = [];
	get menu() {
		return this.#menu;
	}
	onMenuChanged(aListener) {
		this.#menuListeners.push(aListener);
		return () => this.#menuListeners.filter((l) => l !== aListener);
	}
	async #buildMenu() {
		const loc_NewItem = (id, source, route, kind, path, title, titleIsValue) => {
			const result = { key: id, route, kind, path, origin: source };
			switch (typeof title) {
				case "string":
					if (titleIsValue) result.title = (aTrans) => title;
					else result.title = (aTrans) => aTrans.t(title);
					break;
				case "function":
					result.title = title;
					break;
				case "object":
					result.title = (aTrans) => {
						const {
							language: { name: lang },
						} = aTrans;
						return title[lang] || title["AR"];
					};
					break;
				default:
					throw new Error(`menuItem title, expected types (string, function, object) but get ${title}!.`);
			}
			const { icon } = source;
			if (icon) result.icon = icon;
			result.hasEditor = source.editor || source.uiEditor ? true : false;
			return result;
		};
		const loc_SourceLockup = (aApp, aItem) => {
			let name;
			name = aItem.item;
			if (name) {
				name = aApp.appPrefix + name;
				const source = aApp.contains[name];
				if (source.types && !aItem.value) {
					return { kind: MENU_ITEM_KIND_TYPED, source };
				}
				return { kind: MENU_ITEM_KIND_LINK, source };
			}
			name = aItem.group;
			if (name) {
				return { kind: MENU_ITEM_KIND_GROUP, source: aApp.groups[name] };
			}
			return undefined;
		};
		const loc_CreateItem = (aApp, aItem, aRoute) => {
			const { kind, source } = loc_SourceLockup(aApp, aItem);
			if (!source) {
				console.error(`${aItem.name || aItem.item || aItem.group || aItem.value} is not registered!.`);
				return;
			}
			const value = aItem.value;
			const path = value?.toString() || source.path || source.subApi || source.name;
			const route = `${aRoute}/${path}`;
			const result = loc_NewItem(aItem.id, source, route, kind, path, aItem.title || source.title, !!aItem.title);
			if (aItem.childs) {
				const childs = [];
				let keepLink = false;
				for (let sub of aItem.childs) {
					const child = loc_CreateItem(aApp, sub, route);
					if (child) {
						keepLink = keepLink || child.hasEditor || child.keepLink;
						childs.push(child);
					}
				}
				if (childs.length > 0) {
					result.keepLink = keepLink;
					result.children = childs;
				}
			}
			return result;
		};
		const apiData = (await this.#application[FIELD_NAME_API_HANDLER].get(API_CONTEXT_MAIN + "menu")).data;
		const menu = { items: [], groups: [] };
		const apps = this.#application.apps;
		const groups = {};
		apiData.forEach((appRoot) => {
			const source = apps[appRoot.name];
			if (!source) return;
			const title = source.appTitle || `${source.appName}.title`;
			const route = source.appName;
			const appItem = loc_NewItem(appRoot.id, source, route, MENU_ITEM_KIND_LINK, source.appName, title, false);
			if (source.appIcon) appItem.icon = source.appIcon;
			appItem.children = [];
			let keepLink = false;
			appRoot.childs?.forEach((group) => {
				const item = loc_CreateItem(source, group, route);
				if (!item) return;
				keepLink = keepLink || item.hasEditor || item.keepLink;
				appItem.children.push(item);
				if (item.hasEditor || item.keepLink) {
					const nameIn = item.path;
					let dest = groups[nameIn];
					if (!dest) {
						dest = {
							key: nameIn,
							icon: item.icon,
							title: item.title,
							keepLink: true,
							children: [],
						};
						menu.groups.push(dest);
						groups[nameIn] = dest;
					}
					const app_group = {
						key: `g.${appItem.key}.${nameIn}`,
						icon: appItem.icon,
						title: appItem.title,
						keepLink: true,
						children: item.children,
						childsIn: item.key,
					};
					dest.children.push(app_group);
				}
			});
			if (appItem.children.length > 0) {
				appItem.keepLink = keepLink;
				menu.items.push(appItem);
			}
		});
		this.#menu = menu;
		this.#emitMenuChanged();
	}
	#emitMenuChanged() {
		this.#menuListeners.forEach((listener) => listener());
	}
	//#endregion menu
}
