import { isClass } from "@sys:utils";
import {
	APPLICATION_TYPE_SERVER,
	APPLICATION_TYPE_CLIENT,
	CLASS_SYMBOL_APP,
	CLASS_SYMBOL_SYSTEMCLASS,
	APPLICATION_SYMBOL_BASE_CLASSES,
	BUILDER_ENGINE_SYMBOL_FINALIZE,
	BUILDER_ENGINE_SYMBOL_ADDCLASS,
	BUILDER_ENGINE_SYMBOL_APPCREATED,
	APPLICATION_SYMBOL_START,
} from "./MConsts.js";
import { ENTITY_PROP_CLASS } from "../../../constants/index.js";
import MBuilderEngine from "./builders/MBuilderEngine.js";
const CLASS_SYMBOL_IDENTIFIER = Symbol();
class ControllersUtils {
	//#region Constructor
	#application;
	constructor(aApplication) {
		this.#application = aApplication;
	}
	//#endregion Constructor
	//#region Controllers
	waitController(aName, aThen, aCatch, aFinally) {
		if (typeof aThen !== "function") {
			throw new Error(`waitController then{secondParam} param, expected type function but get '${typeof aThen}'!..`);
		}
		const controller = this.getController(aName);
		if (controller instanceof Promise) {
			controller.then = aThen;
			if (aCatch) controller.catch = aCatch;
			if (aFinally) controller.finally = aFinally;
		} else {
			aThen(controller);
			if (aFinally) aFinally();
		}
	}
	getController(aName) {
		let result = this.#application.entities[aName];
		if (!result) throw new Error(`Controller ${aName} was not registered!.`);
		if (!isClass(result)) {
			//frontend
			result = result[ENTITY_PROP_CLASS];
			if (result instanceof Promise) {
				return new Promise(async (resolve, reject) => {
					try {
						const controller = await result;
						resolve(controller);
					} catch (e) {
						reject(e);
					}
				});
			}
		}
		return result;
	}
	getControllers(aNames) {
		if (!Array.isArray(aNames)) {
			throw new Error(`getControllers param, expected value is array of string but get ${aNames}!.`);
		}
		return aNames.map((name) => this.getController(name));
	}
	//#endregion Controllers
	//#region InstanceOf
	waitInstanceOf(aController, aThen, aCatch, aFinally, ...args) {
		if (typeof aThen !== "function") {
			throw new Error(`waitInstanceOf then{secondParam} param, expected type function but get '${typeof aThen}'!..`);
		}
		const instance = this.newInstanceOf(aController, ...args);
		if (instance instanceof Promise) {
			instance.then = aThen;
			if (aCatch) instance.catch = aCatch;
			if (aFinally) instance.finally = aFinally;
		} else {
			aThen(instance);
			if (aFinally) aFinally();
		}
	}
	newInstanceOf(aController, ...args) {
		const controller = this.getController(aController);
		if (controller instanceof Promise) {
			const result = new Promise((resolve, reject) =>
				controller.then((cls) => resolve(cls.create_cls(...args)).catch((e) => reject(e)))
			);
			return result;
		}
		return controller.create_cls(...args);
	}
	//#endregion InstanceOf
	//#region ControllerLoading
	waitLoadByPK(aController, aPrimaryKey, aThen, aCatch, aFinally, aStrategy, ...args) {
		return new Promise(async (resolve, reject) => {
			try {
				const controller = await this.getController(aController);
				const result = await controller.loadByPK(aPrimaryKey, aStrategy, ...args);
				if (aThen) aThen(result);
				resolve(result);
			} catch (e) {
				if (aCatch) aCatch(e);
				else reject(e);
			} finally {
				if (aFinally) aFinally();
			}
		});
	}
	async loadByPK(aController, aPrimaryKey, aStrategy, ...args) {
		const controller = await this.getController(aController);
		return await controller?.loadByPK(aPrimaryKey, aStrategy, ...args);
	}
	waitLoadByFilter(aController, aStrategy, aFilter, aThen, aCatch, aFinally, ...args) {
		return new Promise(async (resolve, reject) => {
			try {
				const controller = await this.getController(aController);
				const result = await controller.loadByFilter(aStrategy, aFilter, ...args);
				if (aThen) aThen(result);
				resolve(result);
			} catch (e) {
				if (aCatch) aCatch(e);
				else reject(e);
			} finally {
				if (aFinally) aFinally();
			}
		});
	}
	async loadByFilter(aController, aStrategy, aFilter, ...args) {
		const controller = await this.getController(aController);
		return await controller?.loadByFilter(aStrategy, aFilter, ...args);
	}
	//#endregion ControllerLoading
}

export default (aAppicationType, Base) =>
	class MApplication extends Base {
		//#region Constructor
		constructor() {
			super(...arguments);
			this.utils = new ControllersUtils(this);
			this.#initEntitiesProxy();
			const start = new.target;
			const stop = Object.getPrototypeOf(Object);
			let builderClass = MBuilderEngine;
			let cls = start;
			do {
				if (Object.hasOwn(cls, CLASS_SYMBOL_SYSTEMCLASS)) {
					const extenders = cls[CLASS_SYMBOL_SYSTEMCLASS].builder;
					if (extenders) {
						const extend = (extender) => {
							if (typeof extender !== "function") return;
							builderClass = extender.call(start, builderClass, this) || builderClass;
						};
						if (!Array.isArray(extenders)) extend(extenders);
						else extenders.forEach(extend);
					}
				}
				cls = Object.getPrototypeOf(cls);
			} while (cls !== stop);
			this.#builderClass = builderClass;
			return this.#build(...arguments);
		}
		//#endregion Constructor
		//#region Apps
		#apps = {};
		get apps() {
			return this.#apps;
		}
		async #createApp(aModule, aBuilder) {
			let Class;
			if (aModule instanceof Promise) {
				Class = (await aModule).default;
			} else if (typeof aModule === "string") {
				Class = (await import(/* webpackIgnore: true */ `${aModule}`)).default;
			} else if (isClass(aModule)) {
				Class = aModule;
			} else {
				throw new Error(`Unkown type of module ${aModule}!.`);
			}
			const app = Class.create_cls(this);
			const name = app.appName;
			if (!name) {
				throw new Error(`appName is required for class: ${Class}!..`);
			}
			if (this.#apps[name]) {
				throw new Error(`app[${name}] is already registered!..`);
			}
			this.#apps[name] = app;
			const appCreated = aBuilder[BUILDER_ENGINE_SYMBOL_APPCREATED];
			if (typeof appCreated === "function") {
				appCreated.call(aBuilder, app);
			}
			return app;
		}
		async #createApps(aModules, aBuilder) {
			const appsCreatros = Array.isArray(aModules)
				? aModules.map((module) => this.#createApp(module, aBuilder))
				: [this.#createApp(aModules, aBuilder)];
			return Promise.all(appsCreatros);
		}
		//#endregion Apps
		//#region Entities
		#entities = {};
		#entitiesProxy;
		#initEntitiesProxy() {
			const get = (aTarget, aProperty, aReceiver) => {
				const entity = this.#entities[aProperty];
				if (!entity) {
					throw new Error(`entity ${aProperty} was not registered!..`);
				}
				return entity;
			};
			this.#entitiesProxy = new Proxy({}, { get });
		}
		get entities() {
			return this.#entitiesProxy;
		}
		#createClassesProxy(aTarget, aBuilder) {
			const get = (aTarget, aProperty, aReceiver) => {
				const entity = this[APPLICATION_SYMBOL_BASE_CLASSES][aProperty] || this.#entities[aProperty];
				if (!entity) {
					throw new Error(`entity ${aProperty} was not registered!..`);
				}
				if (isClass(entity)) return entity;
				let cls;
				if (aAppicationType === APPLICATION_TYPE_CLIENT) {
					const prop = Object.getOwnPropertyDescriptor(entity, ENTITY_PROP_CLASS);
					cls = prop.value || prop.get(aBuilder);
				} else cls = entity;
				if (cls instanceof Promise) {
					if (aTarget.promises === undefined) aTarget.promises = [];
					aTarget.promises.push(cls);
					return class {};
				}
				return cls;
			};
			return new Proxy(aTarget, { get });
		}
		async #classGetter(aApp, aIdentifier, aDeclaration, aBuilder) {
			let Class = aDeclaration.class;
			const description = `${aApp.appName}.${aIdentifier}`;
			if (Class === undefined) {
				throw new Error(`class was not defined for ${description}!..`);
			}
			if (typeof Class !== "function") {
				throw new Error(`class must be function ${description}`);
			}
			const builder = aBuilder || new this.#builderClass(this);
			if (!isClass(Class)) {
				const proxyObject = {};
				const classesProxy = this.#createClassesProxy(proxyObject, builder);
				do {
					const result = await Class(classesProxy, this);
					if (proxyObject.promises) {
						await Promise.all(proxyObject.promises);
						delete proxyObject.promises;
						continue;
					}
					switch (typeof result) {
						case "function":
							Class = result;
							break;
						case "object":
							Class = result.default;
							if (typeof Class === "function") break;
						default:
							const msg = `class getter for [${description}], expected result [function or module] but get ${result}!..`;
							throw new Error(msg);
					}
				} while (!isClass(Class));
			}
			Object.defineProperty(Class, CLASS_SYMBOL_APP, { value: aApp });
			Object.defineProperty(Class, CLASS_SYMBOL_IDENTIFIER, { value: aIdentifier });
			builder[BUILDER_ENGINE_SYMBOL_ADDCLASS](Class, aApp, aIdentifier, aDeclaration);
			if (builder !== aBuilder) {
				await builder[BUILDER_ENGINE_SYMBOL_FINALIZE]();
			}
			return Class;
		}
		#registerEntity(aApp, aName, aDeclaration) {
			if (this.#entities[aName]) {
				throw new Error(`Entity ${aApp.appName}.${aName}, was already registered!..`);
			}
			if (!aDeclaration.class) {
				throw new Error(`Entity ${aApp.appName}.${aName}, need class property!..`);
			}
			if (!aDeclaration.api) {
				const subApi = aDeclaration.subApi;
				if (!subApi) {
					throw new Error(`Entity ${aApp.appName}.${aName}, don't have subApi!..`);
				}
				aDeclaration.api = `${aApp.apiRoot}/${subApi}`;
			}
			let classLoader;
			const ClassDescriptor = {
				enumerable: true,
				configurable: true,
				get: (aBuilder) => {
					if (!classLoader) {
						classLoader = this.#classGetter(aApp, aName, aDeclaration, aBuilder);
						classLoader.then((value) => {
							const descriptor = { enumerable: true, value };
							Object.defineProperty(aDeclaration, ENTITY_PROP_CLASS, descriptor);
							if (aAppicationType === APPLICATION_TYPE_SERVER) {
								Object.defineProperty(this.#entities, aName, descriptor);
							}
							classLoader = undefined;
							return value;
						});
					}
					return classLoader;
				},
			};
			Object.defineProperty(aDeclaration, ENTITY_PROP_CLASS, ClassDescriptor);
			Object.defineProperty(aDeclaration, "controller", {
				get: () => {
					console.warn("method entity.controller is deprecated, please use entity.Class!..");
					return aDeclaration[ENTITY_PROP_CLASS];
				},
			});
			Object.defineProperty(
				this.#entities,
				aName,
				aAppicationType === APPLICATION_TYPE_SERVER ? ClassDescriptor : { enumerable: true, value: aDeclaration }
			);
		}
		//#endregion Entities
		//#region Building
		#builderClass;
		async #build(aApps, ...args) {
			const builder = new this.#builderClass(this, ...args);
			const apps = await this.#createApps(aApps, builder);
			const entities = [];
			apps.forEach((app) => {
				const contains = app.contains;
				if (contains) {
					for (let [name, declaration] of Object.entries(contains)) {
						this.#registerEntity(app, name, declaration);
						entities.push(declaration);
					}
				}
			});
			await builder[BUILDER_ENGINE_SYMBOL_FINALIZE](entities);
			const start = this[APPLICATION_SYMBOL_START];
			if (typeof start === "function") {
				return await start.call(this, ...args);
			}
			//console.debug(this.#entities);
			return this;
		}
		//#endregion Building
	};
