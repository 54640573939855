import MSnackbar from "@compts:misc/MSnackbar.jsx";
import React, { useRef, useState } from "react";

export const StyleContext = React.createContext(null);
export const useStyleSettings = () => React.useContext(StyleContext);

export function StyleSettingsProvider(props) {
	const [drawerWidth, setDrawerWidth] = useState(270);
	const [openDrawer, setOpenDrawer] = useState(true);
	const [modalMood, setModalMood] = useState(false);
	const snackbarRef = useRef(null);
	const setState = {
		drawerWidth: (value) => setDrawerWidth(value),
		openDrawer: (value) => setOpenDrawer(value),
		modalMood: (value) => setModalMood(value),
	};

	return (
		<StyleContext.Provider value={{ drawerWidth, openDrawer, modalMood, setState, snackbarRef }}>
			{props.children}
			<MSnackbar ref={snackbarRef} />
		</StyleContext.Provider>
	);
}
export default StyleSettingsProvider;

// const STYLE_DEFAULTS = {
// 	drawerWidth: 270,
// 	openDrawer: true,
// 	showModal: false,
// };

// function createStyle(aSource, aDefault, aChangedCallback) {
// 	const result = {};
// 	let onChanged = aChangedCallback;
// 	Object.defineProperty(result, "onChanged", {
// 		get: () => onChanged,
// 		set: (aValue) => (onChanged = aValue),
// 	});
// 	const loc_mapObject = (aSource, aDefault, aInitial, aDest, aPrefix) => {
// 		for (let [name, initialValue] of Object.entries(aInitial)) {
// 			const path = aPrefix + name;
// 			if (typeof initialValue === "object") {
// 				const dest = {};
// 				Object.defineProperty(aDest, name, { value: dest, enumerable: true });
// 				loc_mapObject(aSource?.[name], aDefault?.[name], initialValue, dest, path + ".");
// 				continue;
// 			}
// 			let value = aSource?.[name];
// 			if (value === undefined || value === null) {
// 				value = localStorage.getItem(path);
// 				if (value === "false") value = false;
// 				else if (value === "true") value = true;
// 			}
// 			value = value ?? aDefault?.[name] ?? initialValue;
// 			Object.defineProperty(aDest, name, {
// 				enumerable: true,
// 				get: () => value,
// 				set: (aValue) => {
// 					if (value !== aValue) {
// 						value = aValue;
// 						localStorage.setItem(path, aValue);
// 						if (typeof result.onChanged === "function") {
// 							result.onChanged(result);
// 						}
// 					}
// 				},
// 			});
// 		}
// 	};
// 	loc_mapObject(aSource, aDefault, STYLE_DEFAULTS, result, "MStyle.");
// 	return result;
// }
