import { uniqueID } from "../utils/miscellaneous.js";
const COUNT_SYMBOL = Symbol("count");
const MESSAGE_PROMISE_PATH = "path is promise";
export const MRtv_UNTREATED = Symbol();
export const MRtv_EVENTS_TYPES_VALUE = "#value";
export const MRtv_ROOT_OBSERVER = Symbol.for("MRtv.Root");
export const MRtv_OBJECT_CONFIGS = Symbol.for("MRtv.Configs");
export const MRtv_ACTUAL_GET = Symbol("MRtv.actualGet");
export const MRtv_ACTUAL_SET = Symbol("MRtv.actualSet");
export const MRtv_VALUEOF_GETTER = Symbol.for("MRtv.getValueOf");
export const MRtv_VALUEOF_SETTER = Symbol.for("MRtv.setValueOf");
export const MRtv_GET_PROXY_TARGET = Symbol("ProxyTarget");
export const MRtv_MANAGMENT_OBJECT = Symbol.for("MManagementObject");

export const actualGetter = (aInstance, aName) => {
	const result = aInstance?.[aName];
	if (result && typeof result === "object" && MRtv_ACTUAL_GET in result) {
		return result[MRtv_ACTUAL_GET];
	}
	return result;
};

export class MRtvObjectHandler {
	// static getPrototypeOf(...args) {
	// 	return Reflect.getPrototypeOf(...args);
	// }
	// static setPrototypeOf(...args) {
	// 	return Reflect.getPrototypeOf(...args);
	// }
	// static isExtensible(...args) {
	// 	return Reflect.isExtensible(...args);
	// }
	// static preventExtensions(...args) {
	// 	return Reflect.preventExtensions(...args);
	// }
	// static getOwnPropertyDescriptor(...args) {
	// 	return Reflect.getOwnPropertyDescriptor(...args);
	// }
	// static has(...args) {
	// 	return Reflect.has(...args);
	// }
	// static ownKeys(...args) {
	// 	return Reflect.ownKeys(...args);
	// }
	// static apply(...args) {
	// 	return Reflect.apply(...args);
	// }
	// static construct(...args) {
	// 	return Reflect.construct(...args);
	// }
	static propertyGetter(aTarget, aProperty, aReceiver) {
		const root = aTarget[MRtv_ROOT_OBSERVER];
		if (!root) return MRtv_UNTREATED;
		return MRtvUtils.getValueOf(root.config, aTarget, aProperty);
	}
	static propertySetter(aTarget, aProperty, aValue, aReceiver) {
		const root = aTarget[MRtv_ROOT_OBSERVER];
		if (!root) return MRtv_UNTREATED;
		return MRtvUtils.setValueOf(root.config, aTarget, aProperty, aValue);
	}
	static get(aTarget, aProperty, aReceiver) {
		const res = this.propertyGetter(aTarget, aProperty, aReceiver);
		if (res !== MRtv_UNTREATED) return res;
		if (aProperty === MRtv_GET_PROXY_TARGET) return aTarget;
		else return aTarget[aProperty];
	}
	static set(aTarget, aProperty, aValue, aReceiver) {
		const res = this.propertySetter(aTarget, aProperty, aValue, aReceiver);
		if (res !== MRtv_UNTREATED) return res;
		else {
			aTarget[aProperty] = aValue;
			return true;
		}
	}
	static defineProperty(aTarget, aProperty, aDescriptor) {
		const result = Reflect.defineProperty(...arguments);
		if (result) {
			const root = aTarget[MRtv_ROOT_OBSERVER];
			if (root) {
				const keyConfig = MRtvUtils.configOf(root.config, aProperty);
				if (keyConfig) {
					const value = aDescriptor.value || (aDescriptor.get && aDescriptor.get());
					root.valueChanged(root.config, keyConfig, value, undefined);
				}
			}
		}
		return result;
	}
	static deleteProperty(aTarget, aProperty) {
		this.propertySetter(aTarget, aProperty, undefined);
		delete aTarget[aProperty];
		return true;
	}
}
export class MRtvDefaultConfig {
	static autoObserve = true;
	static observeChilds = true;
	static autoHandling = true;
	static defaultHandler = MRtvObjectHandler;
	static fields = true;
	static defaultPropsConfig = MRtvDefaultConfig;
	static specificValue = false;
}

export class MRtvUtils {
	//#region ConfigMethods
	static configOf(aConfig, aKey) {
		if (!aKey || (aKey.includes && (aKey.includes("*") || aKey.includes("?")))) {
			return undefined;
		}
		if (typeof aConfig.configOf === "function") {
			return aConfig.configOf(aKey);
		}
		let result;
		const useConfig = aConfig[MRtv_OBJECT_CONFIGS] || aConfig;
		if (typeof aKey !== "symbol" && Number.isInteger(+aKey)) {
			result = useConfig.rowsConfig || useConfig.rowsClass?.[MRtv_OBJECT_CONFIGS];
		}
		if (useConfig.fields) {
			const field = useConfig.fields[aKey];
			if (field) result = field;
			if (useConfig.fields !== true) return result;
			else return result || useConfig.defaultPropsConfig || useConfig;
		}
		return result;
	}
	static reactive(aConfig, aObject) {
		if (aObject && aConfig.autoHandling) {
			if (typeof aConfig.reactive === "function") {
				return aConfig.reactive(aObject, ...args);
			} else {
				const handler = aConfig.defaultHandler || MRtvObjectHandler;
				return new Proxy(aObject, handler);
			}
		}
		return aObject;
	}
	static observe(aConfig, aObject, aParams) {
		if (aObject[MRtv_ROOT_OBSERVER]) {
			return aObject;
		}
		const root = new MRtvObserver(aObject);
		if (aConfig.observeChilds) {
			for (let [key, value] of Object.entries(aObject)) {
				if (value && typeof value === "object" && !value[MRtv_ROOT_OBSERVER]) {
					const keyConfig = this.configOf(aConfig, key);
					if (keyConfig) {
						aObject[key] = this.observe(keyConfig, value);
						root.valueChanged(key, keyConfig, value, undefined, aParams);
					}
				}
			}
		}
		return this.reactive(aConfig, aObject);
	}
	//#region ConfigMethods
	//#region ValueMethods
	static getValueOf(aInstanceConfig, aInstance, aKey) {
		if (aKey === MRtv_GET_PROXY_TARGET) return aInstance[MRtv_GET_PROXY_TARGET] || aInstance;
		const keyConfig = this.configOf(aInstanceConfig, aKey);
		if (!keyConfig) {
			//	return aInstance?.[aKey];
			const value = aInstance?.[aKey];
			if (aKey === MRtv_OBJECT_CONFIGS || aKey === MRtv_ROOT_OBSERVER) return value;
			if (typeof value === "function") return value;
			throw Error(`Key ${aKey} was not defined yet!..`);
		}
		const valueConfig = keyConfig.specificValue;
		if (!valueConfig) {
			if (typeof aInstanceConfig.getValueOf === "function") {
				return aInstanceConfig.getValueOf(aInstance, aKey);
			}
			const getter = aInstance?.[MRtv_VALUEOF_GETTER];
			if (typeof getter === "function") {
				const original = aInstance[MRtv_GET_PROXY_TARGET] || aInstance;
				return getter.call(original, aKey);
			}
			return actualGetter(aInstance, aKey);
		}
		if (typeof valueConfig === "object") {
			if (valueConfig.get === true) return aInstance?.[aKey];
			if (typeof valueConfig.get === "function") return valueConfig.get.call(aInstance?.[aKey]);
		}
		if (valueConfig === "get" || valueConfig === true) {
			return aInstance?.[aKey];
		}
		throw Error(`Key ${aKey} is WriteOnly!..`);
	}
	static autoValueSetter(aKeyConfig, aInstance, aKey, aValue, ...args) {
		if (!aInstance) {
			throw Error(`Instance required to set value of ${aKey}!..`);
		}
		if (aValue && aKeyConfig.autoObserve && typeof aValue === "object") {
			aValue = this.observe(aKeyConfig, aValue);
		}
		let current = aInstance[aKey];
		const old = current && typeof current === "object" && MRtv_ACTUAL_GET in current ? current[MRtv_ACTUAL_GET] : current /* prettier-ignore */
		if (!Object.is(old, aValue)) {
			if (current && typeof current === "object" && MRtv_ACTUAL_SET in current) {
				current[MRtv_ACTUAL_SET](aValue, ...args);
			} else {
				const root = aInstance[MRtv_ROOT_OBSERVER];
				const old_value = MRtvUtils.beforeChangeValue(old);
				aInstance[aKey] = aValue ?? null;
				root.valueChanged(aKey, aKeyConfig, aValue, old_value, ...args);
			}
		}
		return aValue ?? true;
	}
	static setValueOf(aInstanceConfig, aInstance, aKey, aValue, ...args) {
		const keyConfig = this.configOf(aInstanceConfig, aKey);
		if (!keyConfig) {
			throw Error(`Key ${aKey} was not defined yet!..`);
		}
		const valueConfig = keyConfig.specificValue;
		if (!valueConfig) {
			if (typeof aInstanceConfig.setValueOf === "function") {
				return aInstanceConfig.setValueOf(aInstance, aKey, aValue, ...args);
			}
			const setter = aInstance?.[MRtv_VALUEOF_SETTER];
			if (typeof setter === "function") {
				const original = aInstance[MRtv_GET_PROXY_TARGET] || aInstance;
				return setter.call(original, aKey, aValue, ...args);
			}
			return this.autoValueSetter(keyConfig, aInstance, aKey, aValue, ...args);
		}
		if (typeof valueConfig === "object") {
			if (valueConfig.set === true) return this.autoValueSetter(keyConfig, aInstance, aKey, aValue, ...args);
			if (typeof valueConfig.set === "function") return valueConfig.set.call(aInstance?.[aKey], aValue, ...args);
		}
		if (valueConfig === "set" || valueConfig === true) {
			return this.autoValueSetter(keyConfig, aInstance, aKey, aValue, ...args);
		}
		throw Error(`Key ${aKey} is ReadOnly!..`);
	}
	static beforeChangeValue(aValue) {
		if (!aValue || typeof aValue !== "object") {
			return aValue;
		}
		const stamp = Symbol();
		const objects = [];
		const loc_Assign = (aObject) => {
			aObject = aObject[MRtv_GET_PROXY_TARGET] || aObject;
			const index = aObject[stamp];
			if (index) {
				return objects[index - 1].new;
			}
			const result = {};
			aObject[stamp] = objects.push({ original: aObject, new: result });
			for (let [key, value] of Object.entries(aObject)) {
				if (value === undefined || typeof value === "function") continue;
				const actual = value && typeof value === "object" && MRtv_ACTUAL_GET in value ? value[MRtv_ACTUAL_GET] : value;
				if (!actual || typeof actual !== "object") result[key] = actual;
				else if (actual[MRtv_MANAGMENT_OBJECT] === true);
				else result[key] = loc_Assign(actual);
			}
			let smbl;
			smbl = aObject[MRtv_OBJECT_CONFIGS];
			if (smbl) result[MRtv_OBJECT_CONFIGS] = smbl;
			smbl = aObject[MRtv_ROOT_OBSERVER];
			if (smbl) result[MRtv_ROOT_OBSERVER] = smbl;
			return result;
		};
		const result = loc_Assign(aValue);
		for (let object of objects) {
			delete object.original[stamp];
		}
		return result;
	}
	//#region ValueMethods
	//#region PathMethods
	static getPathConfig(aPath) {
		if (aPath instanceof Promise) {
			console.error(`${MESSAGE_PROMISE_PATH}, can not get config!..`);
			return undefined;
		}
		if (!Array.isArray(aPath.keysConfigs)) return undefined;
		let result = aPath.keysConfigs[aPath.keysConfigs.length - 1];
		while (result.isAlias) {
			result = result.keysConfigs[result.keysConfigs.length - 1];
		}
		return result;
	}
	static pathConfig(aConfig, aPath, aError) {
		const applier = (path) => (this.isUseablePath(path, aError) ? this.getPathConfig(path) : undefined);
		const path = this.parsePath(aConfig, aPath);
		if (path instanceof Promise) {
			return new Promise((resolve, reject) => path.then((p) => resolve(applier(p))).catch(reject));
		}
		return applier(path);
	}
	static parsePath() {
		const result = this.#parsePath(...arguments);
		if (result.promise) {
			return (async () => {
				let res = result;
				do {
					await res.promise;
					res = this.#parsePath(...arguments);
				} while (res.promise);
				return res;
			})();
		}
		return result;
	}
	static #parsePath(aConfig, aPath) {
		aPath = aPath?.trim ? aPath?.trim() : aPath;
		if (!aPath || !(typeof aPath === "string" || aPath instanceof String)) {
			throw Error("aPath must be string to parsePath function!.");
		}
		if (!aConfig) {
			throw Error("Config required to parsePath function!.");
		}
		let workIn = aConfig;
		let pathElements = aPath.split(".");
		const result = { keys: [], useable: true, rootConfig: aConfig, keysConfigs: [] };
		let name = pathElements[pathElements.length - 1];
		let finalyKey = EVENTS_KEYS_SYMBOLS.key2Internal(name);
		if (finalyKey !== name) {
			result.useable = false;
			pathElements.length--;
		} else {
			finalyKey = undefined;
		}
		const loc_addElement = (index, name) => {
			const useConfig = workIn[MRtv_OBJECT_CONFIGS] || workIn;
			if (useConfig instanceof Promise) {
				result.error = { in: index, name };
				result.useable = false;
				result.promise = useConfig;
				return false;
			}
			const fieldConfig = this.configOf(useConfig, name);
			if (fieldConfig) {
				workIn = fieldConfig;
				result.keysConfigs.push(workIn);
				return true;
			}
			const alias = useConfig.aliases?.[name];
			if (alias) {
				let aliasPath;
				if (typeof alias === "string" || alias instanceof String) {
					aliasPath = alias;
				} else if (typeof alias === "object") {
					aliasPath = alias.value;
				}
				if (aliasPath) {
					const aliasResult = this.#parsePath(useConfig, aliasPath);
					if (aliasResult.promise) {
						result.error = { in: index, name };
						result.useable = false;
						result.promise = aliasResult.promise;
						return false;
					}
					aliasResult.isAlias = alias;
					result.keysConfigs.push(aliasResult);
					if (aliasResult.error) {
						result.error = { in: index, name };
						result.useable = false;
						return false;
					}
					if (!aliasResult.useable) {
						result.useable = false;
						return false;
					}
					workIn = aliasResult.keysConfigs[aliasResult.keysConfigs.length - 1];
					return true;
				}
			}
			result.error = { in: index, name };
			result.useable = false;
			return false;
		};
		for (var i = 0; i < pathElements.length; i++) {
			name = pathElements[i];
			let listRow = name.endsWith("]");
			if (listRow) {
				const arrayStart = name.indexOf("[");
				listRow = name.substring(arrayStart + 1, name.length - 1);
				name = name.substring(0, arrayStart);
			}
			let internal_name = EVENTS_KEYS_SYMBOLS.key2Internal(name);
			let internal_row = listRow ? EVENTS_KEYS_SYMBOLS.key2Internal(listRow) : listRow;
			const keyIndex = result.keys.push(internal_name) - 1;
			if (listRow) {
				result.keys.push(internal_row);
			}
			if (name !== internal_name || listRow !== internal_row) {
				result.useable = false;
				break;
			}
			if (!loc_addElement(keyIndex, name) || (listRow && !loc_addElement(keyIndex + 1, listRow))) {
				break;
			}
		}
		for (i++; i < pathElements.length; i++) {
			name = pathElements[i];
			const name_internal = EVENTS_KEYS_SYMBOLS.key2Internal(name);
			const index = result.keys.push(name_internal) - 1;
			if (!result.error && (!name || name.includes("*"))) {
				result.error = { in: index, name };
			}
		}
		if (finalyKey) {
			result.keys.push(finalyKey);
		}
		return result;
	}
	static enumPaths(aPath, aCallback, ...args) {
		if (aPath instanceof Promise) {
			throw new Error(`${MESSAGE_PROMISE_PATH}, can not enumerate...`);
		}
		let result = true;
		for (let i = 0; i < aPath.keys.length; i++) {
			const keyConfig = aPath.keysConfigs[i];
			if (!keyConfig?.isAlias) result = aCallback(aPath.keys[i], keyConfig, ...args);
			else result = this.enumPaths(keyConfig, aCallback, ...args);
			if (result === null || result === undefined) {
				return result;
			}
		}
		return result;
	}
	static pathErrorDetail(aResult, aForUseable) {
		let result = "";
		let i = 0;
		const loc_AddKeys = (aStop) => {
			for (; i < aStop; i++) {
				const internal = aResult.keys[i];
				let key = EVENTS_KEYS_SYMBOLS.internal2Key(internal);
				if (aForUseable && key !== internal) {
					key = `![${key}]`;
				}
				result += (result ? `.` : "") + key;
			}
		};
		if (aResult?.error) {
			loc_AddKeys(aResult.error.in ?? 0);
			result += (result ? `.` : "") + `![${aResult.error.name}]`;
			i++;
		}
		loc_AddKeys(aResult.keys.length ?? 0);
		return `PathKeys "${result}" is error.`;
	}
	static isUseablePath(aResult, aError = true) {
		if (!aResult?.useable) {
			if (aError) {
				throw Error(this.pathErrorDetail(aResult, true));
			}
			return false;
		}
		return true;
	}
	static isEventablePath(aResult, aError = true) {
		if (aResult?.error) {
			if (aError) {
				throw Error(this.pathErrorDetail(aResult, false));
			}
			return false;
		}
		return true;
	}
	//#endregion PathMethods
	//#region EventsMethos
	static #eventsSymbol = Symbol();
	static initateEventsParams(aParams) {
		if (aParams?.[this.#eventsSymbol]) {
			return aParams;
		}
		let _keys, _path;
		if (!aParams) aParams = {};
		aParams[this.#eventsSymbol] = true;
		Object.defineProperty(aParams, "keys", {
			get: () => _keys,
			set: (aKeys) => {
				(_keys = aKeys), (_path = undefined);
			},
		});
		Object.defineProperty(aParams, "path", {
			get: () => {
				if (!_keys) {
					return undefined;
				}
				if (_path === undefined) {
					_path = "";
					for (let key of _keys) {
						_path += key + ".";
					}
					_path = _path.slice(0, -1);
				}
				return _path;
			},
		});
		return aParams;
	}
	//#endregion EventsMethos
}

const uidGetter =
	process.env.NODE_ENV === "production"
		? uniqueID
		: (() => {
				let id = 1;
				return () => `o${id++}`;
		  })();

//export const allObservers = [];
export class MRtvObserver {
	//#region Fields
	#uuid = uidGetter();
	#holder;
	#config;
	#events;
	#listeners;
	//#endregion Fields
	//#region Creator
	constructor(aHolder, aConfig) {
		if (!aHolder || typeof aHolder !== "object") {
			throw Error(`Observe holder expected type is object, but get [${typeof aHolder}]!..`);
		}
		if (aHolder[MRtv_ROOT_OBSERVER]) {
			throw Error(`Holder ${aHolder} was already observed!..`);
		}
		//allObservers.push(this);
		const original = aHolder[MRtv_GET_PROXY_TARGET] || aHolder;
		this.#config = aConfig || original[MRtv_OBJECT_CONFIGS] || MRtvDefaultConfig;
		original[MRtv_ROOT_OBSERVER] = this;
		this.#holder = new WeakRef(original);
		this.isListRoot = Array.isArray(aHolder);
	}
	//#endregion Creator
	//#region Information
	get uuid() {
		return this.#uuid;
	}
	get holder() {
		return this.#holder.deref();
	}
	get config() {
		return this.#config;
	}
	get events() {
		return this.#events;
	}
	get listeners() {
		return this.#listeners;
	}
	print() {
		let str = `{${this.#uuid}:`;
		if (this.#listeners) {
			let listeners = "";
			this.#forEachListener((listener) => (listeners += `${listener.path}, `));
			str += `\nlisteners: [${listeners.slice(0, -2)}].`;
		}
		str += "\n}";
		console.log(str);
		return str;
	}
	//#endregion Information
	//#region Events
	eventAdd(aType, aPath, ...args) {
		const applier = (pathKeys) => {
			MRtvUtils.isEventablePath(pathKeys, true);
			return this.eventAdding(aType, pathKeys, ...args);
		};
		const pathKeys = MRtvUtils.parsePath(this.#config, aPath);
		if (pathKeys instanceof Promise) {
			return new Promise((resolve, reject) => pathKeys.then((p) => resolve(applier(p))).catch(reject));
		}
		return applier(pathKeys);
	}
	eventAdding(aType, aParsingPath, aCallback, aCallby, aPayload) {
		if (typeof aCallback !== "function") {
			throw Error(`eventAdd param 'callback' expected type 'function' but get '${typeof aCallback}'.`);
		}
		if (!this.#events) {
			this.#events = new MRtvEvents();
		}
		return this.#events.on(...arguments);
	}
	eventRemove(aType, aPath, ...args) {
		const applier = (pathKeys) => {
			MRtvUtils.isEventablePath(pathKeys, true);
			return this.eventRemoving(aType, pathKeys, ...args);
		};
		const pathKeys = MRtvUtils.parsePath(this.#config, aPath);
		if (pathKeys instanceof Promise) {
			return new Promise((resolve, reject) => pathKeys.then((p) => resolve(applier(p))).catch(reject));
		}
		return applier(pathKeys);
	}
	eventRemoving(aType, aParsingPath, aCallback) {
		if (typeof aCallback !== "function") {
			throw Error(`eventRemove param 'callback' expected type 'function' but get '${typeof aCallback}'.`);
		}
		if (!this.#events) {
			throw Error(`Observer don't have events!..`);
		}
		return this.#events.off(...arguments);
	}
	#eventEmiting(aType, aParents, aKey, aKeyConfig, aNew, aOld, aParams) {
		if (!this.#events) return;
		const instance = this.holder;
		aParams.holder = instance;
		let result;
		try {
			const keys = aParents ? [...aParents, aKey] : [aKey];
			aParams.keys = keys;
			aParams.observer = this;
			result = this.#events.emit(aType, keys, aNew, aOld, aParams, instance);
		} finally {
			//delete aParams.keys;
			delete aParams.holder;
			delete aParams.observer;
		}
		if (aType === MRtv_EVENTS_TYPES_VALUE) {
			const changedDependencies = aKeyConfig?.changedDependencies;
			if (typeof changedDependencies === "function") {
				const changes = changedDependencies.call(aKeyConfig, aNew, aOld);
				changes?.forEach((depended) => {
					this.#eventEmiting(
						aType,
						aParents,
						depended.key,
						depended.config,
						depended.new_value,
						depended.old_value,
						aParams
					);
				});
			}
		}
		return result;
	}
	eventEmit(aType, aKey, aKeyConfig, aNew, aOld, aParams) {
		aParams = MRtvUtils.initateEventsParams(aParams);
		this.#eventEmiting(aType, undefined, aKey, aKeyConfig, aNew, aOld, aParams);
		this.#forEachListener((listener) => {
			const root = listener.root;
			const keys = [...listener.keys];
			aParams.listener = listener;
			if (listener.rowIndex !== undefined) {
				aParams.rowIndex = listener.rowIndex;
			}
			root.#eventEmiting(aType, keys, aKey, aKeyConfig, aNew, aOld, aParams);
			delete aParams.listener;
			delete aParams.rowIndex;
		});
	}
	//#endregion Events
	//#region ValueManagement
	isListener(aRoot, aPath) {
		const observer = this.#listeners?.[aRoot.#uuid];
		return observer && (!aPath || observer[aPath]);
	}
	isAnOwner(aRootOrHolder) {
		const root = aRootOrHolder instanceof MRtvObserver ? aRootOrHolder : aRootOrHolder?.[MRtv_ROOT_OBSERVER];
		return root && !!this.#listeners?.[root.#uuid];
	}
	#listenerAdd(aListener) {
		const {
			root: { uuid: id },
			path,
		} = aListener;
		const listeners = this.#listeners;
		if (!listeners) this.#listeners = { [COUNT_SYMBOL]: 1, [id]: { [COUNT_SYMBOL]: 1, [path]: aListener } };
		else {
			let observer = listeners[id];
			if (!observer) listeners[id] = { [COUNT_SYMBOL]: 1, [path]: aListener };
			else {
				if (observer[path]) {
					throw new Error(`listener '${id}/${path}' was already registered!..`);
					//return;
				}
				observer[COUNT_SYMBOL]++;
				observer[path] = aListener;
			}
			listeners[COUNT_SYMBOL]++;
		}
		return aListener;
	}
	#listenerRemove(aListener) {
		const {
			root: { uuid: id },
			path,
		} = aListener;
		const listeners = this.#listeners;
		const observer = listeners?.[id];
		const listener = observer?.[path];
		if (!listener) {
			if (process.env.NODE_ENV === "production") {
				return false;
			}
			throw new Error(`listener '${id}.${path}' was not registered!..`);
		}
		delete observer[path];
		let count = --observer[COUNT_SYMBOL];
		if (count === 0) delete listeners[id];
		count = --listeners[COUNT_SYMBOL];
		if (count === 0) this.#listeners = undefined;
		return listener;
	}
	#forEachListener(aCallback) {
		const listeners = this.#listeners;
		if (!listeners) return;
		Object.values(listeners).forEach((observer) => Object.values(observer).forEach(aCallback));
	}
	#resetHolderFor(aKey, aKeyConfig, aNew, aOld, aParams) {
		const loc_listenerFor = (aObserver, aKey, aListener, aOldObserver) => {
			const key = aKey;
			const observers = [];
			let path, root, location;
			let isListRoot, rowIndex, listRow;
			if (!aListener) {
				root = this;
				path = aKey;
				if (aObserver === this) location = aKey;
				if (this.isListRoot) {
					listRow = Number.isInteger(+aKey) ? +aKey : aKey;
					rowIndex = listRow;
				} else isListRoot = aObserver.isListRoot;
			} else {
				root = aListener.root;
				path = `${aListener.path}.${key}`;
				location = aListener.location;
				const l_observers = aListener.observers;
				const lo_length = l_observers.length;
				if (aListener.isListRoot) {
					if (aListener.rowIndex !== undefined) {
						//canceling repeating... need perform in future.
						const index = l_observers.indexOf(aObserver);
						if (index >= 0) return;
					}
					listRow = Number.isInteger(+aKey) ? +aKey : aKey;
					rowIndex = listRow;
				} else {
					isListRoot = aObserver.isListRoot;
					rowIndex = aListener.rowIndex;
				}
				observers.push(...l_observers);
				if (aOldObserver && aOldObserver !== aObserver) {
					let index = observers.indexOf(aOldObserver);
					while (index >= 0) {
						observers[index] = aObserver;
						index = observers.indexOf(aOldObserver);
					}
				}
				const index = observers.indexOf(aObserver);
				if (index >= 0) {
					const keys = aListener.keys;
					if (keys[index] === aKey) {
						//canceling repeating.
						return;
					}
					for (let i = index + 1; i < lo_length; i++) {
						if (observers[i] === aObserver && keys[i] === aKey) {
							//canceling repeating.
							return;
						}
					}
					if (!location) location = path;
				}
				if (!location && this.isAnOwner(aObserver)) {
					location = path;
				}
			}
			let keys;
			observers.push(aObserver);
			Object.freeze(observers);
			const result = {
				key,
				path,
				root,
				location,
				observers,
				get keys() {
					if (keys === undefined) keys = this.path.split(".");
					return keys;
				},
			};
			if (isListRoot) result.isListRoot = isListRoot;
			if (listRow !== undefined) result.listRow = listRow;
			if (rowIndex !== undefined) result.rowIndex = rowIndex;
			Object.freeze(result);
			return result;
		};

		const loc_ResetObject = (aConfig, aNew, aOld, aNewRoot, aOldRoot, aNewListeners, aOldListeners, aResettingKey) => {
			const emited = {};
			const loop = (aObject) => {
				for (let key of Object.keys(aObject)) {
					if (emited[key]) continue;
					emited[key] = true;
					const new_value = actualGetter(aNew, key);
					const old_value = actualGetter(aOld, key);
					if (new_value?.[MRtv_MANAGMENT_OBJECT] === true || old_value?.[MRtv_MANAGMENT_OBJECT] === true) {
						continue;
					}
					const new_root = new_value?.[MRtv_ROOT_OBSERVER];
					const old_root = old_value?.[MRtv_ROOT_OBSERVER];
					const keyConfig = MRtvUtils.configOf(aConfig, key) || new_root?.config || old_root?.config;
					const notified = {};
					const notifier = (o) => {
						const { path } = o;
						if (notified[path]) return;
						notified[path] = true;
						aParams.listener = o;
						if (o.rowIndex !== undefined) aParams.rowIndex = o.rowIndex;
						o.root.#eventEmiting(MRtv_EVENTS_TYPES_VALUE, o.keys, key, keyConfig, new_value, old_value, aParams);
						delete aParams.listener;
						delete aParams.rowIndex;
					};
					aNewListeners?.forEach(notifier);
					aOldListeners?.forEach(notifier);
					if (!new_root && !old_root) continue;
					const new_listeners = new_root && aNewListeners?.length > 0 && [];
					const old_listeners = old_root && aOldListeners?.length > 0 && [];
					if (new_listeners) {
						aNewListeners.forEach((aListener) => {
							const listener = loc_listenerFor(new_root, key, aListener, old_root);
							if (listener) new_listeners.push(listener);
						});
					}
					if (old_listeners) {
						aOldListeners.forEach((aListener) => {
							const listener = loc_listenerFor(old_root, key, aListener);
							if (listener) old_listeners.push(listener);
						});
					}
					if (new_listeners?.length > 0 || old_listeners?.length > 0) {
						loc_ResetObject(keyConfig, new_value, old_value, new_root, old_root, new_listeners, old_listeners, key);
					}
				}
			};
			const inStack = { key: aResettingKey, new: aNew, old: aOld };
			try {
				aParams.resetting.stack.push(inStack);
				if (aOldListeners?.length > 0) {
					loop(aOld);
					aOldListeners.forEach((o) => aOldRoot.#listenerRemove(o));
				}
				if (aNewListeners?.length > 0) {
					aNewListeners.forEach((o) => aNewRoot.#listenerAdd(o));
					loop(aNew);
				}
			} finally {
				if (aParams.resetting.stack.pop() !== inStack) {
					console.warn("dont play with resettingStack please...");
				}
			}
		};

		aParams = MRtvUtils.initateEventsParams(aParams);
		aParams.resetting = { holder: this.holder, stack: [] };
		try {
			const new_root = aNew?.[MRtv_ROOT_OBSERVER];
			const old_root = aOld?.[MRtv_ROOT_OBSERVER];
			const new_listeners = new_root ? [] : undefined;
			const old_listeners = old_root ? [] : undefined;
			const add_listener = (aListener) => {
				if (new_listeners) {
					const listener = loc_listenerFor(new_root, aKey, aListener, old_root);
					if (listener) new_listeners.push(listener);
				}
				if (old_listeners) {
					const listener = loc_listenerFor(old_root, aKey, aListener);
					if (listener) old_listeners.push(listener);
				}
			};
			add_listener();
			this.#forEachListener(add_listener);
			loc_ResetObject(aKeyConfig, aNew, aOld, new_root, old_root, new_listeners, old_listeners, aKey);
		} finally {
			delete aParams.resetting;
		}
	}
	valueChanged(aKey, aKeyConfig, aNew, aOld, aParams) {
		this.eventEmit(MRtv_EVENTS_TYPES_VALUE, aKey, aKeyConfig, aNew, aOld, aParams);
		const oldRoot = aOld?.[MRtv_ROOT_OBSERVER];
		const newRoot = aNew?.[MRtv_ROOT_OBSERVER];
		if (oldRoot || newRoot) {
			this.#resetHolderFor(...arguments);
		}
	}
	//#endregion ValueManagement
}

//#region EventsKeys
const EVENTS_KEYS_EVENTS = Symbol("events");
const EVENTS_KEYS_SYMBOLS = {
	/* ?  */ any: Symbol("any"),
	/* ?N */ any_row: Symbol("anyRow"),
	/* *  */ all_child: Symbol("allChilds"),
	/* ** */ all_recursive: Symbol("allRecursive"),
	internal2Key(aKey) {
		if (aKey === this.any) return "?";
		if (aKey === this.any_row) return "?N";
		if (aKey === this.all_child) return "*";
		if (aKey === this.all_recursive) return "**";
		return aKey;
	},
	key2Internal(aKey) {
		if (aKey === "?") return this.any;
		if (aKey === "?N") return this.any_row;
		if (aKey === "*") return this.all_child;
		if (aKey === "**") return this.all_recursive;
		return aKey;
	},
};
//#endregion EventsKeys

class MRtvEvents {
	#types = { [COUNT_SYMBOL]: 0 };
	get isEmpty() {
		return this.#types[COUNT_SYMBOL] <= 0;
	}
	on(aType, aPath, aCallback, aCallby, aPayload) {
		const keys = [aType];
		MRtvUtils.enumPaths(aPath, (aKey) => keys.push(aKey));
		let workIn = this.#types;
		for (let key of keys) {
			let sub = workIn[key];
			if (!sub) {
				sub = { [COUNT_SYMBOL]: 1 };
				workIn[key] = sub;
			} else {
				sub[COUNT_SYMBOL]++;
			}
			workIn = sub;
		}
		let events = workIn[EVENTS_KEYS_EVENTS];
		if (!events) {
			events = [];
			workIn[EVENTS_KEYS_EVENTS] = events;
		}
		events.push({ func: new WeakRef(aCallback), callby: aCallby, payload: aPayload });
		this.#types[COUNT_SYMBOL]++;
	}
	off(aType, aPath, aCallback) {
		const keys = [aType];
		MRtvUtils.enumPaths(aPath, (aKey) => keys.push(aKey));
		const loc_Error = () => {
			let str = "";
			for (let key of keys) str += EVENTS_KEYS_SYMBOLS.internal2Key(key) + ".";
			str = str.slice(0, -1);
			throw Error(`Event "${str}" was not registered!.`);
		};
		let workIn = this.#types;
		const objects = [workIn];
		for (let key of keys) {
			let sub = workIn[key];
			if (!sub) {
				loc_Error();
			}
			objects.push(sub);
			workIn = sub;
		}
		const events = workIn[EVENTS_KEYS_EVENTS];
		const index = events?.findIndex((e) => e.func.deref() === aCallback);
		if ((index ?? -1) < 0) {
			loc_Error();
		}
		events.splice(index, 1);
		if (events.length === 0) {
			delete workIn[EVENTS_KEYS_EVENTS];
		}
		for (let i = objects.length - 1; i >= 1; i--) {
			const object = objects[i];
			const count = --object[COUNT_SYMBOL];
			if (count === 0) {
				delete objects[i - 1][keys[i - 1]];
			}
		}
		this.#types[COUNT_SYMBOL]--;
	}
	emit(aType, aKeys, aNew, aOld, aParams, aInstance) {
		const typeEvents = this.#types[aType];
		if (!typeEvents) {
			return;
		}
		const alls = [];
		const loc_Search = (aKey, aArray) => {
			const result = [];
			for (let parent of aArray) {
				let srchIn = parent[aKey];
				if (srchIn) {
					result.push(srchIn);
				}
				if (Number.isInteger(+aKey)) {
					srchIn = parent[EVENTS_KEYS_SYMBOLS.any_row];
					if (srchIn) {
						result.push(srchIn);
					}
				}
				srchIn = parent[EVENTS_KEYS_SYMBOLS.any];
				if (srchIn) {
					result.push(srchIn);
				}
				const all_events = parent[EVENTS_KEYS_SYMBOLS.all_recursive]?.[EVENTS_KEYS_EVENTS];
				if (all_events) {
					alls.push(all_events);
				}
			}
			return result;
		};
		let array = [typeEvents];
		for (let i = 0; i < aKeys.length - 1; i++) {
			array = loc_Search(aKeys[i], array);
			if (array.length === 0) {
				array = undefined;
				break;
			}
		}
		let finals;
		if (array) {
			finals = [];
			const key = aKeys[aKeys.length - 1];
			for (let final of array) {
				let events;
				events = final[EVENTS_KEYS_SYMBOLS.all_recursive]?.[EVENTS_KEYS_EVENTS];
				if (events) {
					alls.push(events);
				}
				events = final[EVENTS_KEYS_SYMBOLS.all_child]?.[EVENTS_KEYS_EVENTS];
				if (events) {
					alls.push(events);
				}
				events = final[key]?.[EVENTS_KEYS_EVENTS];
				if (events) {
					finals.push(events);
				}
				if (Number.isInteger(+key)) {
					events = final[EVENTS_KEYS_SYMBOLS.any_row]?.[EVENTS_KEYS_EVENTS];
					if (events) {
						finals.push(events);
					}
				}
				events = final[EVENTS_KEYS_SYMBOLS.any]?.[EVENTS_KEYS_EVENTS];
				if (events) {
					finals.push(events);
				}
			}
		}
		const loc_Call = (aArray) => {
			for (let evetns of aArray) {
				for (let event of evetns) {
					const func = event.func.deref();
					if (!func) {
						console.error("MemoryLeak accrued with events!..");
						continue;
					}
					const instance = event.callby || aInstance;
					aParams.payload = event.payload;
					try {
						func.call(instance, aNew, aOld, aParams);
					} finally {
						delete aParams.payload;
					}
				}
			}
		};
		if (!aParams) {
			throw Error("Params is required to emit events!..");
		}
		if (finals && finals.length > 0) loc_Call(finals);
		if (alls && alls.length > 0) loc_Call(alls.reverse());
	}
}

function addEventsMethods(aObject) {
	const methods = {
		get observer() {
			const result = this[MRtv_ROOT_OBSERVER];
			if (!result) {
				throw Error("Observer required for ObjectEvents!..");
			}
			return result;
		},
		on(...args) {
			return this.observer.eventAdd(...args);
		},
		off(...args) {
			return this.observer.eventRemove(...args);
		},
		emit(aType, aPath, aNew, aOld, aParams) {
			let observer = this.observer;
			const applier = (path) => {
				MRtvUtils.isUseablePath(path, true);
				const parents = path.keys.slice(0, -1);
				if (parents) {
					let holder = observer.holder;
					if (!holder) return false;
					for (let key of parents) {
						let sub = MRtvUtils.getValueOf(observer.config, holder, key);
						observer = sub?.[MRtv_ROOT_OBSERVER];
						if (!observer) return false;
					}
				}
				const key = path.keys[path.keys.length - 1];
				const keyConfig = MRtvUtils.getPathConfig(path);
				return observer.eventEmit(aType, key, keyConfig, aNew, aOld, aParams);
			};
			const path = MRtvUtils.parsePath(observer.config, aPath);
			if (path instanceof Promise) {
				return new Promise((resolve, reject) => path.then((p) => resolve(applier(p))).catch(reject));
			}
			return applier(p);
		},
		onChangedAdd(...args) {
			return this.observer.eventAdd(MRtv_EVENTS_TYPES_VALUE, ...args);
		},
		onChangedRemove(...args) {
			return this.observer.eventRemove(MRtv_EVENTS_TYPES_VALUE, ...args);
		},
		emitChanged(...args) {
			return this.emit(MRtv_EVENTS_TYPES_VALUE, ...args);
		},
	};
	const obj = Object.getPrototypeOf(aObject);
	if (obj.constructor !== Object) {
		Object.setPrototypeOf(methods, obj);
	}
	Object.setPrototypeOf(aObject, methods);
}

export const makeEventable = (aObject) => {
	const root = new MRtvObserver(aObject);
	addEventsMethods(aObject);
	return MRtvUtils.reactive(root.config, aObject);
};
