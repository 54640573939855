import React, { forwardRef, useImperativeHandle, useState } from "react";
import MIcon from "@compts:std/MSIcon/MSIcon.jsx";
import { useTranslation } from "@i18n";
import PopupMenu from "./PopupMenu";
import MListItem from "./MListItem";
import MPaper from "@compts:std/MSPaper/MSPaper.jsx";

// let level = 1;
const isLTR = () => document.dir === "ltr";
export const MMenu = forwardRef(
	(
		{
			mode = "vertical",
			disabledPadding = false,
			className,
			popupClassName,
			colorIcon,
			onClick,
			data,
			multiSelect = false,
			expandIcon,
			expandIconColor,
			orVertical,
			orHorizontal,
			transVertical = "",
			transHorizontal = "",
			readonly = false,
			...props
		},
		ref
	) => {
		const [anchorEls, setAnchorEls] = useState({});
		const horizontalMode = mode.toLocaleLowerCase() === "horizontal";
		const trans = useTranslation();
		const keysNumber = () => Object.keys(anchorEls).length;

		const handleOpenMenu = (event, path, levelChild) => {
			onClick && onClick(event);

			if (keysNumber() === levelChild) {
				addAnchorElTO(anchorEls);
			} else {
				const temp = { ...anchorEls };
				Object.keys(temp).forEach((key, i) => {
					if (i >= levelChild) delete temp[key];
				});

				addAnchorElTO(temp);
			}

			function addAnchorElTO(items) {
				setAnchorEls({ ...items, [path]: event.currentTarget });
			}
		};

		const handleCloseMenu = () => {
			setAnchorEls({});
		};
		const handleOriginHorizontal = () => {
			if (orHorizontal !== undefined) return orHorizontal;
			return "left";
			// if (horizontalMode) return isLTR ? "right" : "left";
			// else return isLTR ? "right" : "left";
		};
		const handleOriginVertical = () => {
			if (orVertical !== undefined) return orVertical;
			if (horizontalMode) return "bottom";
			return "top";
		};
		const handleIcon = () => {
			if (horizontalMode) return "ChevronDown";
			else return isLTR() ? "ChevronRight" : "ChevronLeft";
		};
		// Recursive function to render menus and childrens
		const renderMenu = (items, path = "", levelChild) => {
			return (
				keysNumber() > 0 && (
					<PopupMenu
						dir={document.dir}
						element={anchorEls[path]}
						open={Boolean(anchorEls[path])}
						onClose={() => handleCloseMenu(path)}
						elementOrigin={{
							vertical: handleOriginVertical(),
							horizontal: handleOriginHorizontal(),
						}}
						transformOrigin={{
							vertical: transVertical,
							horizontal: transHorizontal,
						}}
					>
						{renderList(items, path, levelChild)}
					</PopupMenu>
				)
			);
		};

		function renderList(items, path, levelChild) {
			return (
				Boolean(anchorEls[path]) && (
					<MPaper
						onClick={(event) => event.stopPropagation()}
						className={`flex elevation-2 rounded-md flex-col gap-1 p-2 !m-0 ${popupClassName}`}
					>
						{items.map((item, index) => {
							const currentPath = path ? `${path}-` + index : `${index}`;

							return (
								<div key={currentPath}>
									{item.type === "container" ? (
										item.label
									) : (
										<MListItem
											{...props}
											className={`flex relative justify-between items-center ${currentPath}`}
											onMouseEnter={(event) => {
												return item.children && handleOpenMenu(event, currentPath, levelChild);
											}}
											onClick={() => {
												!item.children && !multiSelect && handleCloseMenu();
												!item.children && onClick && onClick(item);
											}}
											text={item.label}
											icon={item.icon && <MIcon name={item.icon} className={colorIcon ?? "text-primary"} />}
											// selected={item.selected}
											collapseIcon={
												item.children &&
												(expandIcon || (
													<MIcon
														name={trans.isLTR ? "ChevronRight" : "ChevronLeft"}
														className={expandIconColor ?? ""}
													/>
												))
											}
										>
											{item.children && (
												<div
													className={`absolute ${
														isLTR() ? "translate-x-full -right-3" : "-translate-x-full -left-3"
													} shadow-md shadow-slate-400  top-0 rounded-md p-0 max-h-[80vh] overflow-auto`}
												>
													{item.children && renderList(item.children, currentPath, levelChild + 1)}
													{/* <MList data={item.children} /> */}
												</div>
											)}
										</MListItem>
									)}
								</div>
							);
						})}
					</MPaper>
				)
			);
		}
		function toggleMenu(event, index = 0) {
			if (Object.keys(anchorEls).length !== 0) handleCloseMenu();
			else handleOpenMenu(event, `root${index}`);
		}
		useImperativeHandle(ref, () => ({
			closeMenu: () => handleCloseMenu(),
			openMenu: (event) => handleOpenMenu(event, "root0", 0),
		}));
		return (
			data && (
				<div className={`${horizontalMode ? "flex" : ""} ${className ?? ""}`}>
					{data.map((ele, index) => {
						return (
							<div key={index}>
								{ele.type === "container" ? (
									<div
										onClick={(event) => {
											if (readonly) return;
											toggleMenu(event, index);
											!ele.children && onClick && onClick(ele);
										}}
									>
										{ele.label}
									</div>
								) : (
									<MListItem
										className="relative"
										ref={ref}
										{...props}
										onClick={(event) => {
											if (readonly) return;
											toggleMenu(event, index);
											!ele.children && onClick && onClick(ele);
										}}
										text={ele.label}
										icon={ele.icon && <MIcon name={ele.icon} className={colorIcon ?? "text-primary"} />}
										collapseIcon={
											ele.children &&
											(expandIcon || <MIcon name={handleIcon()} className={expandIconColor ?? ""} size={0.9} />)
										}
									/>
								)}
								{ele.children && renderMenu(ele.children, "root" + index, 1)}
							</div>
						);
					})}
				</div>
			)
		);
	}
);
export default MMenu;
