String.isString = (aValue) => {
	return typeof aValue === "string" || aValue instanceof String;
};
Number.isNumber = (aValue) => {
	return typeof aValue === "number" || aValue instanceof Number;
};
Date.parseDateTime = (aValue) => {
	if (!aValue) return Number.NaN;
	if (aValue instanceof Date) return aValue;
	const result = Date.parse(aValue);
	return isNaN(result) ? result : new Date(result);
};
Date.parseTime = (aTime) => {
	if (!aTime) return Number.NaN;
	if (typeof aTime === "string") {
		try {
			aTime = new Date(aTime);
		} catch {}
	}
	if (aTime instanceof Date) {
		const result = new Date(0);
		result.setUTCHours(aTime.getUTCHours());
		result.setUTCMinutes(aTime.getUTCMinutes());
		result.setUTCSeconds(aTime.getUTCSeconds());
		result.setUTCMilliseconds(aTime.getUTCMilliseconds());
		return result;
	}
	const parts = aTime.match(/^\s*(\d\d?)(?::?(\d\d))?(?::(\d\d))?(?!\d)(\.\d+)?\s*(pm?|am?)?/i);
	if (parts) {
		let hour = parseInt(parts[1]);
		const pm = parts[5]?.[0].toUpperCase();
		const min = parts[2] ? parseInt(parts[2]) : 0;
		const sec = parts[3] ? parseInt(parts[3]) : 0;
		const ms = parts[4] ? parseFloat(parts[4]) * 1000 : 0;
		if ((pm && (hour == 0 || hour > 12)) || hour > 24 || min >= 60 || sec >= 60) return undefined;
		if (pm === "A" && hour === 12) hour = 0;
		if (pm === "P" && hour !== 12) hour += 12;
		if (hour === 24) hour = 0;
		const result = new Date();
		result.setHours(hour, min, sec, ms);
		return result;
	}
	return Number.NaN;
};

export class DataTypeBase {
	static #instances = {};
	static createOrGet() {
		let args = this.name;
		for (let arg of arguments) {
			args = args + "," + arg;
		}
		var instance = DataTypeBase.#instances[args];
		if (instance === undefined) {
			instance = new this(...arguments);
			DataTypeBase.#instances[args] = instance;
		}
		return instance;
	}

	static makeInvokable() {
		const classType = this;
		return new Proxy(classType, {
			apply(aTarget, aThisArg, aArgs) {
				return classType.createOrGet(...aArgs);
			},
			construct(aTarget, aArgs) {
				return classType.createOrGet(...aArgs);
			},
		});
	}

	toString() {
		return this.constructor.typeName;
	}

	toSQLValue() {
		return this.constructor.toSQLValue(...arguments);
	}

	static toString() {
		return this.typeName;
	}

	get typeName() {
		return this.constructor.typeName;
	}
	static get typeName() {
		return this.name;
	}

	//#region API Methods
	toApiRequest() {
		return this.constructor.toApiRequest(...arguments);
	}
	toApiResponse() {
		return this.constructor.toApiResponse(...arguments);
	}
	fromApiRequest() {
		return this.constructor.fromApiRequest(...arguments);
	}
	fromApiResponse() {
		return this.constructor.fromApiResponse(...arguments);
	}
	static toApiRequest(aValue /*, aInfo*/) {
		return aValue;
	}
	static toApiResponse(aValue /*, aInfo*/) {
		return aValue;
	}
	static fromApiRequest(aValue /*, aInfo*/) {
		return aValue;
	}
	static fromApiResponse(aValue /*, aInfo*/) {
		return aValue;
	}
	//#endregion API Methods
}

class NumberDataType extends DataTypeBase {
	get isNumber() {
		return true;
	}
	static get isNumber() {
		return true;
	}
}
class IntDataType extends NumberDataType {
	static toSQLValue(aValue) {
		return Number.parseInt(aValue).toString();
	}
	static toApiRequest(aValue) {
		if (aValue === null) {
			return null;
		}
		return aValue ? Number.parseInt(aValue) : undefined;
	}
	static toApiResponse(aValue) {
		if (aValue === null) {
			return null;
		}
		return aValue ? Number.parseInt(aValue) : undefined;
	}
	static fromApiRequest(aValue) {
		if (aValue === null) {
			return null;
		}
		return aValue === undefined ? null : Number.parseInt(aValue);
	}
	static fromApiResponse(aValue) {
		if (aValue === null) {
			return null;
		}
		return aValue ? Number.parseInt(aValue) : undefined;
	}
}
class FloatDataType extends NumberDataType {
	static toSQLValue(aValue) {
		if (aValue === null) {
			return null;
		}
		return Number.parseFloat(aValue).toString();
	}
	static toApiRequest(aValue) {
		if (aValue === null) {
			return null;
		}
		return aValue ? Number.parseFloat(aValue) : undefined;
	}
	static toApiResponse(aValue) {
		if (aValue === null) {
			return null;
		}
		return aValue ? Number.parseFloat(aValue) : undefined;
	}
	static fromApiRequest(aValue) {
		if (aValue === null) {
			return null;
		}
		return aValue === undefined ? null : Number.parseFloat(aValue);
	}
	static fromApiResponse(aValue) {
		if (aValue === null) {
			return null;
		}
		return aValue ? Number.parseFloat(aValue) : undefined;
	}
}
class StrDataType extends DataTypeBase {
	static toSQLValue(aValue) {
		if (aValue === null) {
			return null;
		}
		return `N'${aValue}'`;
	}
}

class BOOLEAN extends DataTypeBase {
	static get typeName() {
		return "BOOLEAN";
	}

	static toSQLValue(aValue) {
		if (aValue === null) {
			return null;
		}
		return aValue ? "1" : "0";
	}
	static toApiRequest(aValue) {
		if (aValue === null) {
			return null;
		}
		return aValue === true;
	}
	static toApiResponse(aValue) {
		if (aValue === null) {
			return null;
		}
		return aValue === true;
	}
	static fromApiRequest(aValue) {
		if (aValue === null) {
			return null;
		}
		return aValue === true;
	}
	static fromApiResponse(aValue) {
		if (aValue === null) {
			return null;
		}
		return aValue === true;
	}
}
class TINYINT extends IntDataType {
	static get typeName() {
		return "TINYINT";
	}
}
class SMALLINT extends IntDataType {
	static get typeName() {
		return "SMALLINT";
	}
}
class INTEGER extends IntDataType {
	static get typeName() {
		return "INTEGER";
	}
}
class BIGINT extends IntDataType {
	static get typeName() {
		return "BIGINT";
	}
}
class CHAR extends StrDataType {
	static get typeName() {
		return "CHAR";
	}
}

class STRING extends StrDataType {
	#length = 250;
	constructor(aLength = 250) {
		super();
		this.#length = aLength;
	}

	get length() {
		return this.#length;
	}

	static get length() {
		return 250;
	}

	toString() {
		return `${super.toString()}(${this.#length})`;
	}
	static toString() {
		return `${super.toString()}(250)`;
	}

	static get typeName() {
		return "STRING";
	}
}

class ANSISTRING extends StrDataType {
	#length = 250;
	constructor(aLength = 250) {
		super();
		this.#length = aLength;
	}

	get length() {
		return this.#length;
	}

	static get length() {
		return 250;
	}

	toString() {
		return `${super.toString()}(${this.#length})`;
	}
	static toString() {
		return `${super.toString()}(250)`;
	}
	static get typeName() {
		return "ANSISTRING";
	}
}
class TEXT extends StrDataType {
	static get typeName() {
		return "TEXT";
	}
}
class FLOAT extends FloatDataType {
	static get typeName() {
		return "FLOAT";
	}
}
class DECIMAL extends FloatDataType {
	static get typeName() {
		return "DECIMAL";
	}
}
class DATE extends DataTypeBase {
	static get typeName() {
		return "DATE";
	}

	static toApiRequest(aValue /*, aInfo*/) {
		if (!aValue) return null;
		const value = Date.parseDateTime(aValue);
		return (!isNaN(value) && value.toISOString()) || null;
	}
	static toApiResponse(aValue /*, aInfo*/) {
		if (!aValue) return null;
		const value = Date.parseDateTime(aValue);
		return (!isNaN(value) && value.toISOString()) || null;
	}
	static fromApiRequest(aValue /*, aInfo*/) {
		if (!aValue) return null;
		const value = Date.parseDateTime(aValue);
		return isNaN(value) ? null : value;
	}
	static fromApiResponse(aValue /*, aInfo*/) {
		if (!aValue) return null;
		const value = Date.parseDateTime(aValue);
		return isNaN(value) ? null : value;
	}
	static toSQLValue(aValue) {
		if (!aValue) return null;
		const value = Date.parseDateTime(aValue);
		return (!isNaN(value) && `'${value.toISOString()}'`) || null;
	}
}
class TIME extends DataTypeBase {
	static get typeName() {
		return "TIME";
	}
	static toApiRequest(aValue /*, aInfo*/) {
		if (!aValue) return null;
		const value = Date.parseTime(aValue);
		return (!isNaN(value) && value.toISOString()) || null;
	}
	static toApiResponse(aValue /*, aInfo*/) {
		if (!aValue) return null;
		const value = Date.parseTime(aValue);
		return (!isNaN(value) && value.toISOString()) || null;
	}
	static fromApiRequest(aValue /*, aInfo*/) {
		if (!aValue) return null;
		const value = Date.parseTime(aValue);
		return isNaN(value) ? null : value;
	}
	static fromApiResponse(aValue /*, aInfo*/) {
		if (!aValue) return null;
		const value = Date.parseTime(aValue);
		return isNaN(value) ? null : value;
	}
	static toSQLValue(aValue) {
		if (!aValue) return null;
		const value = Date.parseTime(aValue);
		return (!isNaN(value) && `'${value.toISOString()}'`) || null;
	}
}
class DATETIME extends DATE {
	static get typeName() {
		return "DATETIME";
	}
}

class GUID extends DataTypeBase {
	static get typeName() {
		return "GUID";
	}
}
class BLOB extends DataTypeBase {
	static get typeName() {
		return "BLOB";
	}
}
export class ENUM extends DataTypeBase {
	#elements;
	constructor(aElements) {
		super();
		this.#elements = aElements;
	}

	toSQLValue(aValue) {
		if (aValue === null) {
			return null;
		}
		if (typeof aValue === "string") {
			let index = +aValue;
			if (Number.isInteger(index)) return index;
			index = this.#elements.find((element) => element === aValue);
			return index;
		}
		return aValue;
	}

	fromApiRequest(aValue) {
		if (aValue === null) {
			return null;
		}
		return aValue === undefined ? null : Number.parseInt(aValue);
	}
	get asList() {
		const result = [];
		for (let i = 0; i < this.#elements.length; i++) {
			const value = this.#elements[i];
			if (typeof value === "string") {
				result.push({ index: i, value, title: `enums.${value}` });
			} else if (typeof value === "object") {
				if (!value.index) {
					value.index = i;
				}
				if (!value.title) {
					value.title = `enums.${value.name || value.value}`;
				}
				result.push(value);
			} else {
				result.push(value);
			}
		}
		return result;
	}

	static get typeName() {
		return "ENUM";
	}
}

export class SET extends DataTypeBase {
	//#region allSetInformation
	static SET_MAXBIT = 30;
	static SET_TYPES = { byte: 1 << 7, word: 1 << 15, integer: (1 << 31) >>> 0 };
	static SET_VALUES = (() => {
		const result = {};
		let value = 1;
		for (let i = 0; i <= this.SET_MAXBIT; i++) {
			result[value] = i;
			value = (value << 1) >>> 0;
		}
		return result;
	})();
	//#endregion allSetInformation
	//#region Constructor
	#elements;
	#fieldsElements;
	constructor(aElements, ...args) {
		super();
		let value = 1;
		let max_value = 0;
		const elements = {};
		const fieldsElements = {};
		const utilsIn = this.constructor;
		for (let src of aElements) {
			if (!src) {
				console.warn("SET Element is undefinded!..");
				continue;
			}
			let element;
			switch (typeof src) {
				case "string":
					element = { fieldName: src };
					break;
				case "object":
					if (src instanceof String) {
						element = { fieldName: src };
					} else element = src;
					break;
				default:
					throw new Error(`SET element expected type string or object but get '${typeof src}'!..`);
			}
			if (!element.fieldName) {
				throw new Error(`SET element fieldName is required!..`);
			}
			if (!element.value) element.value = value;
			else if (utilsIn.SET_VALUES[element.value] === undefined) {
				throw new Error(`SET element value ${element.value} is unaccepted!..`);
			}
			const eleValue = element.value;
			if (elements[eleValue]) {
				throw new Error(`SET element value ${eleValue} is repeated!..`);
			}
			const name = element.fieldName;
			if (fieldsElements[name] !== undefined) {
				throw new Error(`SET element fieldName ${name} is repeated!..`);
			}
			if (!element.title) element.title = `fields.${element.fieldName}`;
			max_value = eleValue > max_value ? eleValue : max_value;
			elements[eleValue] = element;
			fieldsElements[name] = element;
			value = (value << 1) >>> 0;
		}
		let size;
		if (max_value <= utilsIn.SET_TYPES.integer) {
			if (max_value <= utilsIn.SET_TYPES.byte) size = 1;
			else if (max_value <= utilsIn.SET_TYPES.word) size = 2;
			else size = 4;
		} //else if (max_value <= utilsIn.SET_TYPES.bigint) size = 8;
		else throw new Error(`SET element with value '${max_value}' was not treated yet!..`);
		this.size = size;
		this.maxValue = max_value;
		this.#elements = elements;
		this.#fieldsElements = fieldsElements;
		if (typeof args[0] === "function") {
			this.onChanged = args[0];
		}
	}
	//#endregion Constructor
	//#region Management
	elementOf(aValue, aError) {
		const bitValue = Number.parseInt(aValue) || 0;
		let result;
		if (!bitValue && (typeof aValue === "string" || aValue instanceof String)) {
			result = this.#fieldsElements[aValue];
		} else result = this.#elements[bitValue];
		if (!result && aError) {
			throw new Error(`SET element value ${aValue} dosn't exsits, can not getElement!...`);
		}
		return result;
	}
	doChanged(aOld, aNew, aAction, aElements) {
		if (this.changedLock || Object.is(aOld, aNew) || typeof this.onChanged !== "function") return aNew;
		this.changedLock = true;
		let result;
		try {
			result = this.onChanged(aNew, aOld, aAction, aElements);
		} finally {
			this.changedLock = false;
		}
		if (result !== undefined) return result;
		else return aNew;
	}
	valueOrEmpty(aValue) {
		return Number.parseInt(aValue) || 0;
	}
	include(aSet, aValue) {
		let result = this.valueOrEmpty(aSet);
		const doInclude = (aValue) => {
			const element = this.elementOf(aValue, true);
			result = (result | element.value) >>> 0;
			return element;
		};
		let elements;
		if (!Array.isArray(aValue)) elements = doInclude(aValue);
		else elements = aValue.map(doInclude);
		return this.doChanged(aSet, result, "include", elements);
	}
	exclude(aSet, aValue) {
		let result = this.valueOrEmpty(aSet);
		let elements;
		const doExclude = (aValue) => {
			const element = this.elementOf(aValue, true);
			result = (result & ~element.value) >>> 0;
			return element;
		};
		if (!Array.isArray(aValue)) elements = doExclude(aValue);
		else elements = aValue.map(doExclude);
		return this.doChanged(aSet, result, "exclude", elements);
	}
	resetValue(aSet, aBit, aValue) {
		return aValue ? this.include(aSet, aBit) : this.exclude(aSet, aBit);
	}
	isIncluded(aSet, aValue) {
		if (!aSet) return false;
		const element = this.elementOf(aValue, false);
		if (!element) return false;
		const result = this.valueOrEmpty(aSet) & element.value;
		return result === element.value;
	}
	forEachElement(aCallback) {
		for (let element of Object.values(this.#elements)) {
			aCallback(element);
		}
	}
	forEachIncluded(aSet, aCallback) {
		const set = this.valueOrEmpty(aSet);
		if (!set) return;
		for (let element of Object.values(this.#elements)) {
			if (set & element.value) {
				aCallback(element);
			}
		}
	}
	//#endregion Management
	//#region ApiManagement
	toApiRequest(aValue /*, aInfo*/) {
		const result = this.valueOrEmpty(aValue);
		return result ? result : null;
	}
	toApiResponse(aValue /*, aInfo*/) {
		const result = this.valueOrEmpty(aValue);
		return result ? result : null;
	}
	fromApiRequest(aValue /*, aInfo*/) {
		if (aValue === null) return null;
		return aValue === undefined ? null : this.valueOrEmpty(aValue);
	}
	fromApiResponse(aValue /*, aInfo*/) {
		const result = this.valueOrEmpty(aValue);
		return result ? result : null;
	}
	//#endregion ApiManagement
	//#region DbManagement
	toSQLValue(aValue) {
		const result = this.valueOrEmpty(aValue);
		return result ? result : null;
	}
	//#endregion DbManagement
	//#region Information
	static get typeName() {
		return "SET";
	}
	//#endregion Information
}

class LANG extends DataTypeBase {
	#length = 250;
	constructor(aLength = 250) {
		super();
		this.#length = aLength;
	}

	get length() {
		return this.#length;
	}
	static get length() {
		return 250;
	}

	toString() {
		return `${super.toString()}(${this.#length})`;
	}
	static toString() {
		return `${super.toString()}(250)`;
	}

	static get typeName() {
		return "LANG";
	}
}

export const DataTypeClasses = {
	BOOLEAN,
	TINYINT,
	SMALLINT,
	INTEGER,
	BIGINT,
	CHAR,
	STRING,
	ANSISTRING,
	TEXT,
	FLOAT,
	DECIMAL,
	DATE,
	TIME,
	DATETIME,
	GUID,
	BLOB,
	ENUM,
	SET,
	LANG,
};

export const DataTypes = {
	BOOLEAN,
	TINYINT,
	SMALLINT,
	INTEGER,
	BIGINT,
	CHAR: CHAR.makeInvokable(),
	STRING: STRING.makeInvokable(),
	ANSISTRING: ANSISTRING.makeInvokable(),
	TEXT: TEXT.makeInvokable(),
	FLOAT,
	DECIMAL,
	DATE,
	TIME,
	DATETIME,
	GUID,
	BLOB,
	ENUM(aElements) {
		if (aElements === undefined || !Array.isArray(aElements)) {
			throw new Error("DataType ENUM elements required!.");
		}
		return new ENUM(aElements);
	},
	SET(aElements, ...args) {
		if (aElements === undefined || !Array.isArray(aElements)) {
			throw new Error("DataType SET elements required!.");
		}
		return new SET(aElements, ...args);
	},
	LANG: LANG.makeInvokable(),
};
export default DataTypes;
