import * as icon from "@mdi/js";
import Icon from "@mdi/react";

export default function MIcon({ style, className = "text-primary", size = 1, name, rotate, onClick }) {
	if (!name || !icon[`mdi${name}`]) name = "ClockOutline";
	return (
		<Icon
			path={icon[`mdi${name}`]}
			size={size}
			className={`${className ?? ""} ${onClick && "cursor-pointer"} m-icon`}
			rotate={rotate}
			onClick={onClick}
			style={style}
		/>
	);
}
