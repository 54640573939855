import React from "react";
import useTranslation from "@i18n";
import { useApplication } from "@/context/AppContext.jsx";

function buildMenu(aMenu, aTrans) {
	const menu = {
		items: undefined,
		groups: undefined,
		allItems: [],
		groupsID: Symbol(),
		fixRoute(aRoute) {
			if (!aRoute || (typeof aRoute !== "string" && aRoute instanceof String)) {
				return undefined;
			}
			let lang = aTrans.language.current;
			let result = aRoute;
			if (aRoute[0] === "/") lang = "/" + lang;
			if (aRoute.startsWith(lang)) {
				if (aRoute[lang.length] === "/") result = aRoute.substring(lang.length + 1);
				else result = aRoute.substring(lang.length);
			} else if (aRoute[0] === "/") {
				result = aRoute.substring(1);
			}
			return result;
		},
		keysOf(aRoute) {
			aRoute = this.fixRoute(aRoute);
			if (!aRoute) return;
			const result = [];
			if (!aRoute) return result;
			let workIn = this.items;
			for (let path of aRoute.split("/")) {
				const item = workIn?.find((i) => String(i.path) === path);
				if (!item) return undefined;
				result.push(item.key);
				workIn = item.children;
			}
			return result;
		},
		itemsOf(aRoute) {
			aRoute = this.fixRoute(aRoute);
			if (!aRoute) return;
			const result = [];
			if (!aRoute) return result;
			let workIn = this.items;
			for (let path of aRoute.split("/")) {
				const item = workIn?.find((i) => String(i.path) === path);
				if (!item) return undefined;
				result.push(item);
				workIn = item.children;
			}
			return result;
		},
		get locationKeys() {
			return this.keysOf(window.location.pathname);
		},
		get locationItems() {
			return this.itemsOf(window.location.pathname);
		},
	};
	if (!aMenu) {
		menu.items = [];
		menu.groups = [];
		return menu;
	}
	const itemsByID = {};
	const baseSymbol = Symbol();
	const loc_LoopItems = (aSrcItems, aParent, useSymbol) => {
		const result = [];
		for (let source of aSrcItems) {
			if (!source.keepLink && !source.hasEditor) continue;
			const key = source.key;
			const itemInBase = itemsByID[key];
			if (itemInBase) {
				if (!useSymbol) {
					throw new Error(`Key ${source.key} is already exists in MainMenu!.`);
				}
				result.push(itemInBase);
				continue;
			}
			const item = {
				key,
				path: source.path,
				label: source.title(aTrans),
				get keys() {
					const parent = this[baseSymbol];
					return parent ? [...parent.keys, this.key] : [this.key];
				},
				get level() {
					const parent = this[baseSymbol];
					return parent ? parent.level + 1 : 0;
				},
				get parent() {
					return this[baseSymbol];
				},
				keysBy(aSymbol) {
					const parent = this.parentBy(aSymbol);
					return parent ? [...parent.keys, this.key] : [this.key];
				},
				levelBy(aSymbol) {
					const parent = this.parentBy(aSymbol);
					return parent ? parent.level + 1 : 0;
				},
				parentBy(aSymbol) {
					return (aSymbol && this[aSymbol]) || this[baseSymbol];
				},
			};
			itemsByID[key] = item;
			if (aParent) item[useSymbol || baseSymbol] = aParent;
			if (source.icon) item.icon = source.icon;
			if (source.children) {
				const childsIn = source.childsIn;
				if (childsIn) {
					if (!useSymbol) {
						throw new Error(`can not link childs in MainMenu!..`);
					}
					const src = itemsByID[childsIn];
					if (!src) {
						throw new Error(`can not get childs for item ${childsIn}!..`);
					}
					item.children = src.children;
					item.children?.forEach((child) => (child[useSymbol] = item));
				} else item.children = loc_LoopItems(source.children, item, useSymbol);
			}
			if (source.hasEditor && source.kind !== "typed") {
				item.route = source.route;
				if (!useSymbol) menu.allItems.push(item);
			}
			result.push(item);
		}
		return result;
	};
	menu.items = loc_LoopItems(aMenu.items);
	menu.groups = loc_LoopItems(aMenu.groups, undefined, menu.groupsID);
	return menu;
}

// const mainContext = MApplication.mainContext;
// const subscribe = (...args) => mainContext.onMenuChanged(...args);
// const getSnapshot = () => mainContext.menu;

export function useMenu() {
	const application = useApplication();
	const trans = useTranslation();
	const menu = React.useMemo(() => buildMenu(application.mainContext.menu, trans), [application, trans]);
	return menu;
}
export default useMenu;
