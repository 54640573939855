import React, { useEffect, useMemo } from "react";
import { uniqueID } from "@sys:utils";
export const MSCheckbox = React.forwardRef(
	(
		{ onChange, label, suffix, checked = false, helperText, value, status, name, error, warning, isDisabled, ...props },
		ref
	) => {
		if (!error) error = status?.error;
		if (!warning) warning = status?.warning;
		if (!isDisabled) isDisabled = status?.isDisabled;
		const [getter, setter] = React.useState(checked);
		function handle(event) {
			setter(!getter);
			onChange && onChange(event);
		}
		const classValidation = `${warning ? "warning" : ""} ${error ? "error" : ""}  ${isDisabled ? "disabled" : ""}`;
		const id = useMemo(() => uniqueID(), []);
		useEffect(() => {
			setter(checked);
		}, [checked]);
		return (
			<div className="wrper-checkbox flex flex-col ">
				<div className={"m-checkbox " + classValidation} tabIndex={0} {...props} ref={ref}>
					<input type="checkbox" id={id} onChange={handle} value={value} name={name} checked={getter} />
					<label htmlFor={id} className="label">
						{label}
					</label>
					<div>{suffix}</div>
				</div>
				{helperText && <div>{helperText}</div>}
			</div>
		);
	}
);

export default MSCheckbox;
