import React from "react";
import { useTranslation } from "@i18n";
import useItemStatus from "@compts:root/hooks/useItemStatus.js";
import MSNumber from "@compts:std/common/MSNumber.jsx";
import MLoader from "@compts:std/common/MLoader.jsx";

function LocalNumber({ item, size }) {
	const elementRef = React.useRef();
	const trans = useTranslation();
	const title = item?.title(trans);
	const [getter, setter] = React.useState(item?.value || "");
	const {
		status,
		validationIcon: suffix,
		validationText,
		showTextValidation,
	} = useItemStatus(item, elementRef.current?.input);

	React.useEffect(() => {
		const doValueChanged = (aNew, aOld, aParams) => {
			if (aParams?.byEditor === elementRef.current) {
				return;
			}
			setter(+aNew);
		};
		item?.onChangedAdd(doValueChanged);
		return () => {
			item?.onChangedRemove(doValueChanged);
		};
	}, [item]);

	const doChange = React.useCallback(
		(element) => {
			const value = +element.target.value;
			setter(value);
			item?.setValue(value, { byEditor: elementRef.current?.input });
		},
		[item]
	);
	return (
		<MSNumber
			ref={elementRef}
			size={size}
			suffix={suffix}
			status={status}
			value={getter}
			label={title}
			helperText={validationText}
			onBlur={() => showTextValidation(false)}
			onChange={doChange}
		/>
	);
}

export const MNumber = (props) => {
	const loader = async () => {
		const result = { ...props };
		result.item = await result.item;
		return result;
	};

	return props.item instanceof Promise ? (
		<MLoader component={LocalNumber} loader={loader} />
	) : (
		<LocalNumber {...props} />
	);
};
export default MNumber;
