import { forwardRef } from "react";
import MIcon from "@compts:std/MSIcon/MSIcon.jsx";
import MTypography from "@compts:std/MSTypography/MSTypography.jsx";

function LocalMSChip({ className, label = "chip", icon, clearable, onClear, ...props }, ref) {
	return (
		<div
			ref={ref}
			{...props}
			className={`flex items-center gap-1 border border-primary rounded rounded-deafult mt-[5px] px-[5px] bg-superLightPrimary ${
				className ?? ""
			}`}
		>
			{icon && icon}
			<MTypography className="text-[12px] ">
				{" "}
				<span className="text-primary">{label}</span>
			</MTypography>
			{clearable && <MIcon name={"Close"} onClick={onClear} size={0.7} className="text-primary" />}
		</div>
	);
}

const MSChip = forwardRef(LocalMSChip);

export default MSChip;
