import MPaper from "@compts:std/MSPaper/MSPaper.jsx";
import { forwardRef } from "react";

function LocalMDrawer({ open = true, className, reverse, children, ...props }, ref) {
	return (
		<div
			{...props}
			className={`m-drawer ${reverse ? "reversed" : ""} ${open && `open w-[270px]`}  ${className}`}
			ref={ref}
		>
			<MPaper className="m-inner-drawer !rounded-none m-0 relative w-full px-2 py-1 ">{children}</MPaper>
		</div>
	);
}

const MDrawer = forwardRef(LocalMDrawer);

export default MDrawer;
