import { forwardRef } from "react";

function LocalMTypography({ className, children, ...props }, ref) {
	return (
		<div {...props} ref={ref} className={`text-textPrimary font-Rubik m-typography text-base  ${className ?? ""} `}>
			{children}
		</div>
	);
}

const MSTypography = forwardRef(LocalMTypography);

export default MSTypography;
