import React from "react";
import { useTranslation } from "@i18n";
import MSTextarea from "@compts:std/common/MSTextarea";
import useItemStatus from "@compts:root/hooks/useItemStatus.js";
import MLoader from "@compts:std/common/MLoader.jsx";

function LocalTextArea({ item }) {
	const elementRef = React.useRef();
	const [getter, setter] = React.useState(item?.value || "");
	const { status, validationIcon: suffix, validationText, showTextValidation } = useItemStatus(item, elementRef);

	React.useEffect(() => {
		const doValueChanged = (aNew, aOld, aParams) => {
			if (aParams?.byEditor === elementRef.current) {
				return;
			}
			setter(aNew);
		};
		item?.onChangedAdd(doValueChanged);
		return () => {
			item?.onChangedRemove(doValueChanged);
		};
	}, [item]);
	const trans = useTranslation();
	const title = item?.title(trans);

	const doChange = (element) => {
		const value = element.target.value;
		setter(value);
		item?.setValue(value, { byEditor: elementRef.current });
	};

	return (
		<MSTextarea
			ref={elementRef}
			maxLength="20"
			label={title}
			suffix={suffix}
			status={status}
			value={getter}
			helperText={validationText}
			onBlur={() => showTextValidation(false)}
			handleOnChange={doChange}
		/>
	);
}

export const MTextArea = (props) => {
	const loader = async () => {
		const result = { ...props };
		result.item = await result.item;
		return result;
	};

	return props.item instanceof Promise ? (
		<MLoader component={LocalTextArea} loader={loader} />
	) : (
		<LocalTextArea {...props} />
	);
};
export default MTextArea;
