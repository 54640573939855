import React from "react";
import MBreadcrumbs from "./MBreadcrumbs.jsx";
import useMenu from "@/mainMenu/menu.js";
import MIcon from "@compts:std/MSIcon/MSIcon.jsx";

export default function MLocationBreadcrumbs({ separator = "/" }) {
	const { locationItems } = useMenu();

	const items = locationItems?.map((item) => ({
		label: item.showIcon ? <MIcon name={item.icon} className="text-disabled" /> : item.label,
		href: item.route,
	}));
	items?.unshift({ label: <MIcon name="Home" className="text-disabled" />, href: "/" });
	return (
		<div className="m-current-breadcrumb  flex items-center gap-2">
			<MBreadcrumbs items={items} separator={separator} />
		</div>
	);
}
