const STYLE_DEFAULTS = {
	layout: "Apollo",
	headerFixed: true,
	dark: false,
	mainColor: "primary",
	rounded: 2,
	sideBarColor: "#111827",
};

export function createStyle(aSource, aDefault, aChangedCallback) {
	const result = {};
	let onChanged = aChangedCallback;
	Object.defineProperty(result, "onChanged", {
		get: () => onChanged,
		set: (aValue) => (onChanged = aValue),
	});
	const loc_mapObject = (aSource, aDefault, aInitial, aDest, aPrefix) => {
		for (let [name, initialValue] of Object.entries(aInitial)) {
			const path = aPrefix + name;
			if (typeof initialValue === "object") {
				const dest = {};
				Object.defineProperty(aDest, name, { value: dest, enumerable: true });
				loc_mapObject(aSource?.[name], aDefault?.[name], initialValue, dest, path + ".");
				continue;
			}
			let value = aSource?.[name];
			if (value === undefined || value === null) {
				value = localStorage.getItem(path);
				if (value === "false") value = false;
				else if (value === "true") value = true;
			}
			value = value ?? aDefault?.[name] ?? initialValue;
			Object.defineProperty(aDest, name, {
				enumerable: true,
				get: () => value,
				set: (aValue) => {
					if (value !== aValue) {
						value = aValue;
						const htmlDocument = document.documentElement;
						htmlDocument.setAttribute(name, aValue);
						localStorage.setItem(path, aValue);
						if (typeof result.onChanged === "function") {
							result.onChanged(result);
						}
					}
				},
			});
		}
	};
	loc_mapObject(aSource, aDefault, STYLE_DEFAULTS, result, "MStyle.");
	return result;
}

export default createStyle;
