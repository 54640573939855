import { Children } from "react";
import { MSCol, MSRow } from ".";
import useWindowSize from "./useWindowResize";
import MSBox from "../MSBox/MSBox";

export function MSForm({ children, size, padding = "1", colPixcel = 300, disabledDefault = false }) {
	// function getSize(){
	// 	switch(size){
	// 		case 1:
	// 	}
	// }

	function DefaultMode({ children }) {
		const oChildren = Children.toArray(children);
		const rowCount = Math.ceil(oChildren.length / 40);
		const { width: innerWidth } = useWindowSize();
		// let colCount = Math.ceil(Math.ceil(oChildren.length / 10));
		// colCount = colCount > 4 ? 4 : colCount;
		const getColumnCount = () => {
			let maxColumn = Math.floor(innerWidth / colPixcel);
			maxColumn = maxColumn < 1 ? 1 : maxColumn;
			const currentCount = Math.ceil(oChildren.length / 10);

			if (maxColumn > currentCount) return currentCount;
			return maxColumn;
		};

		const getFormSize = () => {
			if (size !== undefined) return size;
			// const smallMode = innerWidth < 1200;
			// const mobileMode = innerWidth < 600;
			// if (smallMode) return 3;
			// if (mobileMode) {
			// if (colCount <= 1) return 2;
			// return 4;
			// }
			return colCount > 4 ? "4" : colCount;
		};

		const colCount = getColumnCount();
		const itemCount = Math.ceil(oChildren.length / colCount);
		const formSize = getFormSize();
		const dataToView = [];
		const buildGrid = () => {
			for (let rowIndex = 0; rowIndex < rowCount; rowIndex++) {
				dataToView[rowIndex] = [];

				for (let index = 0; index < colCount; index++) {
					dataToView[rowIndex].push(oChildren.splice(0, itemCount));
				}
			}
		};
		buildGrid();

		return (
			<div className={`flex flex-col p-${padding} overflow-visible s-${formSize}`}>
				{dataToView.map((row, i) => (
					<MSRow key={i} cols={colCount}>
						{row.map((column, i) => (
							<MSCol key={i}>{column.map((item) => item)}</MSCol>
						))}
					</MSRow>
				))}
			</div>
		);
	}
	function DevMode({ children }) {
		return <MSBox className={`flex flex-col  p-${padding} gap-2 w-full s-${size || 4}`}>{children}</MSBox>;
	}

	return disabledDefault ? <DevMode>{children}</DevMode> : <DefaultMode>{children}</DefaultMode>;
}

export default MSForm;
