import React from "react";
import UIApplication from "@core";
import routesOf from "./routes/routes.js";
import ThemeLayout from "@/styleViews/themes/themeLayout/ThemeLayout.jsx";
import { AppContext } from "./context/AppContext.jsx";
import "./sass/main/main.scss";
import "@mdi/font/css/materialdesignicons.min.css";

const App = () => {
	const [appContext, setAppContext] = React.useState(null);
	React.useEffect(() => {
		UIApplication(
			[
				// import("./apps/learning/Lms.Ui.Main.js"),
				import("./apps/security/Sec.ui.Main.js"),
				import("./apps/people/Ppl.ui.Main.js"),
				import("./apps/accounting/Acc.ui.Main.js"),
				import("./apps/system/Sys.ui.Main.js"),
				import("./apps/stocking/Stk.ui.Main.js"),
				import("./apps/humans/Hr.ui.Main.js"),
				import("./apps/transportation/Trn.ui.Main.js"),
			],
			document.baseURI
		).then((application) => {
			document.documentElement.setAttribute("mainColor", "indigo");
			document.documentElement.setAttribute("rounded", "2");
			const routes = routesOf(application);
			setAppContext(<AppContext application={application} layout={ThemeLayout} routes={routes} />);
		});
	}, []);
	if (appContext) {
		document.getElementById("styleIdLoader")?.remove();
		document.getElementById("appLoader")?.remove();
	}
	return appContext;
};

export default App;

