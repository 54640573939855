import React from "react";
import { useTranslation } from "@i18n";
import MSRadioGroup from "@compts:std/common/MSRadioGroup.jsx";
import useItemStatus from "@compts:root/hooks/useItemStatus.js";
import MLoader from "@compts:std/common/MLoader.jsx";

function LocalRadio({ item, row }) {
	const elementRef = React.useRef();
	const trans = useTranslation();
	const title = item?.title(trans);
	const [getter, setter] = React.useState(item?.value ?? "");
	const [options, setOptions] = React.useState([]);
	const {
		status,
		validationIcon: suffix,
		validationText,
		showTextValidation,
	} = useItemStatus(item, elementRef.current);

	React.useEffect(() => {
		const doValueChanged = (aNew, aOld, aParams) => {
			if (aParams?.byEditor === elementRef.current) {
				return;
			}
			setter(aNew);
		};
		item?.onChangedAdd(doValueChanged);
		return () => item?.onChangedRemove(doValueChanged);
	}, [item]);

	const selectValueChanged = React.useCallback(
		(e) => {
			const value = e.target.value;
			setter(value);
			item?.setValue(value, { byEditor: elementRef.current });
		},
		[item]
	);

	React.useEffect(() => {
		const loc_ValuesChanged = (aValues) => {
			const list = [];
			aValues?.forEach((...args) => list.push(aValues.valueToOption(trans, ...args)));
			setOptions(list);
		};

		item?.onValuesAdd(loc_ValuesChanged);
		return () => {
			item?.onValuesRemove(loc_ValuesChanged);
		};
	}, [item, trans, selectValueChanged]);

	return (
		<MSRadioGroup
			label={title}
			row={row}
			suffix={suffix}
			value={getter}
			status={status}
			ref={elementRef}
			items={options}
			helperText={validationText}
			onChange={(event) => {
				setter(event.target.value);
				item?.setValue(event.target.value, { byEditor: elementRef });
			}}
			onBlur={() => showTextValidation(false)}
		/>
	);
}

export const MRadio = (props) => {
	const loader = async () => {
		const result = { ...props };
		result.item = await result.item;
		return result;
	};

	return props.item instanceof Promise ? <MLoader component={LocalRadio} loader={loader} /> : <LocalRadio {...props} />;
};
export default MRadio;
