import React from "react";
import { Outlet } from "react-router-dom";
import useLanguage from "./i18n/i18n.jsx";
import createStyle from "./style/styleConfig.js";
import StyleProvider from "./style/StyleProvider.jsx";

export const MainContext = React.createContext(null);
export const useContext = () => React.useContext(MainContext);
export const useStyle = () => React.useContext(MainContext).style;
export const useApplication = () => React.useContext(MainContext).application;
export const useTranslation = () => React.useContext(MainContext).language;

const I18nRouter = React.lazy(() => import("./i18n/I18nRouter.jsx"));

export function AppContext({ application, styleConfig, layout, routes, children }) {
	const doStyleConfigChanged = (oldStyle) => setStyle(createStyle(oldStyle, styleConfig, doStyleConfigChanged));
	const [style, setStyle] = React.useState(createStyle(undefined, styleConfig, doStyleConfigChanged));

	const language = useLanguage({ routes, children });
	if (Array.isArray(routes)) {
		const Home = () => (
			<StyleProvider style={style} layout={layout}>
				{children}
				<Outlet />
			</StyleProvider>
		);
		routes = [{ path: "", name: "app", element: <Home />, children: routes }];
		return (
			<MainContext.Provider value={{ application, style, language }}>
				<React.Suspense>
					<I18nRouter
						routes={routes}
						languages={language.languages}
						current={language.language.current}
						default="ar"
						changeLanguage={language.changeLanguage}
					/>
				</React.Suspense>
			</MainContext.Provider>
		);
	}

	return (
		<MainContext.Provider value={{ style, language }}>
			<StyleProvider style={style} layout={layout}>
				{children}
			</StyleProvider>
		</MainContext.Provider>
	);
}
export default AppContext;
