export * from "../../shared/core/MMeta.js";
import { MRtvUtils, MRtv_EVENTS_TYPES_VALUES } from "@systypes";
import { initSetConfig } from "../../shared/core/management/MSetMngmt.js";
import { ENTITY_PROP_CLASS, FIELD_NAME_TYPEID } from "../../../constants/index.js";
import { CLASS_EXTENDER_TYPED } from "../../shared/core/MConsts.js";
import { MItem, MField, MFieldConfig, MPropertyLang, MPropertyCaption } from "../../shared/core/MMeta.js";

export default (Base) =>
	class UIMeta extends Base {
		//#region FieldsManagement
		addTypedField(aName, aDataType, aDeclaration) {
			const result = this.addTableColumn(aName, aDataType, aDeclaration);
			result.apiDisabled = aDeclaration.apiDisabled ?? true;
			return result;
		}
		addTableColumn(aName, aDataType, aDeclaration) {
			let result;
			const title = aDeclaration?.title || `fields.${aName}`;
			const type = aDataType.typeName;
			switch (type) {
				case "SET":
					result = new UISetConfig(aDataType, title, this, aName, aDeclaration);
					break;
				case "ENUM":
					result = new UIEnumConfig(aDataType, title);
					break;
				default:
					result = new UICLSTypedFieldConfig(aDataType, title);
					if (aName === "ID") result.smallName = "id";
			}
			if (Array.isArray(result)) {
				this.addField(aName, result[0]);
				const func = result[1];
				if (typeof func !== "function") {
					throw new Error("Initializer must be function!..");
				}
				func();
			} else this.addField(aName, result);
			return result;
		}
		//#endregion FieldsManagement
		//#region PropertiesManagement
		addCaption(aName, aFields) {
			const result = new UIPropertyCaption(aName, aFields);
			this.addProperty(aName, result);
			return result;
		}
		addLangProperty(aPrefix, aTitle) {
			const result = new UIPropertyLang(aPrefix, aTitle);
			this.addProperty(aPrefix, result);
			return result;
		}
		//#endregion PropertiesManagement
		//#region MRtvManagement
		createConfig() {
			const result = super.createConfig(...arguments);
			result.defaultItemClass = UIItem;
			result.translate = (...args) => this.translate(...args);
			return result;
		}
		//#endregion MRtvManagement
		//#region UIMethods
		translate(aTrans, aName) {
			const { exists: isExists, t } = aTrans.i18n;
			let meta = this;
			do {
				const resName = `${meta.app.appName}.${meta.name}.${aName}`;
				if (isExists(resName)) {
					return t(resName);
				}
				meta = meta.parent;
			} while (meta);
			const resName = `${this.app.appName}.${aName}`;
			if (isExists(resName)) {
				return t(resName);
			}
			return t(aName);
		}
		//#endregion UIMethods
		//#region ManagementClasses
		[CLASS_EXTENDER_TYPED](aBase, aTypeID) {
			const Base = super[CLASS_EXTENDER_TYPED](...arguments);
			const meta = this;
			const typeID = aTypeID;
			class UITyped extends Base {
				static get meta() {
					return meta;
				}
				static async apiGet(aSubApi, aParams) {
					if (!aParams) aParams = {};
					aParams[FIELD_NAME_TYPEID] = typeID;
					return super.apiGet(...arguments);
				}
				static apiPost(aSubApi, aData) {
					if (aData instanceof FormData) aData.append(FIELD_NAME_TYPEID, typeID);
					else aData[FIELD_NAME_TYPEID] = typeID;
					return super.apiPost(...arguments);
				}
			}
			return UITyped;
		}
		//#endregion ManagementClasses
	};

export class UIFieldConfig extends MFieldConfig {
	//#region FieldManagement
	get hasValues() {
		return this.valuesClass?.prototype instanceof UIFieldValues;
	}
	//#endregion FieldManagement
}

class UICLSTypedFieldConfig extends UIFieldConfig {
	//#region Constructor
	constructor(aType, aTitle) {
		super();
		this.datatype = aType;
		if (aTitle) this.title = aTitle;
	}
	//#endregion Constructor
	//#region information
	get fieldKind() {
		return this.datatype.typeName;
	}
	//#endregion information
	//#region MRtvManagement
	get fieldClass() {
		return UITypedField;
	}
	//#endregion MRtvManagement
}

export class UIDependsConfig extends UIFieldConfig {
	//#region Constructor
	#entity;
	#instanceClass;
	constructor(aEntity, ...args) {
		super(...args);
		this.#entity = aEntity;
	}
	//#endregion Constructor
	//#region ControllerVerification
	get instanceClass() {
		if (!this.#instanceClass) {
			return this.verifyInstanceClass();
		}
		return this.#instanceClass;
	}
	verifyInstanceClass() {
		if (this.#instanceClass) return this.#instanceClass;
		if (this.loadingPromise) return this.loadingPromise;
		const controller = this.#entity[ENTITY_PROP_CLASS];
		if (controller instanceof Promise) {
			this.loadingPromise = new Promise((resolve, reject) => {
				controller
					.then((cls) => {
						this.#instanceClass = cls;
						if (typeof this.instanceClassLoaded === "function") {
							this.instanceClassLoaded(cls);
						}
						resolve(cls);
					})
					.catch((e) => reject(e))
					.finally(() => delete this.loadingPromise);
			});
			return this.loadingPromise;
		} else if (typeof this.instanceClassLoaded === "function") {
			this.instanceClassLoaded(controller);
		}
		this.#instanceClass = controller;
		return controller;
	}
	//#endregion ControllerVerification
}

class UIPropertyLang extends MPropertyLang {
	//#region Constructor
	constructor(aPrefix, aTitle) {
		super(aPrefix);
		if (aTitle) this.title = aTitle;
	}
	//#endregion Constructor
}

class UIPropertyCaption extends MPropertyCaption {
	//#region Constructor
	constructor(aName, aFields, aTitle) {
		super(aName, aFields);
		this.title = aTitle || "fields." + aName;
	}
	//#endregion Constructor
}

export class UIItem extends MItem {
	//#region UIManagement
	#trans;
	#title;
	title(aTranslation) {
		if (this.#trans === aTranslation) {
			return this.#title;
		}
		const result = this.constructor.titleOf_cls(this.pathObject, aTranslation);
		this.#trans = aTranslation;
		this.#title = result;
		return result;
	}
	static translate_cls(aPath, aTranslation, aResName) {
		let result = "";
		let translator;
		const loc_translate = (resource) => {
			if (translator) {
				const title = translator.translate(aTranslation, resource);
				result += result ? `.${title}` : title;
			} else console.error(`can not translate title for ${resource} ... no translator.`);
		};
		let prev_title;
		if (typeof aPath.rootConfig.translate === "function") {
			translator = aPath.rootConfig;
		}
		MRtvUtils.enumPaths(aPath, (aKey, aConfig) => {
			if (prev_title) loc_translate(prev_title);
			if (typeof aConfig.translate === "function") translator = aConfig;
			prev_title = aConfig.title;
			return true;
		});
		loc_translate(aResName);
		return result;
	}

	static titleOf_cls(aPath, aTranslation) {
		let result = "";
		let translator;
		const mapKeys = (aPath) => {
			const save_translator = translator;
			if (typeof aPath.rootConfig.translate === "function") {
				translator = aPath.rootConfig;
			}
			for (let i = 0; i < aPath.keys.length; i++) {
				const config = aPath.keysConfigs[i];
				if (typeof config.translate === "function") translator = config;
				if (translator) {
					const resName = config.title;
					if (resName) {
						const title = translator.translate(aTranslation, resName);
						result += result ? `.${title}` : title;
					} else if (config.isAlias) mapKeys(config);
				} else if (config.title) {
					console.error(`can not translate title for ${config.title} ... no translator.`);
				}
			}
			translator = save_translator;
		};
		mapKeys(aPath);
		return result;
	}
	//#endregion UIManagement
	//#region ValuesManagement
	onValuesAdd(aCallback, atAll, ...args) {
		//...args = aCallby, aPayload
		const field = this.finalField;
		if (field && !field.config.hasValues) {
			throw new Error(`item ${this.pathName} don't has values!..`);
		}
		const result = super.eventsModification(true, MRtv_EVENTS_TYPES_VALUES, aCallback, atAll, ...args);
		const applier = field?.values?.applyAt;
		if (typeof applier === "function") {
			applier.call(field.values, aCallback, this, ...args);
		}
		return result;
	}
	onValuesRemove(aCallback, ...args) {
		return super.eventsModification(false, MRtv_EVENTS_TYPES_VALUES, aCallback, ...args);
	}
	//#endregion ValuesManagement
	//#region deprecatedMethods
	get owner() {
		console.warn("method 'item.owner' is deprecated!..");
		return this.finalField?.instance;
	}
	get config() {
		console.warn("method 'item.config' is deprecated, please use finalConfig!..");
		return this.finalConfig;
	}
	onChangeAdd(...args) {
		console.warn("method 'item.onChangeAdd' is deprecated, please use onChangedAdd method!..");
		return super.onChangedAdd(...args);
	}
	onChangeRemove(...args) {
		console.warn("method 'item.onChangeRemove' is deprecated, please use onChangedRemove method!..");
		return super.onChangedRemove(...args);
	}
	//#endregion deprecatedMethods
}

export class UIField extends MField {
	//#region ValuesManagement
	#values;
	get values() {
		if (this.#values) return this.#values;
		const cls = this.config.valuesClass;
		if (!cls) {
			throw new Error(`field ${this.name} don't has values!..`);
		}
		this.#values = new cls(this);
		return this.#values;
	}
	get valuesDirect() {
		return this.#values;
	}
	//#endregion ValuesManagement
	//#region Translation
	#fieldTitle;
	get fieldTitle() {
		if (!this.#fieldTitle) {
			this.#fieldTitle = (aTrans) => this.instance.translate(aTrans, this.config.title || "fields." + this.name);
		}
		return this.#fieldTitle;
	}
	//#endregion Translation
}

class UITypedField extends UIField {
	//#region ApiManagement
	toApiRequest(aRequest) {
		if (this.config.apiDisabled) return;
		const value = super.value;
		if ((value ?? undefined) === undefined) return;
		aRequest.append(this.name, this.config.datatype.toApiRequest(value));
	}
	fromApiResponse(aValue, aResponse) {
		if (this.config.apiDisabled) return;
		super.setValue(this.config.datatype.fromApiResponse(aValue), aResponse);
	}
	//#endregion ApiManagement
}

export class UIFieldValues {
	//#region Constructor
	constructor(aField) {
		this.field = aField;
		this.forceReload();
	}
	//#endregion Constructor
	//#region Information
	get name() {
		return this.field.name;
	}
	get config() {
		return this.field.config;
	}
	//#endregion Information
	//#region Management
	#values;
	reload() {
		if (this.reloadTimer) clearTimeout(this.reloadTimer);
		this.reloadTimer = setTimeout(() => {
			this.forceReload();
			delete this.reloadTimer;
		}, 300);
	}
	forceReload() {
		let promise;
		const loader = async () => {
			let values;
			try {
				values = await this.reloadValues();
				if (this.loadingPromise === promise) {
					this.setValues(values);
				}
			} finally {
				if (this.loadingPromise === promise) {
					delete this.loadingPromise;
				}
			}
			return values;
		};
		promise = loader();
		this.loadingPromise = promise;
		return loader;
	}
	setValues(aValues) {
		const field = this.field;
		const name = field.name;
		const config = field.config;
		const root = field.verifyInstance().root;
		const old = this.#values ? { old: this.#values, new: aValues } : undefined;
		this.#values = aValues;
		const params = {};
		root.eventEmit(MRtv_EVENTS_TYPES_VALUES, name, config, this, old, params);
		return old;
	}
	valueToKeyValue(aValue) {
		return [aValue[this.titleName], aValue[this.idName]];
	}
	valueToOption(aTranslation, aValue) {
		const array = this.valueToKeyValue(aValue);
		return { label: array[0], value: array[1] };
	}
	toSelectOptions(aTranslation) {
		if (!this.#values) return [];
		const result = this.#values.map((option) => this.valueToOption(aTranslation, option));
		return result;
	}
	forEach(aCallback) {
		this.#values?.forEach(aCallback);
	}
	applyAt(aCallback, aItem, aCallby, aPayload) {
		if (this.#values) {
			const params = MRtvUtils.initateEventsParams();
			params.keys = aItem.keys;
			const instance = aCallby || this.field.instance;
			if (aPayload) params.payload = aPayload;
			aCallback.call(instance, this, undefined, params);
		}
	}
	//#endregion Management
}

export class UIEnumConfig extends UICLSTypedFieldConfig {
	//#region FieldManagement
	get valuesClass() {
		return UIEnumValues;
	}
	//#endregion FieldManagement
}

class UISetItem extends UIItem {
	subItems() {
		const config = this.finalConfig?.datatype;
		if (config?.typeName !== "SET") {
			throw new Error(`SetItem expected config is set but get ${config}!..`);
		}
		const result = [];
		config.forEachElement((element) => result.push(UIItem.nestedOf_cls(this, element.fieldName)));
		return result;
	}
}

class UISetConfig extends UICLSTypedFieldConfig {
	//#region Constructor
	constructor(aSet, aTitle, aMeta, aName, aDeclaration) {
		super(aSet, aTitle);
		return [this, () => initSetConfig(this, aSet, aMeta, "", aName, aDeclaration)];
	}
	//#endregion Constructor
	//#region ItemManagement
	get itemClass() {
		return UISetItem;
	}
	//#endregion ItemManagement
}

class UIEnumValues extends UIFieldValues {
	//#region Constructor
	constructor(...args) {
		super(...args);
		this.idName = "index";
		this.titleName = "title";
	}
	//#endregion Constructor
	//#region Loading
	reloadValues() {
		return this.config.datatype.asList;
	}
	//#endregion Loading;
	//#region Format
	valueToOption(aTranslation, aValue) {
		if (!aValue) {
			return null;
		}
		const controller = this.field.instance;
		return {
			value: aValue.index,
			label: controller.translate(aTranslation, aValue.title),
		};
	}
	//#endregion Format
	//#region Information
	get needTranslation() {
		return true;
	}
	//#endregion Information
}
