import CaList from "@/views/CaList/index.js";
import { ENTITY_PROP_CLASS } from "../../../constants/index.js";
const getter = (application) => {
	const routesParams = {
		typeID: "$typeID",
		toFields(aParams) {
			let result = {};
			let hasParams = false;
			for (let [param, field] of Object.entries(this)) {
				const value = aParams[param];
				if (value) {
					result[field] = value;
					hasParams = true;
				}
			}
			return hasParams ? result : undefined;
		},
	};
	const loadElement = async (aItem, { params }) => {
		const controller = await aItem[ENTITY_PROP_CLASS];
		if (aItem.editor && !aItem.editorLoaded) {
			aItem.editor = (await aItem.editor()).default;
			aItem.editorLoaded = true;
		}
		await controller.prepare_cls();
		const fields = routesParams.toFields(params);
		const Class = controller.meta.mngmtClass(fields);
		if (Class !== controller) {
			const result = { ...aItem };
			result[ENTITY_PROP_CLASS] = Class;
			return result;
		}
		return aItem;
	};

	const appsRoutes = [];
	const buildAppRoutes = (app) => {
		const loc_AddRoutes = (aParent, decleration) => {
			for (let item of Object.values(decleration)) {
				const route = {
					name: item.name,
					path: item.path || item.subApi || item.name,
				};
				if (item.types) route.path += `/:typeID`;
				if (item.items) {
					if (item.uiElement) {
						route.element = item.uiElement;
					}
					route.children = [];
					loc_AddRoutes(route, item.items);
					aParent.children.push(route);
				} else {
					if (item.uiEditor) {
						route.lazy = async () => {
							const editor = await item.uiEditor();
							const loader = !editor.loader
								? loadElement.bind(undefined, item)
								: async (...args) => {
										const res = await loadElement(item, ...args);
										return await editor.loader(...args, res);
								  };
							return { Component: editor.default, loader };
						};
						aParent.children.push(route);
					} else if (item.editor) {
						route.loader = (request) => loadElement.bind(undefined, item, request);
						route.element = <CaList />;
						aParent.children.push(route);
					}
				}
			}
		};
		const appRoutes = {
			name: app.appName,
			path: app.appName,
			children: [],
		};
		loc_AddRoutes(appRoutes, app.groups);
		if (app.staticPages) {
			appRoutes.children = [...appRoutes.children, ...app.staticPages];
		}
		return appRoutes;
	};
	for (let app of Object.values(application.apps)) {
		appsRoutes.push(buildAppRoutes(app));
	}

	return appsRoutes;
};

export default getter;
