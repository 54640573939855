import MSBox from "@compts:std/MSBox/MSBox.jsx";
import MSTypography from "@compts:std/MSTypography/MSTypography.jsx";
import { Children } from "react";

export function MSRow({
	children,
	label,
	cols,
	className,
	alignTitle = "left",
	padding = 0,
	innerPadding = 1,
	flex = false,
	gap = "2",
}) {
	const colCount = cols !== undefined ? cols : Children.count(children);
	return (
		<MSBox
			className={`flex ${className ?? ""} rounded-default flex-col pt-1 p-${padding} ${label ? "bg-divider" : ""}`}
		>
			{label && <MSTypography style={{ textAlign: alignTitle }}>{label}</MSTypography>}
			<div
				className={` w-full items-start ${
					flex ? "flex items-center" : `grid gap-${gap} grid-cols-${colCount}`
				} py-${innerPadding} `}
			>
				{children}
			</div>
		</MSBox>
	);
}

export default MSRow;
