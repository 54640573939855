import axios from "axios";
import UIMainContext from "./UIContext.js";
import uiBase from "./baseClasses/uiBase.js";
import uiBasic from "./baseClasses/uiBasic.js";
import uiCrudBase from "./baseClasses/uiCrudBase.js";
import uiBuilderEngine from "./builders/uiBuilderEngine.js";
import MCrudBase from "../../shared/core/baseClasses/MCrudBase.js";
import { FIELD_NAME_API_HANDLER } from "../../../constants/index.js";
import {
	CLASS_SYMBOL_SYSTEMCLASS,
	APPLICATION_SYMBOL_START,
	APPLICATION_SYMBOL_BASE_CLASSES,
} from "../../shared/core/MConsts.js";

export default class UIApplication {
	//#region Constructor
	constructor(aApps, aBaseURI) {
		this.#api = axios.create({
			baseURL: aBaseURI,
		});
		const BaseUI = uiBase(this, this.#api);
		const baseClasses = {
			Basic: MCrudBase(uiBasic(BaseUI), this),
			CrudBase: MCrudBase(uiCrudBase(BaseUI), this),
		};
		Object.freeze(baseClasses);
		Object.defineProperty(this, APPLICATION_SYMBOL_BASE_CLASSES, { value: baseClasses });
	}
	//#endregion Constructor
	//#region Information
	#api;
	get [FIELD_NAME_API_HANDLER]() {
		return this.#api;
	}
	get isUI() {
		return true;
	}
	static [CLASS_SYMBOL_SYSTEMCLASS] = {
		builder: [uiBuilderEngine],
	};
	//#endregion Information

	async [APPLICATION_SYMBOL_START]() {
		const context = new UIMainContext(this);
		await context.prepare();
		this.mainContext = context;
		return this;
	}
}
