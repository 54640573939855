import React, {
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import MSButton from "../MSButton/MSButton";
import { useStyleSettings } from "@/styleViews/layouts/common/styleSettings";
import MSPaper from "@compts:std/MSPaper/MSPaper.jsx";
import { createPortal } from "react-dom";
import MTypography from "../MSTypography/MSTypography";
import MIcon from "@compts:std/MSIcon/MSIcon.jsx";
import useTranslation from "@i18n";
import { MLoadingIcon } from "@compts:misc";

function LocalModal(
	{
		open: aOpen,
		onClose,
		onOk,
		warning,
		ok = "buttons.ok",
		className,
		loadingProcess,
		children: aChildren,
		title,
		container,
		moreOne = false,
	},
	ref
) {
	const { setState } = useStyleSettings();
	const [open, setOpen] = useState(aOpen);
	const [position, setPosition] = useState({ top: null, left: null });
	const [children, setChildren] = useState(aChildren);
	const trans = useTranslation();
	const [loading, setLoading] = useState(false);
	const modalRef = useRef(null);
	const payload = useMemo(() => ({}), []);

	const handleOpen = useCallback(
		(value) => {
			setOpen(value);
			if (!moreOne) {
				setState["modalMood"](value);
			}
		},
		[setState, moreOne]
	);

	const doOkClicked = (e) => {
		const close = !onOk || onOk(e, payload.payload);
		if (close instanceof Promise) {
			close.then((doClose) => doClose !== false && handleOpen(false));
		} else if (close !== false) {
			handleOpen(false);
		}
	};

	useImperativeHandle(ref, () => ({
		open: async (aPayload, loader) => {
			handleOpen(true);
			payload.payload = aPayload;
			if (loader) {
				setLoading(true);
				await loader();
				setLoading(false);
			}
		},
		close: () => {
			handleOpen(false);
		},
		children: (value) => setChildren(value),
	}));

	useEffect(() => {
		setChildren(aChildren);
	}, [aChildren]);

	useEffect(() => {
		if (open) {
			const handleKeyDown = (event) => {
				if (event.key === "Escape") {
					handleOpen(false);
					onClose && onClose(event);
				}
			};
			window.addEventListener("keydown", handleKeyDown);

			return () => {
				window.removeEventListener("keydown", handleKeyDown);
			};
		}
	}, [open, onClose, handleOpen]);

	useImperativeHandle(modalRef, () => ({
		changePosition: (newPosition) => setPosition(newPosition),
	}));

	const handleMouseDown = useCallback(
		(e, position) => {
			const offset = {
				x: e.clientX,
				y: e.clientY,
			};
			const maxWidth =
				(container || document.body).getBoundingClientRect().width -
				innerModalRef.current.getBoundingClientRect().width;
			const maxHeight =
				(container || document.body).getBoundingClientRect().height -
				innerModalRef.current.getBoundingClientRect().height;

			const handleMouseMove = (e) => {
				let perTop = position.top;
				let perLeft = position.left;
				let resultX = e.clientX - offset.x + perLeft;
				const resultY = e.clientY - offset.y + perTop;
				if (resultY > 0 && resultX > 0 && resultX < maxWidth && resultY < maxHeight)
					setPosition({ top: resultY, left: resultX });
			};

			const handleMouseUp = () => {
				window.removeEventListener("mousemove", handleMouseMove);
				window.removeEventListener("mouseup", handleMouseUp);
			};
			window.addEventListener("mousemove", handleMouseMove);
			window.addEventListener("mouseup", handleMouseUp);
		},
		[container]
	);

	const innerModalRef = useRef(null);

	useLayoutEffect(() => {
		function getTopLeftValue() {
			const modalElement = innerModalRef.current;
			if (open && modalElement && children) {
				const containerElement = container || document.body;
				const containerRect = containerElement.getBoundingClientRect();
				const modalRect = modalElement.getBoundingClientRect();

				const centerPosition = {
					top: (containerRect.height - modalRect.height) / 2,
					left: (containerRect.width - modalRect.width) / 2,
				};
				return centerPosition;
			}
			return null;
		}
		const result = getTopLeftValue();
		result && setPosition({ ...result });
	}, [open, container, children]);

	return (
		open &&
		createPortal(
			<div className={`m-modal ${loading ? "loading" : ""}`}>
				<div
					style={{
						position: "absolute",
						top: `${position.top}px`,
						left: `${position.left}px`,
						opacity: !position.top || !position.left ? 0 : 1,
					}}
					ref={innerModalRef}
					className="m-inner-modal"
				>
					<MSPaper className={`${className || ""}`}>
						<div className="modal-header " onMouseDown={(e) => handleMouseDown(e, position)}>
							<MTypography className="w-fit" onMouseDown={(e) => e.stopPropagation()}>
								{title}
							</MTypography>
							<MSButton
								variant="text"
								type="circle"
								onMouseDown={(e) => {
									onClose && onClose(e);
									handleOpen(false);
								}}
								icon={<MIcon name="Close" className="text-disabled close-icon" size={0.8} />}
							/>
						</div>

						<div className="modal-body">
							<MLoadingIcon type="circle" />
							{children}
						</div>
						<div className="flex items-center gap-1 pt-4 justify-end modal-actions">
							<MSButton
								variant="text"
								className="!text-textSecondary cancel"
								onClick={(e) => {
									onClose && onClose(e);
									handleOpen(false);
								}}
								label={trans.t("buttons.cancel")}
							/>

							<MSButton
								loading={loadingProcess}
								className="ok"
								onClick={doOkClicked}
								label={warning ? trans.t("buttons.ignoreAll") : trans.t(ok)}
							/>
						</div>
					</MSPaper>
				</div>
			</div>,
			container || document.body
		)
	);
}

export default forwardRef(LocalModal);
