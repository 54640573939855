import React, { useState, useRef, useEffect, forwardRef, useMemo, useImperativeHandle } from "react";
import MIcon from "@compts:std/MSIcon/MSIcon.jsx";
import MSChip from "@compts:std/MSChip/MSChip.jsx";
import { MList, MMenu } from "@compts:misc";
import MSInput from "./MSInput.jsx";

export const MSSelect = forwardRef(
	(
		{
			options,
			onChange,
			multiable = false,
			clearable = true,
			maxCount,
			prefix,
			suffix,
			onClear,
			helperText,
			label = "select",
			status,
			error,
			className,
			value,
			onFocus,
			onBlur,
			warning,
			isReadOnly,
			isDisabled,
			size,
			...props
		},
		ref
	) => {
		if (!error) error = status?.errors;
		if (!warning) warning = status?.warnings;
		if (!isReadOnly) isReadOnly = status?.isReadOnly;
		if (!isDisabled) isDisabled = status?.isDisabled;

		const defaultValueOptions = useMemo(() => {
			if (!multiable || !options?.length) return {};
			let defaultValues = {};

			value?.forEach((val) => {
				const option = options.find((ele) => String(ele.value) === String(val));
				if (option) defaultValues = { ...defaultValues, [val]: option };
			});
			return defaultValues;
		}, [value, multiable, options]);
		const [selectedOptions, setSelectedOptions] = useState(defaultValueOptions);
		const [filteredOptions, setFilteredOptions] = useState(options);
		const searchRef = useRef(null);
		const listRef = useRef(null);
		const menuRef = useRef(null);
		const inputRef = useRef(null);
		const handleSelect = (option, event) => {
			if (!multiable) menuRef.current.closeMenu();
			if (option?.value === undefined) {
				return;
			}
			if (option?.value === null) return onChange(option, event);
			if (!multiable) {
				setSelectedOptions({ [option.value]: option });
				searchRef.current.value = option.label;
				onChange(option, event);
			} else {
				if (selectedOptions[option.value]) {
					const temp = { ...selectedOptions };
					delete temp[option.value];
					setSelectedOptions({ ...temp });
					onChange(Object.values(temp), event);
				} else {
					const temp = { ...selectedOptions, [option.value]: option };
					setSelectedOptions(temp);
					onChange(Object.values(temp), event);
				}
			}
		};
		const removeOption = (value) => {
			onClear && onClear(value);
			const temp = { ...selectedOptions };
			delete temp[value];
			setSelectedOptions(temp);
		};
		function handleSearch(event) {
			const value = event.target.value;
			const aOptions = options.filter((ele) => ele.label.toLowerCase().includes(value?.toLowerCase()));

			setFilteredOptions(aOptions);
		}
		useEffect(() => {
			setFilteredOptions(options);
		}, [options]);

		function handleShowSelection(option, i) {
			if (!multiable) return;
			if (maxCount && i === maxCount) return <MSChip label={`+ ${Object.keys(selectedOptions).length - maxCount}`} />;
			return (
				<MSChip
					label={option.label}
					clearable={clearable}
					onClear={(event) => {
						removeOption(option.value);
						event.stopPropagation();
					}}
				/>
			);
		}
		function clearAll(event) {
			event.stopPropagation();
			setSelectedOptions({});
			searchRef.current.value = "";
			if (multiable) return onChange([]);
			return onChange(null);
		}
		function handleBlurSearch(event) {
			if (!searchRef.current.contains(event.target)) {
				const value = searchRef.current.value;
				if (!value) return;
				const index = Object.values(options).findIndex((ele) => String(ele.label) === String(value));
				if (index < 0) {
					searchRef.current.value = "";
					setFilteredOptions(options);
					if (!multiable) setSelectedOptions({});
				}
			}
		}

		useEffect(() => {
			document.addEventListener("click", handleBlurSearch);
			return () => document.removeEventListener("click", handleBlurSearch);
		});

		function handleKeyDown(event) {
			// if (event.key == "tab") {
			// }
			if (event.key === "ArrowDown" || event.key === "ArrowUp") {
				event.preventDefault();
				if (filteredOptions?.length > 0) {
					listRef.current?.focus();
				}
			}
			if (event.key !== "tab") {
				menuRef.current.openMenu(event);
			}
		}

		useEffect(() => {
			if (Object.keys(selectedOptions).length > 0) inputRef.current.updateIsFill(true);
			else inputRef.current.updateIsFill(false);
		}, [selectedOptions]);

		const selectData = (
			<MSInput
				{...props}
				onFocus={(e) => {
					inputRef.current.updateIsFill(true);
					onFocus && onFocus(e);
				}}
				onBlur={(e) => {
					if (Object.keys(selectedOptions).length <= 0) inputRef.current.updateIsFill(false);
					onBlur && onBlur(e);
				}}
				ref={inputRef}
				status={status}
				label={label}
				labelClass={Object.keys(selectedOptions).length > 0 ? "is-fill" : ""}
				helperText={helperText}
				className={`m-select  ${multiable ? "multiable" : ""}`}
				suffix={
					<div
						className="flex items-center"
						// tabIndex={0}
						// onMouseDown={(e) => {
						// 	e.preventDefault();
						// 	menuRef.current.closeMenu();
						// }}
					>
						<div onMouseDown={() => menuRef.current.closeMenu()}>{suffix}</div>
						{/* </div> */}
						<div className="second-suffix-icon">
							{Object.keys(selectedOptions)?.length > 0 && clearable ? (
								<MIcon onClick={clearAll} name="Close" />
							) : (
								<MIcon name="ChevronDown" className="cursor-pointer" />
							)}
						</div>
					</div>
				}
			>
				<div className="m-select-selection">
					{multiable &&
						Object.values(selectedOptions)?.map((option, i) => (
							<div key={i} className=" m-select-selection-item">
								{handleShowSelection(option, i)}
							</div>
						))}
					<input
						type="text"
						ref={searchRef}
						className={`search-input`}
						value={searchRef.current?.value ?? ""}
						onChange={handleSearch}
						onKeyDown={handleKeyDown}
						readOnly={isReadOnly}
						disabled={isDisabled}
						onFocus={(event) => {
							event.target.select();
						}}
						// onBlur={handleBlurSearch}
					/>
				</div>
			</MSInput>
			// <div className="select-wrap-helper-text flex  items-start flex-col  ">
			// 	<div className="select-container w-full" ref={ref}>
			// 		{prefix && <div className="prefix">{prefix}</div>}
			// 		<div
			// 			className={`${className ?? ""} ${classValidation} ${size || ""}  ${
			// 				multiable ? "multiable" : ""
			// 			}   m-select ${Object.keys(selectedOptions).length > 0 ? "is-fill" : ""} ${
			// 				isDisabled || isReadOnly ? "pointer-events-none" : "cursor-pointer"
			// 			}`}
			// 			tabIndex={0}
			// 			onFocus={() => {
			// 				searchRef.current.focus();
			// 			}}
			// 			onBlur={(e) => {
			// 				searchRef.current.blur();
			// 				onBlur && onBlur(e);
			// 			}}
			// 			onKeyDown={handleKeyDown}
			// 			{...props}
			// 		>
			// 			{label && <div className="label">{label}</div>}
			// 			<div className="m-select-selection" tabIndex={0}>
			// 				{multiable &&
			// 					Object.values(selectedOptions)?.map((option, i) => (
			// 						<div key={i} className=" m-select-selection-item">
			// 							{handleShowSelection(option, i)}
			// 						</div>
			// 					))}
			// 				<input
			// 					type="text"
			// 					ref={searchRef}
			// 					className={`search-input`}
			// 					value={searchRef.current?.value ?? ""}
			// 					onChange={handleSearch}
			// 					onFocus={(event) => event.target.select()}
			// 					// onBlur={handleBlurSearch}
			// 				/>
			// 			</div>
			// 			<div
			// 				className="suffix"
			// 				tabIndex={0}
			// 				onMouseDown={(e) => {
			// 					e.preventDefault();
			// 					menuRef.current.closeMenu();
			// 				}}
			// 			>
			// 				{suffix}
			// 			</div>
			// 			{Object.keys(selectedOptions)?.length > 0 && clearable ? (
			// 				<MIcon className="second-suffix-icon" onClick={clearAll} name="Close" />
			// 			) : (
			// 				<MIcon className="second-suffix-icon" name="ChevronDown" />
			// 			)}
			// 		</div>
			// 	</div>
			// 	{helperText && <div className="helper-text"> {helperText}</div>}
			// </div>
		);

		const dropDownData = filteredOptions.map((option, i) => {
			return {
				...option,
				key: i,
				selected: selectedOptions[option.value] !== undefined,
			};
		});
		const data = [
			{
				type: "container",
				label: selectData,
				children: [
					{
						type: "container",
						label: <MList onClick={handleSelect} ref={listRef} data={dropDownData} />,
					},
				],
			},
		];
		useEffect(() => {
			function defaultValueSearch() {
				if (multiable || !options?.length) return (searchRef.current.value = "");
				if (value === null || value === "") {
					searchRef.current.value = "";
					setSelectedOptions({});
					return;
				}
				const option = options.find((ele) => String(ele.value) === String(value));
				if (option) {
					setSelectedOptions({ [value]: option });
					searchRef.current.value = option.label;
				}
			}
			defaultValueSearch();
		}, [value, options, multiable]);
		useImperativeHandle(ref, () => ({
			input: inputRef.current?.input,
		}));
		return (
			<>
				<MMenu
					ref={menuRef}
					data={data}
					orVertical="bottom"
					orHorizontal="center"
					transHorizontal="center"
					multiSelect={multiable}
					readonly={isDisabled || isReadOnly}
					popupClassName="m-select-popup"
				/>
			</>
		);
	}
);
export default MSSelect;
