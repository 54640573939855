import MStyled from "@compts:misc/MStyled.jsx";

// import MThemeProvider from "@/components/themes/MThemeProvider";
export function StyleProvider({ style, layout: Layout, children }) {
	return (
		<div>
			{/* <MThemeProvider style={style}> */}
			<MStyled />
			<Layout style={style}>{children}</Layout>
			{/* </MThemeProvider> */}
		</div>
	);
}

export default StyleProvider;
