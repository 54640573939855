import UIListConfig from "../management/UIListMngmt.js";
import UIRelationConfig from "../management/UIRelationMngmt.js";
export default (Base) =>
	class UIBuilderEngine extends Base {
		//#region ClassBuilding
		treatRelationDefinition(aName, aColumn, aMeta /*, aParams*/) {
			const config = new UIRelationConfig(aName, aColumn);
			aMeta.addField(aName, config);
			if (aColumn.default !== undefined) aMeta.defaultFor(aName, aColumn.default);
			if (aColumn.unique) aMeta.constraints.unique(aName);
			if (aColumn.notNull) aMeta.constraints.notNull(aName);
			if (aColumn.warnExists) aMeta.constraints.warnExists(aName);
			if (aColumn.typed) aMeta.setTyped(config);
		}
		treatColumnDefinition(aName, aColumn, aMeta, aParams) {
			switch (aColumn.type.typeName) {
				case "LANG": //Zafer Language
					const datatype = aParams.datatypes.STRING(aColumn.type.length);
					const langCols = [`${aName}_AR`, `${aName}_EN`];
					aMeta.addTableColumn(langCols[0], datatype, {title: aColumn.title_AR ? aColumn.title_AR : `fields.${aName}_AR`}); /* prettier-ignore */
					aMeta.addTableColumn(langCols[1], datatype, {title: aColumn.title_EN ? aColumn.title_EN : `fields.${aName}_EN`}); /* prettier-ignore */
					aMeta.addLangProperty(aName, aColumn.title ? aColumn.title : `fields.${aName}`);
					if (aColumn.unique) aMeta.constraints.unique(langCols);
					if (aColumn.notNull) aMeta.constraints.anyNotNull(langCols);
					if (aColumn.warnExists) {
						langCols.forEach((name) => aMeta.constraints.warnExists(name));
					}
					break;
				default:
					aMeta.addTableColumn(aName, aColumn.type, aColumn);
					if (aColumn.default !== undefined) aMeta.defaultFor(aName, aColumn.default);
					if (aColumn.autoCode) aMeta.autoCode(aName, aColumn.autoCode);
					if (aColumn.unique && !aColumn.autoNumber && !aColumn.sequence) aMeta.constraints.unique(aName);
					if (aColumn.notNull && !aColumn.autoNumber && !aColumn.sequence) aMeta.constraints.notNull(aName);
					if (aColumn.warnExists) aMeta.constraints.warnExists(aName);
			}
		}
		treatListFieldDefinition(aName, aDefinition, aMeta) {
			aMeta.addField(aName, new UIListConfig(aName, aDefinition));
		}
		treatAliases(aMeta, aData) {
			// 	const aliases = aData.aliases;
			// 	if (aliases) {
			// 		for (let [key, value] of Object.entries(aliases)) {
			// 			aMeta.addField( key, value);
			// 	}
		}
		//#endregion ClassBuilding
	};
