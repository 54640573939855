import React, { forwardRef } from "react";
export const MSBox = forwardRef(({ className, style,children, ...props }, ref) => {
	return (
		<div ref={ref} className={className} style={style}  {...props}>
			{children}
		</div>
	);
});

export default MSBox;
