import React from "react";
import { useTranslation } from "@i18n";
import MSCheckbox from "@compts:std/common/MSCheckbox.jsx";
import useItemStatus from "@compts:root/hooks/useItemStatus.js";
import MLoader from "@compts:std/common/MLoader.jsx";

function LocalCheckbox({ item, onChange }) {
	const trans = useTranslation();
	const elementRef = React.useRef();
	const [getter, setter] = React.useState(item?.value ?? false);
	const {
		status,
		validationIcon: suffix,
		validationText,
		showTextValidation,
	} = useItemStatus(item, elementRef.current);
	React.useEffect(() => {
		const doValueChanged = (newValue, oldValue, params) => {
			if (params?.byEditor === elementRef.current) {
				return;
			}
			setter(newValue);
		};

		item?.onChangedAdd(doValueChanged);
		return () => {
			item?.onChangedRemove(doValueChanged);
		};
	}, [item]);

	const title = item?.title(trans);

	const doChange = (event) => {
		const value = event?.target?.checked;
		setter(value);
		item?.setValue(value, { byEditor: elementRef.current });
		onChange && onChange(event);
	};

	return (
		<MSCheckbox
			ref={elementRef}
			status={status}
			suffix={suffix}
			checked={getter}
			label={title}
			helperText={validationText}
			onBlur={() => showTextValidation(false)}
			onChange={doChange}
		/>
	);
}

export const MCheckBox = (props) => {
	const loader = async () => {
		const result = { ...props };
		result.item = await result.item;
		return result;
	};

	return props.item instanceof Promise ? (
		<MLoader component={LocalCheckbox} loader={loader} />
	) : (
		<LocalCheckbox {...props} />
	);
};
export default MCheckBox;
