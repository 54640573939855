import { CLASS_SYMBOL_SYSTEMCLASS } from "../../../shared/core/MConsts.js";

const basicMetaExtender = (Base) =>
	class UiBasicMeta extends Base {
		get noPrimaryReqired() {
			return true;
		}
	};

export default (Base) =>
	class UiBasic extends Base {
		static [CLASS_SYMBOL_SYSTEMCLASS] = {
			meta: basicMetaExtender,
		};
	};
