import MSModal from "@/components/standard/MSModal/MSModal";
import { useRef, useState } from "react";

export default function useModal() {
	const [data, setData] = useState({});
	const [loadingProcess, setLoadingProcess] = useState(false);
	const modalRef = useRef(null);
	function showModal(param) {
		setData(param);

		modalRef.current.open(null, param.loader);
	}
	function closeModal() {
		modalRef.current.close();
	}
	function loadingModal(value) {
		setLoadingProcess(value);
	}

	function Modal() {
		return (
			<MSModal
				loadingProcess={loadingProcess}
				ok={data.ok}
				ref={modalRef}
				className={data.className}
				moreOne={data.moreOne ?? false}
				warning={data.warning}
				onOk={data?.onOk}
				// loader={data.loader}
				container={document.querySelector(".modal-container")}
				title={data.title}
			>
				{data.content}
			</MSModal>
		);
	}

	return [Modal, showModal, closeModal, loadingModal];
}
