import React from "react";
import MSInput from "./MSInput";

export const MSTextarea = React.forwardRef(
	(
		{
			handleOnChange,
			label,
			value,
			maxLength,
			suffix,
			helperText,
			status,
			error = status?.errors,
			warning = status?.warnings,
			isDisabled,
			isReadOnly,
			...props
		},
		ref
	) => {
		if (!error) error = status?.errors;
		if (!warning) warning = status?.warnings;
		if (!isReadOnly) isReadOnly = status?.isReadOnly;
		if (!isDisabled) isDisabled = status?.isDisabled;

		return (
			<MSInput
			{...props}
				ref={ref}
				label={label}
				value={value || ""}
				rows={4}
				id="outlined-textarea"
				multiline
				variant="outlined"
				maxLength={maxLength}
				onChange={handleOnChange}
				disabled={!!isDisabled}
				error={!!error}
				helperText={helperText}
				suffix={suffix}
				isReadOnly={!!isReadOnly}
				className={"m-textarea"}
			/>
		);
	}
);

export default MSTextarea;
