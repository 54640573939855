import * as React from "react";
import { useTranslation } from "@/context/AppContext";
import MSTypography from "@compts:std/MSTypography/MSTypography";
import MCollapse from "@compts:misc/MCollapse";
import MSButton from "@compts:std/MSButton/MSButton.jsx";

export function ValidationResult({ errors, warnings, show }) {
	const trans = useTranslation();
	const aErrors = errors?.messagesArray(trans);
	const [aWarnings, setAWarnings] = React.useState(warnings?.messages());

	React.useEffect(() => {
		setAWarnings(warnings?.messages());
	}, [warnings]);
	return (
		<MCollapse open={show}>
			<div className="flex flex-col gap-0 validation-result" style={{ overflowY: "auto", maxHeight: "100px" }}>
				{aErrors?.map((error, i) => (
					<MSTypography key={i} className="!p-0 ps-1 !text-error !text-[11px]">
						{error}
					</MSTypography>
				))}
				{aWarnings?.map((warning, i) => (
					<div className="flex items-center justify-between" key={i}>
						<MSTypography className="text-warning cursor-pointer !p-0 ps-1 !text-[11px]">
							{warning.asString(trans)}
						</MSTypography>
						<MSButton
							label="ignore"
							variant="text"
							className="warning-ignore"
							// className="h-4  text-[10px] bg-backgroundLight !text-warning border-[1px] border-warning"
							onClick={(e) => {
								warning.ignore();
								setAWarnings(warnings?.messages());
								e.stopPropagation();
							}}
						/>
					</div>
				))}
			</div>
		</MCollapse>
	);
}
export default ValidationResult;
