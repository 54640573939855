import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import MIcon from "@compts:std/MSIcon/MSIcon.jsx";
import MListItem from "./MListItem";
import MCollapse from "@compts:misc/MCollapse.jsx";
import useTranslation from "@i18n";
const allKeys = {};

export const MList = forwardRef(
	(
		{
			data,
			defaultselect,
			defaultopen,
			expandIconColor,
			iconColor,
			onExpandIconColor,
			onIconColor,
			disablePadding,
			className = "",
			onClick,
			onOpen,
			onSelect,
			...props
		},
		ref
	) => {
		const [openItems, setOpenItems] = useState({});
		const [selectItem, setSelectItem] = useState({});
		const getAllItemsOneLevel = useCallback((items) => {
			const oneLevelItems = [];
			function getItems(aItems) {
				aItems.forEach((ele) => {
					oneLevelItems.push(ele);
					if (ele.children) getItems(ele.children);
				});
			}
			getItems(items);
			return oneLevelItems;
		}, []);
		const allItemsOneLevel = getAllItemsOneLevel(data);
		const trans = useTranslation();
		const handleOpenItem = (item) => {
			onOpen && onOpen(item);
			setOpenItems((prevState) => {
				const temp = { ...prevState };
				Object.entries(temp)?.forEach(([key, openItem]) => {
					if (+item.level <= +openItem?.level) temp[key] = undefined;
				});
				if (!prevState[item.key]) return { ...temp, [item.key]: item };
				else {
					delete temp[item.key];
					return temp;
				}
			});
		};
		const handleSelectItem = (item) => {
			onSelect && onSelect(item);
			setSelectItem({
				[item.key]: true,
			});
		};
		function handleKeyDown(key, item) {
			if (key.code === "Enter") return handleClick(key, item);
			const currentIndex = allItemsOneLevel.findIndex((ele) => String(ele.key) === String(item.key));
			if (key.code === "ArrowDown") {
				key.preventDefault();
				if (currentIndex < allItemsOneLevel.length - 1) {
					allItemsOneLevel[currentIndex + 1].ref?.focus();
					if (allItemsOneLevel[currentIndex + 1].ref !== document.activeElement) {
						for (let index = currentIndex + 2; index < allItemsOneLevel.length + 1; index++) {
							allItemsOneLevel[index]?.ref?.focus();
							if (allItemsOneLevel[index]?.ref === document.activeElement) return;
						}
					}
				}
			}
			if (key.code === "ArrowUp") {
				key.preventDefault();
				if (currentIndex > 0) {
					allItemsOneLevel[currentIndex - 1].ref?.focus();
					if (allItemsOneLevel[currentIndex - 1].ref !== document.activeElement) {
						for (let index = currentIndex - 1; index > 0; index--) {
							allItemsOneLevel[index]?.ref?.focus();
							if (allItemsOneLevel[index]?.ref === document.activeElement) return;
						}
					}
				}
			}
		}
		const isGroup = (item) => item.children?.length > 0;
		const isSelected = (item) => {
			const selected = !!openItems[item.key] || !!selectItem[item.key];
			return selected;
		};
		useEffect(() => {
			if (Object.keys(allKeys).length) {
				const items = {};
				defaultopen?.forEach((key) => {
					if (allKeys[key]) items[allKeys[key]] = true;
				});
				setOpenItems(items);
				if (allKeys[defaultselect]) setSelectItem({ [defaultselect.toString()]: true });
			}
		}, [defaultopen, defaultselect]);

		const setKey = (item) => {
			allKeys[item.key] = item.key;
			return null;
		};
		function handleClick(event, item) {
			isGroup(item) ? handleOpenItem(item) : handleSelectItem(item);
			onClick && onClick(item, event);
		}

		const renderListItems = (items, indexForRef = 0) => {
			return items.map((item, i) => {
				return (
					<React.Fragment key={item.label + i}>
						{setKey(item)}
						<MListItem
							ref={(itemRef) =>
								// itemRef && allItemsOneLevel[indexForRef].label === item.label
								// 	? (itemsRef.current[indexForRef++] = item.label)
								// : null
								(item.ref = itemRef?.item ?? itemRef)
							}
							className={`${isSelected(item) && item.children ? "opened" : ""}
						`}
							{...props}
							onKeyDown={(key) => handleKeyDown(key, item)}
							onClick={(event) => handleClick(event, item)}
							selected={item.selected !== undefined ? item.selected : isSelected(item) && !item.children}
							icon={
								item.icon && (
									<MIcon
										name={item.icon}
										className={`${isSelected(item) ? onIconColor ?? "text-primary" : iconColor ?? "text-lightPrimary"}`}
									/>
								)
							}
							text={item.label}
							collapseIcon={
								item.children ? (
									<MIcon
										size={0.9}
										name={trans.isLTR ? "ChevronRight" : "ChevronLeft"}
										className={`transition-all duration-200 ${
											isSelected(item) ? onExpandIconColor + "  -rotate-90" : expandIconColor
										}`}
									/>
								) : null
							}
						/>

						{item.children && (
							<MCollapse open={isSelected(item) === true}>
								<div className={`${disablePadding ? "ps-0" : "ps-2"} m-inner-list`}>
									{renderListItems(item.children, indexForRef)}
								</div>
							</MCollapse>
						)}
					</React.Fragment>
				);
			});
		};
		useImperativeHandle(ref, () => ({
			focus: () => allItemsOneLevel[0].ref?.focus(),
		}));

		return (
			<div tabIndex={0} className={`m-list flex flex-col gap-1 h-fit-content ${className}`}>
				{renderListItems(data)}
			</div>
		);
	}
);

export default MList;
