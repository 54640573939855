import MIcon from "@compts:std/MSIcon/MSIcon.jsx";
import { forwardRef, useEffect, useState } from "react";

function LocalMCollapse({ className, open: aOpen, title, children, onClick }, ref) {
	const [open, setOpen] = useState(aOpen);
	function handleClick(event) {
		onClick && onClick(event);
	}
	useEffect(() => setOpen(aOpen), [aOpen]);
	return (
		<div
			className={`flex flex-col w-full m-collapse ${aOpen === undefined ? "dynamic" : ""} ${open ? "open" : ""} ${
				className ?? ""
			}`}
		>
			{aOpen === undefined && (
				<div className="flex  items-center justify-between px-2 header" onClick={() => setOpen(!open)}>
					<div className="title">{title}</div>
					<MIcon name={`Chevron${open ? "Up" : "Down"}`} className="text-textSecondary" />
				</div>
			)}
			<div className={`body w-full `} ref={ref} onClick={handleClick}>
				{children}
			</div>
		</div>
	);
}

export const MCollapse = forwardRef(LocalMCollapse);

export default MCollapse;
