import React from "react";
import { useTranslation } from "@i18n";
import MSDatePicker from "@compts:std/common/MSDatePicker.jsx";
import useItemStatus from "@compts:root/hooks/useItemStatus.js";
import MLoader from "@compts:std/common/MLoader.jsx";

function LocalDatePicker({ item }) {
	const elementRef = React.useRef();
	const [getter, setter] = React.useState(item?.value || null);
	const { status, validationIcon: suffix, validationText, showTextValidation } = useItemStatus(item);
	React.useEffect(() => {
		const doValueChanged = (aNew, aOld, aParams) => {
			if (aParams?.byEditor === elementRef.current) {
				return;
			}
			setter(aNew);
		};

		item?.onChangedAdd(doValueChanged);
		return () => {
			item?.onChangedRemove(doValueChanged);
		};
	}, [item]);
	const trans = useTranslation();
	const title = item?.title(trans);

	const doChange = (newValue) => {
		// setter(newValue);
		item?.setValue(newValue, { byEditor: elementRef.current });
	};

	return (
		<MSDatePicker
			ref={elementRef}
			defaultValue={getter ?? null}
			label={title}
			status={status}
			suffix={suffix}
			onChange={doChange}
			helperText={validationText}
			onBlur={() => {
				showTextValidation(false);
			}}
		/>
	);
}

export const MDatePicker = (props) => {
	const loader = async () => {
		const result = { ...props };
		result.item = await result.item;
		return result;
	};

	return props.item instanceof Promise ? (
		<MLoader component={LocalDatePicker} loader={loader} />
	) : (
		<LocalDatePicker {...props} />
	);
};
export default MDatePicker;
