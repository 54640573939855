import { forwardRef, useImperativeHandle, useRef } from "react";

function LocalMListItem(
	{ className = "", classNameText = "", text, selected, icon, collapseIcon = "", children, ...props },
	ref
) {
	const itemRef = useRef(null);
	useImperativeHandle(ref, () => ({
		item: itemRef.current,
	}));

	// useEffect(() => {
	// 	if (focus) itemRef.current?.focus();
	// 	else itemRef.current?.blur();
	// }, [focus]);
	return (
		<div
			tabIndex={0}
			className={`m-list-item w-full ${selected ? "selected" : ""} ${className ?? ""}`}
			ref={itemRef}
			{...props}
		>
			<div className="flex  items-center gap-2 ">
				<div>{icon}</div>
				<div className={`m-list-item-text ${classNameText ?? ""}`}>{text}</div>
			</div>
			<div className="m-list-item-icon">{collapseIcon}</div>
			{children}
		</div>
	);
}

const MListItem = forwardRef(LocalMListItem);

export default MListItem;
