import { forwardRef } from "react";
import MSInput from "./MSInput";

function LocalMSNumber(
	{
		className,
		value,
		label,
		onBlur,
		placeholder,
		status,
		size,
		prefix,
		helperText,
		suffix,
		onChange,
		hiddenArrow = true,
		clearable,
	},
	ref
) {
	return (
		<MSInput
			label={label}
			onBlur={onBlur}
			type="number"
			placeholder={placeholder}
			value={value}
			status={status}
			prefix={prefix}
			onChange={onChange}
			size={size}
			suffix={suffix}
			helperText={helperText}
			clearable={clearable}
			className={`${className ?? ""} ${hiddenArrow ? "hiddenArrow" : ""}`}
			ref={ref}
		/>
	);
}

const MSNumber = forwardRef(LocalMSNumber);

export default MSNumber;
