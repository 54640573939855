export const API_CONTEXT_MAIN = "/Api/main/";
export const CLASS_EXTENDER_TYPED = Symbol();
export const CLASS_SYMBOL_APP = Symbol();
export const CLASS_SYMBOL_SYSTEMCLASS = Symbol();
export const APPLICATION_TYPE_SERVER = 1;
export const APPLICATION_TYPE_CLIENT = 2;
export const APPLICATION_SYMBOL_START = Symbol();
export const APPLICATION_SYMBOL_BASE_CLASSES = Symbol();
export const BUILDER_ENGINE_SYMBOL_ADDCLASS = Symbol();
export const BUILDER_ENGINE_SYMBOL_APPCREATED = Symbol();
export const BUILDER_ENGINE_SYMBOL_BUILDCLASS = Symbol();
export const BUILDER_ENGINE_SYMBOL_FINALIZE = Symbol();
