import MSNumber from "@compts:std/common/MSNumber.jsx";
import MSButton from "@compts:std/MSButton/MSButton.jsx";
import MIcon from "@compts:std/MSIcon/MSIcon.jsx";
import { MSSelect } from "@compts:stdCommon";
import useTranslation from "@i18n";
import * as React from "react";

export function MPagination(
	{ page: aPage = 1, disabled, perPage: aPerPage = 5, min: aMin = 1, max: aMax = 5, onChange },
	ref
) {
	const [page, setPage] = React.useState(aPage);
	const [min, setMin] = React.useState(aMin);
	const [max, setMax] = React.useState(aMax);
	const [perPage, setPerPage] = React.useState(aPerPage);
	function handleClick(increase) {
		const newValue = page + increase;
		setPage(newValue);
		handleChange(newValue, perPage);
	}
	function handleChange(page, perPage) {
		onChange && onChange({ page, perPage });
	}
	const handleUpdateValues = (key, value) => {
		switch (key) {
			case "min":
				return setMin(value);
			case "max":
				return setMax(value);
			case "page":
				return setPage(value);
			case "perPage":
				return setPerPage(value);
			default:
				return;
		}
	};
	function handleChangePage(event) {
		handleChange(event.target.value, perPage);
	}
	function handleChangePerPage(option) {
		handleChange(page, option.value);
		setPerPage(option.value);
	}
	React.useImperativeHandle(ref, () => ({
		updateValues: handleUpdateValues,
	}));
	const trans = useTranslation();
	return (
		<div className={`m-pagination ${disabled ? "disabled" : ""}`}>
			<MSButton
				type="circle"
				variant="text"
				className="w-7 h-7 justify-center"
				icon={<MIcon name="ChevronDoubleRight" size={0.9} />}
				onClick={() => handleClick(5)}
				disabled={page + 5 > max}
			/>
			<MSButton
				type="circle"
				variant="text"
				className="w-7 h-7 justify-center"
				icon={<MIcon name="ChevronRight" size={0.9} />}
				onClick={() => handleClick(1)}
				disabled={page + 1 > max}
			/>
			<MSNumber
				className="input-value"
				label={trans.t("pagination.page")}
				size="small"
				value={page}
				onChange={handleChangePage}
			/>
			<MSButton
				type="circle"
				variant="text"
				className="w-7 h-7 justify-center"
				icon={<MIcon name="ChevronLeft" size={0.9} />}
				onClick={() => handleClick(-1)}
				disabled={page - 1 < min}
			/>
			<MSButton
				type="circle"
				variant="text"
				className="w-7 h-7 justify-center"
				icon={<MIcon name="ChevronDoubleLeft" size={0.9} />}
				onClick={() => handleClick(-5)}
				disabled={page - 5 < min}
			/>
			<MSSelect
				label={trans.t("pagination.perPage")}
				clearable={false}
				size="small"
				options={[
					{ label: 5, value: 5 },
					{ label: 10, value: 10 },
					{ label: 20, value: 20 },
					{ label: 30, value: 30 },
					{ label: 50, value: 50 },
				]}
				value={perPage}
				onChange={handleChangePerPage}
			/>
		</div>
	);
}
export default React.forwardRef(MPagination);
