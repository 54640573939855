import React, { useCallback, useState } from "react";
import { ValidationResult } from "@compts:misc/validationResult";
import MSIcon from "@compts:std/MSIcon/MSIcon.jsx";

const Component_Status = { isReadOnly: Boolean, isDisabled: Boolean, errors: Object, warnings: Object };
export const getComponentStatus = (aCurrent, aNew) => {
	let result = {};
	let anyChanged = false;
	for (let [name, type] of Object.entries(Component_Status)) {
		const current = aCurrent?.[name];
		const newState = type === Boolean ? !!aNew?.[name] : aNew?.[name];
		if ((newState ?? undefined) !== undefined) result[name] = newState;
		if (current !== newState) anyChanged = true;
	}
	return anyChanged ? result : aCurrent;
};

export const useItemStatus = (item, reference) => {
	const [current, resetStatus] = React.useState(getComponentStatus(undefined, item?.status));
	React.useEffect(() => {
		const doStatusChanged = (aNew) => {
			const new_status = getComponentStatus(current, aNew);
			if (new_status !== current) {
				resetStatus(new_status);
			}
		};
		item?.onStatusAdd(doStatusChanged);
		return () => item?.onStatusRemove(doStatusChanged);
	}, [item, current]);
	const [showValid, setShowValid] = useState(false);
	const errors = current?.errors;
	const warnings = current?.warnings;
	const validationText = showValid ? <ValidationResult show={showValid} errors={errors} warnings={warnings} /> : null;
	const showTextValidation = useCallback((value) => {
		setShowValid(value);
	}, []);
	const handleClickIcon = useCallback(
		(event) => {
			event.stopPropagation();
			showTextValidation(true);
			reference?.focus();
		},
		[showTextValidation, reference]
	);
	const validationIcon = React.useMemo(() => {
		if (!errors && !warnings) return undefined;
		return (
			<MSIcon
				sx={{ cursor: "pointer" }}
				name={"AlertCircleOutline"}
				onClick={handleClickIcon}
				className={errors ? "text-error" : "text-warning"}
			/>
		);
	}, [errors, warnings, handleClickIcon]);

	return { status: current, validationIcon, validationText, showTextValidation };
};

export default useItemStatus;
